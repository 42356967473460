import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { VisitType } from 'src/app/model/master/visit-type/VisitType';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VisitTypeService {

  constructor(private http: HttpClient) { }

  public submit(visitType: VisitType): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/VisitType/Insert", visitType);
    return this.http.post<Role[]>(`${environment.apiUrl}/VisitType/Insert`, visitType);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/VisitType");
    return this.http.get<any[]>(`${environment.apiUrl}/VisitType`);
  }

  public update(visitType: VisitType): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/VisitType/Update", visitType);
    return this.http.post<Role[]>(`${environment.apiUrl}/VisitType/Update`, visitType);
  }
}
