export class Role {
    RoleID: number;
    RoleName : string;
    RoleDescription: string;
    IsActive: boolean= true;
    ModifiedBy: number;
}

export enum UserRole {
    BLMAdmin = 1,
    ACUser = 2,
    Dealer = 3,
    DealerUser = 4
}
