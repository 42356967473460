import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Designation } from '../../../model/master/designation/desingation';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  url : string = environment.apiUrl
  constructor(private http: HttpClient) { }

  public submit(designation): Observable<Designation[]> {    
    return this.http.post<Designation[]>(`${environment.apiUrl}/Designation/Insert`, designation);
  }

  public search(): Observable<any> {    
    return this.http.get<any[]>(`${environment.apiUrl}/Designation`);
  }
  public update(designation): Observable<Designation[]> {
    return this.http.post<Designation[]>(`${environment.apiUrl}/Designation/Update`, designation);
  }
 
}
