import { Component, OnInit } from '@angular/core';
import { LeadService } from 'src/app/service/transaction/lead/lead.service';
import { LeadDatails } from './LeadDatails';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})
export class LeadComponent implements OnInit {
  leadDetails = new LeadDatails();
  LeadList: any[];
  data: any;
  p: any;
  sessionData: any = [];
  constructor(private leadService: LeadService, private router: Router) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    const me = this;
    me.search();
  }

  search() {
    const me = this;
    me.leadService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
    });
  }

  edit(leadData) {
    const me = this;
    me.leadDetails = leadData;
  }
}
