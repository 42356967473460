export class VistiDetails {
    VisitDetailID: number;
    VisitID: number;
    CompetitionMakeID: number;
    CompetitionMake: string;
    Quantity: number;
    NumberOfWorkingPumps: number;
    NumberOfStandByPumps: number;
    PumpTID: number;
    CompetitionModel: string;
    IsActive: boolean = true;
}