import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContactReport } from './ContactReport';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-report',
  templateUrl: './contact-report.component.html',
  styleUrls: ['./contact-report.component.css']
})
export class ContactReportComponent implements OnInit {

  ContactReport = new ContactReport();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  selected: any[];
  p: number = 1;
  fileName = 'Contact Report';
  sessionData: any = [];
  ModifiedBy: number;

  constructor(
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    // private accountService: AccountService,
    private datePipe: DatePipe,
    private exportService: ExcelService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    const  me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
   }

  ngOnInit() {
    const me = this;
    me.getZone();
    
  //   me.ContactReport.ToDate = new Date();
  //   me.ContactReport.FromDate = new Date(
  //     new Date().getFullYear(),
  //     new Date().getMonth() - 1, 
  //     new Date().getDate()
  // );

  me.ContactReport.ToDate = new Date(new Date().getFullYear(), 11, 31);
    me.ContactReport.FromDate = new Date(
        new Date().getFullYear(),
        0, 
        1
    );
    me.search();
  }
  changeDate(FromDate){
    this.ContactReport.ToDate= undefined;
    
    }
  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ContactReport.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ContactReport.DealerID = me.DealerList[0].DealerID;
    });
  }
  
  getDealerName(ZoneID) {
    const me = this;
    me.ContactReport.UserID = null
    me.ContactReport.DealerID = null
    me.ContactReport.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ContactReport.DealerID = me.DealerList[0].DealerID;
     });
  }

  search() {
    const me = this;
    // if (me.ContactReport && me.ContactReport.ZoneID != null || me.ContactReport
      // && me.ContactReport.DealerID !== undefined) {
      const FromDate = me.datePipe.transform(me.ContactReport.FromDate, 'dd/MM/yyyy');
      const ToDate = me.datePipe.transform(me.ContactReport.ToDate, 'dd/MM/yyyy');
      me.ContactReport.AccountID = 0;
      me.reportService.GetContactDetailsData(FromDate, ToDate, me.ContactReport.AccountID, me.ContactReport.ZoneID,
        (me.ContactReport.UserID ? me.ContactReport.UserID : me.sessionData.UserID), (me.ContactReport.DealerID ? me.ContactReport.DealerID : me.sessionData.DealerID), me.ContactReport.DealerSEID, me.ModifiedBy,me.sessionData.RoleName).subscribe(data => {
          //console.log(data);
          me.data = data.Response;
          
          
        });
    // }
  }

  export() {
    const me = this;
    const sendDataToExport = [];
    me.data.forEach(element => {
      const obj = {
        'Account Name': element.AccountName,
        'Person Name': element.PersonName,
        'Designation': element.Designation,
        'Email': element.EmailID,
        'Mobile Number': element.MobileNumber,
        'Landline Number': element.LandLineNumber,
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }

  reset() {
    const me = this;
    me.ContactReport = new ContactReport();
    me.data = [];
  }

  initialize(contactreportfilterform: NgForm) {
    const me = this;
    // me.changeDetectorRef.detectChanges();
    contactreportfilterform.reset();
    me.ngOnInit();
  }
}