import { Component, OnInit } from '@angular/core';
import { DealerTarget } from 'src/app/model/master/dealer-target/DealerTarget';
import { DealerTargetService } from 'src/app/service/master/dealer-target/dealer-target.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-dealertarget',
  templateUrl: './dealertarget.component.html',
  styleUrls: ['./dealertarget.component.css']
})
export class DealertargetComponent implements OnInit {

  dealerTarget = new DealerTarget();
  dealerTargetList: any;
  p: number;
  dealerNameList: any;
  createData: any;
  updateData: any;
  yearList: any[] = [];
  year: number;
  sessionData:any;
  constructor(
    private dealerTargetService: DealerTargetService,
    private dealerService: DealerService,
    private toastr: ToastrService) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.search();
    me.getDealerName();
    me.getYearList();
  }

  submit(dealerTarget: DealerTarget) {
    const me = this;
    if (dealerTarget.TargeID == null && dealerTarget.TargeID === undefined) {
      me.create(dealerTarget);
    } else {
      me.update(dealerTarget);
    }
  }

  create(dealerTarget: DealerTarget) {
    const me = this;
    me.dealerTargetService.submit(dealerTarget).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(dealerTarget: DealerTarget) {
    const me = this;
    const obj = {
      TargetID: dealerTarget.TargeID,
      DealerID: dealerTarget.DealerID,
      TargetYear: dealerTarget.TargetYear,
      TargetValue: dealerTarget.TargetValue,
      IsActive: dealerTarget.IsActive,
      ModifiedBy: me.sessionData.UserID,
    };
    me.dealerTargetService.update(obj).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  edit(data: DealerTarget) {
    const me = this;
    me.dealerTarget = data;
  }

  search() {
    const me = this;
    me.dealerTargetService.search().subscribe(data => {
      me.dealerTargetList = data.Response;
    });
  }

  getDealerName() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.dealerNameList = Utils.filterData(data.Response);
    });
  }

  intitialize(dealrtargetform: NgForm) {
    const me = this;
    dealrtargetform.reset();
    me.dealerTarget = new DealerTarget();
  }


  getYearList() {
    const me = this;
    me.year = new Date().getFullYear();
    for (let i = 2015; i <= me.year; i++) {
      me.yearList.push({ id: i, TargetYear: i });
    }
  }
}
