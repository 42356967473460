import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-acc-con-report',
  templateUrl: './acc-con-report.component.html',
  styleUrls: ['./acc-con-report.component.css']
})

export class AccConReportComponent implements OnInit {

  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  AseList: any[];
  DealerSEList: any[];
  ACUserList: any[];
  sessionData: any = [];
  ModifiedBy:number;
  p: number = 1;
  fileName = 'Account report';

  constructor(
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private exportService: ExcelService,
    protected changeDetectorRef: ChangeDetectorRef,
  ) { 
    const  me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
  }

  ngOnInit() {
    const me = this;
    me.getZone();
    
    // new Date(new Date().getFullYear(), 11, 31)
    me.ReportDatails.ToDate = new Date(new Date().getFullYear(), 11, 31);
    me.ReportDatails.FromDate = new Date(
        new Date().getFullYear(),
        0, 
        1
    );
    me.search();
  }
  
  changeDate(FromDate){
    this.ReportDatails.ToDate= undefined;
    }
  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }
  
  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
     });
  }

  search() {
    const me = this;
    // if (me.ReportDatails && me.ReportDatails.ZoneID != null || me.ReportDatails
    //   && me.ReportDatails.DealerID !== undefined) {
      const FromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
      const ToDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
      me.reportService.GetAccountDetailsData(FromDate, ToDate, me.ReportDatails.ZoneID,
        (me.ReportDatails.UserID ? me.ReportDatails.UserID : me.sessionData.UserID), (me.ReportDatails.DealerID ? me.ReportDatails.DealerID : me.sessionData.DealerID), me.ReportDatails.DealerSEID, me.ModifiedBy,me.sessionData.RoleName).subscribe(data => {
          me.data = data.Response;
        });
    // }
  }

  export() {
    const me = this;
    const sendDataToExport = [];
    me.data.forEach(element => {
      const obj = {
        'Account Date': element.AccountDate,
        'Account Name': element.AccountName,
        'Company Name': element.CompanyName,
        'Address ': element.Address,
        'District Name': element.DistrictName,
        'State Name': element.StateName,
        'PinCode ': element.PinCode,
        'GSTN Number': element.GSTNNumber,
        'Segment Name': element.SegmentName,
        'Dealer Name': element.DealerName,
        'Dealer SE Name': element.DealerSEName,
        'Account Type Name': element.AccountTypeName,
        'Person Name': element.PersonName,
        'Designation ': element.Designation,
        'EmailID ': element.EmailID,
        'Mobile Number': element.MobileNumber,
        'Land Line Number': element.LandLineNumber
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }

  reset() {
    const me = this;
    me.ReportDatails = new ReportDatails();
    me.data = [];
  }

  initialize(accountreportfilterform: NgForm) {
    const me = this;
    // me.changeDetectorRef.detectChanges();
    accountreportfilterform.reset();
    me.ngOnInit();
  }
}
