import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { AccountType } from 'src/app/model/master/account-type/AccountType';
import { WinningChances } from 'src/app/model/master/winning-chances/WinningChances';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WinningChancesService {

  constructor(private http: HttpClient) { }


  public submit(winningChanceData: WinningChances): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/WinningChance/Insert", winningChanceData);
    return this.http.post<Role[]>(`${environment.apiUrl}/WinningChance/Insert`, winningChanceData);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/WinningChance");
    return this.http.get<any[]>(`${environment.apiUrl}/WinningChance`);
  }

  public update(winningChanceData: WinningChances): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/WinningChance/Update", winningChanceData);
    return this.http.post<Role[]>(`${environment.apiUrl}/WinningChance/Update`, winningChanceData);
  }


  
  // public changestats(teamId): Observable<any> {
  //   return this.http.get<any[]>("http://localhost:8080/changestatus?teamId=" + teamId);
  // }

}
