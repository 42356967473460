export class DelarUser
{
    DealerSEID : number;
    DealerID : number;
    DealerName : string;
    FirstName : string;
    LastName : string;
    EmailID : string;
    UserName : string;
    UserPassword : string;
    RoleID : number;
    RoleName : string;
    MobileNumber : number;
    IsActive : boolean = true;
    ModifiedBy: number;
}