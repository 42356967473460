import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ReportDatails } from '../report/ReportDatails';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { QuoteupdateService } from '../../service/transaction/quoteupdate/quoteupdate.service';
import { QuoteUpdate } from '../../model/transaction/quoteupdate/quoteupdate';
import { ScorecardopportunityService } from 'src/app/service/master/scorecardopportunitydashboard/scorecardopportunity.service';
import { DatePipe } from '@angular/common';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { NgForm } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-scorecard-opportunity',
  templateUrl: './scorecard-opportunity.component.html',
  styleUrls: ['./scorecard-opportunity.component.css']
})

export class ScorecardOpportunityComponent implements OnInit {
  ReportDatails = new ReportDatails();
  quoteUpdate = new QuoteUpdate();
  data: any;
  DealerList: any[];
  ProductList: any[];
  winningList: any[];

  sessionData: any = [];
  AllDealer: any[];
  AllProducts: any[];

  dateTicks: Array<any> = [];
  ModifiedBy: number;
  datetype: any;
  currDate: any;
  winningchanceId: any;
  discount:number;
  discountList:any[]=[{value:0},{value: 5}, {value:10},{value: 15}, {value:20} ,{value:25}, {value:30}, {value:35},{value:40},{value:45},{value:50},{value:55},{value:60},{value:65},{value:70},{value:75}];


  numberOfVisitsOptions = {
    title: 'Total Visits',
    chartArea: { left: 50, top: 50, right: 30, width: 100, height: 300 },
    legend: { position: 'top', alignment: 'start' },
    isStacked: true,
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3'],
    vAxis: { title: '', ticks: [0], format: '0', textStyle: { fontSize: 13 } }
  }

  totalOpportunityOptions = {
    title: 'Total Opportunites',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 50, top: 50, right: 30, width: 100, height: 300 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#43b02a', '#C8102E', '#ffcd00', '#6d2077', '#0099cc'],
    vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 }, }
  }

  totalValueOpportunityOptions = {
    title: 'Total Value Opportunites',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 50, top: 50, right: 30, width: 100, height: 300 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#ffcd00','#43b02a', '#C8102E',  '#6d2077', '#0099cc'],
    vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 }, format: 'short' }
  }

  totalWonOptions = {
    title: 'Total Order Won',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 50, top: 50, right: 30, width: 100, height: 300 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#43b02a'],
    vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 }, format: 'short' }

  }

  @ViewChild('dealernumberOfVisits') dealernumberOfVisits: ElementRef
  @ViewChild('totalOpportunities') totalOpportunities: ElementRef
  @ViewChild('totalValueOpportunities') totalValueOpportunities: ElementRef
  @ViewChild('totalOrderWon') totalOrderWon: ElementRef

  constructor(
    private scorecardopportunity: ScorecardopportunityService,
    private dealerService: DealerService,
    private datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private productRangeService: ProductRangeService,
    private quoteupdateService: QuoteupdateService,
  ) {
    const me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
    me.datetype = -1;
    me.discount=0;
  }

  drawChart = (datetypeData: any) => {

    const me = this;
    // this.fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    // this.toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');

    const dealer = me.ReportDatails.DealerID;

    const productRange = me.ReportDatails.ProductRangeID;
    const winingChanceId = 0;
    const datetype = -1;
    const dicountPer = me.discount;
    const fromDate = new Date(this.ReportDatails.FromDate).getFullYear();
    
    const toDate = new Date(this.ReportDatails.ToDate).getFullYear();

    let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&UserID=' + me.sessionData.UserID + '&RoleName=' + me.sessionData.RoleName + '&DealerID=' + dealer + '&ProductRangeID=' + productRange + '&WinningChanceID=' + winingChanceId + '&DateType=' + datetypeData+ '&Discount=' + me.discount;

    //call service
    me.scorecardopportunity.scorecardopportunitydashboard(filter).subscribe(data => {

      //draw total opportunities chart
      const totalOpportunityData = new google.visualization.DataTable();
      totalOpportunityData.addColumn('string', 'Year');
      totalOpportunityData.addColumn('number', 'Won');
      totalOpportunityData.addColumn('number', 'Lost');
      totalOpportunityData.addColumn('number', 'On Going Live');
      totalOpportunityData.addColumn('number', 'Project Dropped or Shelved');
      totalOpportunityData.addColumn('number', 'Postponed');

      const totalOpportunityRows = [];

      if (data.Response && typeof data.Response.TotalOpportunities != "undefined" && data.Response.TotalOpportunities != null && data.Response.TotalOpportunities.length != null
        && data.Response.TotalOpportunities.length > 0) {

        data.Response.TotalOpportunities.forEach(opportunity => {

          totalOpportunityRows.push([opportunity.YearNo, opportunity.Won, opportunity.Lost, opportunity.OnGoingLive, opportunity.ProjectDroppedORShelved, opportunity.Postponed])
        });

        totalOpportunityData.addRows(totalOpportunityRows);
        delete this.totalOpportunityOptions.vAxis.ticks;
      } else {
        totalOpportunityRows.push([0, 0, 0, 0, 0, 0]);
        totalOpportunityData.addRows(totalOpportunityRows);
      }

      const totalOpportunityChart = new google.visualization.ColumnChart(me.totalOpportunities.nativeElement);
      totalOpportunityChart.draw(totalOpportunityData, me.totalOpportunityOptions);


      //draw total value opportunities chart
      const totalValueOpportunityData = new google.visualization.DataTable();
      totalValueOpportunityData.addColumn('string', 'Year');
      totalValueOpportunityData.addColumn('number', 'On Going Live');
      totalValueOpportunityData.addColumn('number', 'Won');
      totalValueOpportunityData.addColumn('number', 'Lost');
      totalValueOpportunityData.addColumn('number', 'Project Dropped or Shelved');
      totalValueOpportunityData.addColumn('number', 'Postponed');

      const totalValueOpportunityRows = [];

      if (data.Response && typeof data.Response.TotalOpportunityValues != "undefined" && data.Response.TotalOpportunityValues != null && data.Response.TotalOpportunityValues.length != null
        && data.Response.TotalOpportunityValues.length > 0) {

        data.Response.TotalOpportunityValues.forEach(opportunity => {

          totalValueOpportunityRows.push([opportunity.YearNo, opportunity.TotalOpportunityValue, opportunity.TotalWonValue, opportunity.TotalLostValue, opportunity.TotalProjectDroppedORShelvedValue, opportunity.TotalPostponedValue])
        });

        totalValueOpportunityData.addRows(totalValueOpportunityRows);
        delete this.totalValueOpportunityOptions.vAxis.ticks;
      } else {
        totalValueOpportunityRows.push([0, 0, 0, 0, 0, 0]);
        totalValueOpportunityData.addRows(totalValueOpportunityRows);
      }

      const totalValueOpportunityChart = new google.visualization.ColumnChart(me.totalValueOpportunities.nativeElement);
      totalValueOpportunityChart.draw(totalValueOpportunityData, me.totalValueOpportunityOptions);

      //draw total order won opportunities chart
      const totalOrderWonData = new google.visualization.DataTable();
      totalOrderWonData.addColumn('string', 'Year');
      totalOrderWonData.addColumn('number', 'Total Won Value');


      const totalOederWonRows = [];

      if (data.Response && typeof data.Response.TotalWonValues != "undefined" && data.Response.TotalWonValues != null && data.Response.TotalWonValues.length != null
        && data.Response.TotalWonValues.length > 0) {

        data.Response.TotalWonValues.forEach(opportunity => {
          totalOederWonRows.push([opportunity.YearNo, opportunity.TotalWonValue])
        });

        totalOrderWonData.addRows(totalOederWonRows);
        delete this.totalWonOptions.vAxis.ticks;
      } else {
        totalOederWonRows.push([0, 0, 0, 0, 0, 0]);
        totalOrderWonData.addRows(totalOederWonRows);
      }

      const totalOrderWonChart = new google.visualization.ColumnChart(me.totalOrderWon.nativeElement);
      totalOrderWonChart.draw(totalOrderWonData, me.totalWonOptions);



      //draw total number of visits chart
      const dealernumberOfVisitsData = new google.visualization.DataTable();
      dealernumberOfVisitsData.addColumn('string', 'Year');
      dealernumberOfVisitsData.addColumn('number', 'New Visit Count');
      dealernumberOfVisitsData.addColumn('number', 'FollowUp Visit Count');

      const dealernumberOfVisitsRows = [];

      if (data.Response && typeof data.Response.TotalVisits != "undefined" && data.Response.TotalVisits != null && data.Response.TotalVisits.length != null
        && data.Response.TotalVisits.length > 0) {

        data.Response.TotalVisits.forEach(numOfVisit => {

          dealernumberOfVisitsRows.push([numOfVisit.YearNo, numOfVisit.NewVisitCount, numOfVisit.FollowUpVisitCount])
        });

        dealernumberOfVisitsData.addRows(dealernumberOfVisitsRows);

        delete this.numberOfVisitsOptions.vAxis.ticks;

      } else {
        me.AllDealer.forEach(delNoVisit => {
          dealernumberOfVisitsRows.push([delNoVisit.YearNo, 0, 0]);
        });
        dealernumberOfVisitsData.addRows(dealernumberOfVisitsRows);
      }

      const dealernumVisitChart = new google.visualization.ColumnChart(me.dealernumberOfVisits.nativeElement);
      dealernumVisitChart.draw(dealernumberOfVisitsData, me.numberOfVisitsOptions);


    });
  }

  ngOnInit() {
    const me = this;
    // this.ReportDatails.FromDate = me.datePipe.transform('01/01/2019', 'dd/MM/yyyy');
    this.ReportDatails.FromDate = new Date('01/01/2019');
    // this.toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    me.ReportDatails.ToDate = new Date();

    const dealer = me.ReportDatails.DealerID;
    const productRange = me.ReportDatails.ProductRangeID;
    const winingChanceId = me.winningchanceId;
    const datetype = -1;

    me.getProducts();
    me.currDate = new Date();
   
    me.changeDetectorRef.detectChanges();
    // me.ReportDatails.FromDate = new Date(new Date().getFullYear(), 0, 1);

    me.getAllDealer();
    //me.searchWinning();
    for (var m = 1; m <= 12; m++)
      me.dateTicks.push(new Date('2019-' + m + '-1'));
  }

  changeDate(FromDate) {
    this.ReportDatails.ToDate = undefined;
  }

  ngAfterViewInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart(-1));
  }

  getProducts() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      me.AllProducts = data.Response;
      let item = { ProductRangeID: '', ProductRangeName: 'All' }
      data.Response.splice(0, 0, item);
      me.ProductList = data.Response;
      me.ReportDatails.ProductRangeID = me.ProductList[0].ProductRangeID;
    });
  }

  getAllDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.AllDealer = data.Response;
      let item = { DealerID: '', DealerName: 'All' }
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  searchWinning() {
    const me = this;
    me.quoteupdateService.searchWinning().subscribe(data => {
      //"WinningChanceID":1,"ChancePercentage":0,
      let item = { WinningChanceID: '', ChancePercentage: 'All' }
      data.Response.splice(0, 0, item);
      me.winningList = data.Response;
      me.quoteUpdate.WinningChanceID = me.winningList[0].WinningChanceID;
    });
  }

  initialize(scorecardopportunityfilterform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    scorecardopportunityfilterform.reset();
    me.ngOnInit();
    me.ngAfterViewInit();

  }

}
