import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AnnouncementService } from 'src/app/service/transaction/announcement/announcement.service';
import { ToastrService } from 'ngx-toastr';
import { Announcement } from './Announcement';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  p: number;
  totalRec: number;
  announcement = new Announcement();
  currentFileUpload: any;
  selectedFiles: any;
  AttachmentPathRes: any = null;
  sessionData: any = [];
  title: string;
  titleBtn: string;
  AttachmentPathtemp: string;
  responseMessage: any;
  message: string;
  data: any;

  constructor(
    private announcementService: AnnouncementService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  selectFileAttachmentPath(event) {
    const me = this;
    me.selectedFiles = event.target.files;
    me.currentFileUpload = me.selectedFiles.item(0);
    me.announcementService.pushFileToStorage(me.currentFileUpload).subscribe(data => {
      me.AttachmentPathRes = data;
    });
    me.selectedFiles = undefined;
    me.currentFileUpload = undefined;
  }

  submit() {
    const me = this;
    if (me.announcement && me.announcement.AnnouceID == null || me.announcement && me.announcement.AnnouceID === undefined) {
      me.save();
    } else {
      me.update();
    }
  }

  save() {
    const me = this;
    me.announcement.ModifiedBy = me.sessionData.UserID;
    me.announcement.AnnouncementTitle = me.announcement.AnnouncementTitle;
    me.announcement.UpFilePath = me.AttachmentPathRes && me.AttachmentPathRes.Response;
    me.announcement.UpFileName = me.AttachmentPathRes && me.AttachmentPathRes.FileName;
    me.announcement.StartDate = me.announcement.StartDate;
    me.announcement.EndDate = me.announcement.EndDate;
    me.announcement.AnnouncementDescription = me.announcement.AnnouncementDescription;
    me.announcement.IsActive = me.announcement.IsActive;
    me.announcementService.submit(me.announcement).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.announcementService.update(me.announcement).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  search() {
    const me = this;
    me.announcementService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
      me.totalRec = data.Response.length;
      // console.log(data.Response);

    });
  }

  edit(announcementdata) {
    const me = this;
    me.announcement.AnnouceID = announcementdata.AnnouceID;
    me.announcement.UpFileName = announcementdata.UpFileName;
    me.announcement.UpFilePath = announcementdata.UpFilePath;
    me.announcement.AnnouncementTitle = announcementdata.AnnouncementTitle;
    me.announcement.StartDate = announcementdata.StartDate;
    me.announcement.EndDate = announcementdata.EndDate;
    me.announcement.AnnouncementDescription = announcementdata.AnnouncementDescription;
    me.announcement.IsActive = announcementdata.IsActive;
    me.announcement.ModifiedBy = me.sessionData.UserID;
  }

  currDate: any;
  ngOnInit() {
    const me = this;
    me.currDate = new Date();
    me.announcement.EndDate = me.currDate;
    me.changeDetectorRef.detectChanges();
    me.announcement.StartDate = new Date(
      new Date().getFullYear(),
      0,
      1
    );
    me.search();
  }

  changeDate(FromDate) {
    const me = this;
    this.announcement.EndDate = undefined;
  }

  initialize(announcementform: NgForm) {
    const me = this;
    announcementform.reset();
    me.changeDetectorRef.detectChanges();
    me.announcement = new Announcement();
  }

}
