import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PumpTechnology } from 'src/app/model/master/pump-technology/PumpTechnology';
import { PumpTechnologyService } from 'src/app/service/master/pump-technology/pump-technology.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-competitiontech',
  templateUrl: './competitiontech.component.html',
  styleUrls: ['./competitiontech.component.css']
})
export class CompetitiontechComponent implements OnInit {
  term: string;
  pumpTechnology = new PumpTechnology();
  data: PumpTechnology[];
  p: number;
  createData: any;
  updatedData: any;
  sessionData : any;
  constructor(
    private pumpTechnologyService: PumpTechnologyService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    const me = this;
    me.search(me.term);
  }

  submit(pumpTechnology: PumpTechnology) {
    const me = this;
    if (pumpTechnology.PumpTechnologyID == null && pumpTechnology.PumpTechnologyID == undefined) {
      me.create(pumpTechnology);
    } else {
      me.update(pumpTechnology);
    }
  }

  create(pumpTechnology: PumpTechnology) {
    const me = this;
    pumpTechnology.ModifiedBy = me.sessionData.UserID;
    me.pumpTechnologyService.submit(pumpTechnology).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update(pumpTechnology: PumpTechnology) {
    const me = this;
    me.pumpTechnologyService.update(pumpTechnology).subscribe(data => {
      me.updatedData = data;
      if (me.updatedData.IsSuccess) {
        me.toastr.success(me.updatedData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  // search() {
  //   const me = this;
  //   me.pumpTechnologyService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }
  search(term: string) {
    const me = this;
    me.pumpTechnologyService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.PumpTechnologies.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  edit(pumpdata: PumpTechnology) {
    const me = this;
    me.pumpTechnology.PumpTechnologyID = pumpdata.PumpTechnologyID;
    me.pumpTechnology.PumpTechnologies = pumpdata.PumpTechnologies;
    me.pumpTechnology.PumpTechnologyDescription = pumpdata.PumpTechnologyDescription;
    me.pumpTechnology.IsActive = pumpdata.IsActive;
    me.pumpTechnology.ModifiedBy = me.sessionData.UserID;
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.pumpTechnology = new PumpTechnology();
  }
}
