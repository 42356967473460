import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AccountTypeService } from 'src/app/service/master/account-type/account-type.service';
import { AccountType } from 'src/app/model/master/account-type/AccountType';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.css']
})
export class AccountTypeComponent implements OnInit {

  data: any[];
  accountType = new AccountType();
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private accountTypeService: AccountTypeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(accountType: AccountType) {
    const me = this;

    if (accountType.AccountTypeID == null && accountType.AccountTypeID === undefined) {
      me.create(accountType);
    } else {
      me.update(accountType);
    }
  }

  create(accountType: AccountType) {
    const me = this;
    me.accountType.ModifiedBy = me.sessionData.UserID;
    me.accountTypeService.submit(accountType).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(accountType: AccountType) {
    const me = this;
    me.accountTypeService.update(accountType).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.accountType = new AccountType();
  }

  search() {
    const me = this;
    me.accountTypeService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  edit(d: AccountType) {
    const me = this;
    me.accountType.AccountTypeID = d.AccountTypeID;
    me.accountType.AccountTypeName = d.AccountTypeName;
    me.accountType.AccountTypeDescription = d.AccountTypeDescription;
    me.accountType.IsActive = d.IsActive;
    me.accountType.ModifiedBy = me.sessionData.UserID;
  }
}
