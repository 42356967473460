export class Contact {
    ContactID: number;
    PersonName: string;
    Designation: string;
    EmailID: string;
    MobileNumber: string;
    LandLineNumber: string;
    ReportingID: string;
    ReportingName: string;
    AccountID: number;
    AccountName: string;
    Department: string;
    IsActive: boolean = true;
    ModifiedBy: string;
}
