import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { WinningChances } from 'src/app/model/master/winning-chances/WinningChances';
import { WinningChancesService } from 'src/app/service/master/winning-chances/winning-chances.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-chanceswinning',
  templateUrl: './chanceswinning.component.html',
  styleUrls: ['./chanceswinning.component.css']
})
export class ChanceswinningComponent implements OnInit {

  winningChances = new WinningChances();
  data: WinningChances[];
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private winningChancesService: WinningChancesService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(winningChances: WinningChances) {
    const me = this;
    if (winningChances.WinningChanceID == null && winningChances.WinningChanceID === undefined) {
      me.create(winningChances);
    } else {
      me.update(winningChances);
    }
  }

  create(winningChances: WinningChances) {
    const me = this;
    winningChances.ModifiedBy = me.sessionData.UserID;
    me.winningChancesService.submit(winningChances).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(winningChances: WinningChances) {
    const me = this;
    me.winningChancesService.update(winningChances).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  search() {
    const me = this;
    me.winningChancesService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  edit(winningChancesData: WinningChances) {
    const me = this;
    me.winningChances.WinningChanceID = winningChancesData.WinningChanceID;
    me.winningChances.ChancePercentage = winningChancesData.ChancePercentage;
    me.winningChances.IsActive = winningChancesData.IsActive;
    me.winningChances.ModifiedBy = me.sessionData.UserID;
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.winningChances = new WinningChances();
  }
}
