import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Dealer } from '../../model/master/dealer/dealer';
import { DealerService } from '../../service/master/dealer/dealer.service';
import { ZoneService } from '../../service/master/zone/zone.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';


@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.css']
})

export class DealerComponent implements OnInit {

  p: number;
  dealer = new Dealer();
  responseMessage: any;
  message: string;
  data: any;
  zoneList: any[];
  sessionData:any;
  constructor(
    private dealerService: DealerService,
    private zoneService: ZoneService,
    private toastr: ToastrService, protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.dealer.IsActive = true;
    me.search();
    me.searchZone();
  }

  searchZone() {
    const me = this;
    me.zoneService.search().subscribe(data => {
      me.zoneList = Utils.filterData(data.Response);
    });
  }

  serviceDealer() {
    const me = this;
    me.dealer.ModifiedBy = me.sessionData.UserID;
    me.dealerService.submit(me.dealer).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.dealerService.update(me.dealer).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.dealer && me.dealer.DealerID == null || me.dealer && me.dealer.DealerID === undefined) {
      me.serviceDealer();
    } else {
      me.update();
    }
  }

  edit(roleData) {
    const me = this;
    me.dealer.DealerID = roleData.DealerID;
    me.dealer.DealerName = roleData.DealerName;
    me.dealer.DealerDescription = roleData.DealerDescription;
    me.dealer.ZoneID = roleData.ZoneID;
    //me.dealer.ZoneName = roleData.ZoneName;
    me.dealer.DealerAddress = roleData.DealerAddress;
    me.dealer.MobileNumber = roleData.MobileNumber;
    me.dealer.EmailID = roleData.EmailID;
    me.dealer.PANNumber = roleData.PANNumber;
    me.dealer.GSTNNumber = roleData.GSTNNumber;
    me.dealer.IsActive = roleData.IsActive;
    me.dealer.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.dealer = new Dealer();
  }
}
