import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/master/login/login.service';
import { Login } from '../model/master/login/login';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  objLogin = new Login();
  loginFailed = false;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  submit() {
    const me = this;
    if (me.objLogin && me.objLogin.UserName && me.objLogin.UserPassword) {
      me.loginService.login(me.objLogin.UserName, me.objLogin.UserPassword).subscribe(data => {
        if (data && data.IsSuccess === true) {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('currentUser', JSON.stringify(data.Response));
          this.router.navigate(['/', 'index']);
        } else {
          this.loginFailed = true;
        }
      },
        error => {
          this.loginFailed = true;
          console.log(error);
        }
      );
    } else {
      this.loginFailed = true;
    }
  }

  navigate() {
    this.router.navigate(['/', 'forgot_password']);
 }


}
