import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { LeadType } from 'src/app/model/master/lead-type/LeadType';
import { ProductModel } from 'src/app/model/master/product-model/ProductModel';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductModelService {

  constructor(private http: HttpClient) { }

  public submit(productModel: ProductModel): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/ProductModel/Insert", productModel);
    return this.http.post<Role[]>(`${environment.apiUrl}/ProductModel/Insert`, productModel);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/ProductModel");
    return this.http.get<any[]>(`${environment.apiUrl}/ProductModel`);
  }

  public update(productModel: ProductModel): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/ProductModel/Update", productModel);
    return this.http.post<Role[]>(`${environment.apiUrl}/ProductModel/Update`, productModel);
  }

  public searchProductRange(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/ProductRange");
    return this.http.get<any[]>(`${environment.apiUrl}/ProductRange`);
  }
}
