import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Account } from '../../model/transaction/account/account';
import { AccountService } from '../../service/transaction/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';
import { VisitService } from 'src/app/service/transaction/visit/visit.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit {
  p: number;
  data: any;
  temp: any;
  segmentList: any[];
  DealerList: any[];
  DealerSEList: any[];
  accountTypeList: any[];
  // districtList: any[];
  stateList: any[];
  responseMessage: any;
  message: string;
  account = new Account();
  sessionData: any = [];
  currDate: string;
  userData: any;


  constructor(
    private accountService: AccountService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef,
    private visitService: VisitService) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    //console.log('sessionData', me.sessionData);
  }


  searchSegment() {
    const me = this;
    me.accountService.searchSegment().subscribe(data => {
      me.segmentList = Utils.filterData(data.Response);
    });
  }

  searchDealer() {
    const me = this;
    me.accountService.searchDealer().subscribe(data => {
      me.DealerList = Utils.filterData(data.Response);
      //console.log(me.DealerList);

    });
  }

  searchDealerSE(DealerID) {
    const me = this;
    me.account.DealerSEID = undefined;
    me.accountService.searchDealerSE(DealerID).subscribe(data => {
      me.DealerSEList = Utils.filterData(data.Response);
      //console.log(me.DealerSEList);
    });
  }

  searchAccountType() {
    const me = this;
    me.accountService.searchAccountType().subscribe(data => {
      me.accountTypeList = Utils.filterData(data.Response);
    });
  }

  // public searchDistrict() {
  //   const me = this;
  //   me.accountService.searchDistrict().subscribe(data => {
  //     me.districtList = data.Response;
  //   });
  // }

  public searchState() {
    const me = this;
    me.accountService.searchState().subscribe(data => {
      me.stateList = Utils.filterData(data.Response);
    });
  }

  search() {
    const me = this;
    me.accountService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
    });
  }

  getCurrDate() {
    const me = this;
    me.currDate = new Date(new Date().getFullYear(), (new Date().getMonth()),
      new Date().getUTCDate(), 0, 0, 0, 0).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  }

  ngOnInit() {
    const me = this;
    me.getCurrDate();
    me.search();
    me.searchSegment();
    me.searchDealer();
    me.searchAccountType();
    me.searchState();
    me.getUserDataByID(me.sessionData.UserID);
    // me.searchDistrict();
  }

  serviceRole() {
    const me = this;
    me.account.AccountDate = me.currDate;
    me.account.ModifiedBy = me.sessionData.UserID;
    me.accountService.submit(me.account).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess === true) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.accountService.update(me.account).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.account && me.account.AccountID == null || me.account && me.account.AccountID === undefined) {
      me.serviceRole();
    } else {
      me.update();
    }
  }

  edit(accountData) {
    const me = this;
    me.searchDealerSE(accountData.DealerID);
    me.account.DealerSEID = accountData.DealerSEID;
    me.account.AccountDate = accountData.AccountDate;
    me.account.AccountID = accountData.AccountID;
    me.account.AccountName = accountData.AccountName;
    me.account.CompanyName = accountData.CompanyName;
    me.account.Address = accountData.Address;
    me.account.DistrictName = accountData.DistrictName;
    me.account.StateName = accountData.StateName;
    me.account.PinCode = accountData.PinCode;
    me.account.GSTNNumber = accountData.GSTNNumber;
    me.account.SegmentID = accountData.SegmentID;
    me.account.DealerID = accountData.DealerID;
    me.account.AccountTypeID = accountData.AccountTypeID;
    me.account.ModifiedBy = me.sessionData.UserID;
  }

  initialize(accountform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    me.account.AccountID = undefined;
    accountform.reset();
    me.autoBinding();
    me.search();
  }

  autoBinding() {
    const me = this;
    me.account = new Account();
    me.changeDetectorRef.detectChanges();
    me.account.AccountDate = me.currDate;
    if (me.sessionData.RoleName === 'Dealer User') {
     // console.log('Dealer User');
      // me.account.DealerID=me
      me.searchDealerSE(me.userData.DealerID);
      me.account.DealerID = me.userData.DealerID;
      me.account.DealerSEID = me.sessionData.UserID;

    } else if (me.sessionData.RoleName === 'Dealer') {
      me.searchDealerSE(me.sessionData.UserID);
      me.account.DealerID = me.sessionData.UserID;
    }

  }
  getUserDataByID(userID) {
    const me = this;
    me.visitService.getUserDataByID(userID).subscribe(data => {
      me.userData = data.Response;
    });
  }
  // clearValues(visitform: NgForm, visitupdatetableform: NgForm) {
  //   const me = this;
  //   visitform.reset();
  //   visitupdatetableform.reset();
  //   me.autoBinding();
  // }
  // autoBinding() {
  //   const me = this;
  //   me.visitUpdate = new VisitUpdate();
  //   this.visitUpdate.objVistiDetails = [];
  //   this.changeDetectorRef.detectChanges();
  //   me.visitUpdate.VisitDate = new Date();
  //   if (me.sessionData.RoleName === 'Dealer User') {
  //     me.searchDealerSE(me.userData.DealerID, 'Edit');
  //     me.visitUpdate.DealerID = me.userData.DealerID;
  //     me.visitUpdate.DealerSEID = me.sessionData.UserID;
  //   } else if (me.sessionData.RoleName === 'Dealer') {
  //     me.searchDealerSE(me.sessionData.UserID, 'Add');
  //     me.visitUpdate.DealerID = me.sessionData.UserID;
  //   }
  // }
}
