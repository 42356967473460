export class User {
    UserID: number;
    RoleID: number;
    RoleName?: string;
    objZoneID: any;
    LoginName: string;
    UserPassword: string;
    FirstName: string;
    LastName: string;
    UserEmailID: number;
    IsFirstLogin: boolean;
    MobileNumber: string;
    IsActive: boolean = true;
    ModifiedBy: number;
    DesignationID:number;
}
