import { Injectable } from '@angular/core';
import { Role } from 'src/app/model/master/role/role';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Lead/GetAll?UserID=` + UserID + `&RoleName=` + RoleName, {});
  }

  public GetVisitDetailsByLeadID(LeadID, UserID, RoleName)
  {
    return this.http.get<any[]>(`${environment.apiUrl}/VisitDetails/GetVisitDetailsByLeadID?LeadID=` + LeadID, {});
  }
}
