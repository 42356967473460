import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HelpDesk } from './HelpDesk';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpdeskService } from 'src/app/service/transaction/helpdesk/helpdesk.service';
import { Utils } from 'src/app/common-function/filter-dropdown-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms/src/forms';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.css']
})
export class HelpdeskComponent implements OnInit {

  p: number;
  totalRec: number;
  helpdesk = new HelpDesk();
  currentFileUpload: any;
  selectedFiles: any;
  AttachmentPathRes: any = null;
  sessionData: any = [];
  title: string;
  titleBtn: string;
  AttachmentPathtemp: string;
  responseMessage: any;
  message: string;
  data: any;

  constructor(
    private helpdeskService: HelpdeskService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  search() {
    const me = this;
    me.helpdeskService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
      me.totalRec = data.Response.length;
      // console.log(data.Response);

    });
  }

  edit(helpdeskdata) {
    const me = this;
    me.helpdesk.UpFileID = helpdeskdata.UpFileID;
    me.helpdesk.UpFileName = helpdeskdata.UpFileName;
    me.helpdesk.UpFilePath = helpdeskdata.UpFilePath;
    me.helpdesk.FileTitle = helpdeskdata.FileTitle;
    me.helpdesk.StartDate = helpdeskdata.StartDate;
    me.helpdesk.EndDate = helpdeskdata.EndDate;
    me.helpdesk.IsActive = helpdeskdata.IsActive;
    me.helpdesk.ModifiedBy = me.sessionData.UserID;
  }

  selectFileAttachmentPath(event) {
    const me = this;
    me.selectedFiles = event.target.files;
    me.currentFileUpload = me.selectedFiles.item(0);
    me.helpdeskService.pushFileToStorage(me.currentFileUpload).subscribe(data => {
      me.AttachmentPathRes = data;
    });
    me.selectedFiles = undefined;
    me.currentFileUpload = undefined;
  }

  submit() {
    const me = this;
    if (me.helpdesk && me.helpdesk.UpFileID == null || me.helpdesk && me.helpdesk.UpFileID === undefined) {
      me.save();
    } else {
      me.update();
    }
  }

  save() {
    const me = this;
    me.helpdesk.ModifiedBy = me.sessionData.UserID;
    me.helpdesk.FileTitle = me.helpdesk.FileTitle;
    me.helpdesk.UpFilePath = me.AttachmentPathRes && me.AttachmentPathRes.Response;
    me.helpdesk.UpFileName = me.AttachmentPathRes && me.AttachmentPathRes.FileName;
    me.helpdesk.StartDate = me.helpdesk.StartDate;
    me.helpdesk.EndDate = me.helpdesk.EndDate;
    me.helpdesk.IsActive = me.helpdesk.IsActive;
    me.helpdeskService.submit(me.helpdesk).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.helpdeskService.update(me.helpdesk).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  changeDate(FromDate) {
    const me = this;
    this.helpdesk.EndDate = undefined;
  }

  open(content) {

    this.modalService.open(content, {
      keyboard: false,
      backdrop: 'static'
    })

  }

  currDate: any;
  ngOnInit() {
    const me = this;
    me.currDate = new Date();
    me.helpdesk.EndDate = me.currDate;
    me.changeDetectorRef.detectChanges();
    me.helpdesk.StartDate = new Date(
      new Date().getFullYear(),
      0,
      1
    );
    me.search();
  }

  initialize(helpdeskform: NgForm) {
    const me = this;
    helpdeskform.reset();
    me.changeDetectorRef.detectChanges();
    me.helpdesk = new HelpDesk();
  }

}
