import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Segment } from '../../../model/master/segment/segment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(segment): Observable<Segment[]> {
    // return this.http.post<Segment[]>(this.url+"api/Segment/Insert", segment);
    return this.http.post<Segment[]>(`${environment.apiUrl}/Segment/Insert`, segment);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Segment");
    return this.http.get<any[]>(`${environment.apiUrl}/Segment`);
  }

  public update(segment): Observable<Segment[]> {
    // return this.http.post<Segment[]>(this.url+"api/Segment/Update", segment);
    return this.http.post<Segment[]>(`${environment.apiUrl}/Segment/Update`, segment);
  }

  public GetSegments(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Segment`);
  }

  public GetSegmentsByDealerID(DealerID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Segment/GetAllSegmentDataByDealerID?DealerID=`+DealerID);
  }

  public GetAccountsBySegment(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Segment/GetAccounts`,body);
  }

  public GetAccountsBySegmentID(SegmentID,DealerID,ZoneID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Segment/GetAccountsBySegmentID?SegmentID=`+ SegmentID + `&DealerID=` + DealerID + `&ZoneID=` + ZoneID);
  }

  public GetMassMailerContactDetails(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Accounts/GetMassMailerContactDetails`,body);
  }
  
  public SendMassMail(body): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Accounts/SendMassMail`,body);
  }
}
