import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderWon } from '../../../model/master/orderwon/orderwon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderwonService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(OrderWon): Observable<OrderWon[]> {
    // return this.http.post<OrderWon[]>(this.url+"api/OrderWon/Insert", OrderWon);
    return this.http.post<OrderWon[]>(`${environment.apiUrl}/OrderWon/Insert`, OrderWon);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/OrderWon");
    return this.http.get<any[]>(`${environment.apiUrl}/OrderWon`);
  }
  public update(OrderWon): Observable<OrderWon[]> {
    // return this.http.post<OrderWon[]>(this.url+"api/OrderWon/Update", OrderWon);
    return this.http.post<OrderWon[]>(`${environment.apiUrl}/OrderWon/Update`, OrderWon);
  }
}
