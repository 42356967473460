import { Injectable, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationType } from '../../../model/master/application/application';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
 //@Input() url : string;
  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(application): Observable<ApplicationType[]> {
    // return this.http.post<ApplicationType[]>(this.url+"api/Application/Insert", application);
    return this.http.post<ApplicationType[]>(`${environment.apiUrl}/Application/Insert`, application);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Application");
    return this.http.get<any[]>(`${environment.apiUrl}/Application`);
  }
  public update(application): Observable<ApplicationType[]> {
    // return this.http.post<ApplicationType[]>(this.url+"api/Application/Update", application);
    return this.http.post<ApplicationType[]>(`${environment.apiUrl}/Application/Update`, application);
  }
}
