import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Quantarget } from './Quantarget';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { DealerQuantityTargetService } from 'src/app/service/master/dealer-quantity-target/dealer-quantity-target.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-dealer-quan-tar',
  templateUrl: './dealer-quan-tar.component.html',
  styleUrls: ['./dealer-quan-tar.component.css']
})

export class DealerQuanTarComponent implements OnInit {
  Quantarget = new Quantarget();
  DealerList: any = [];
  YearList: any = [];
  sessionData: any = [];
  is_edit: number;
  disabled: boolean = false;
  ModifiedBy: number;
  p: number;
  searchText: any;
  createData: any;
  updatedData: any = [];
  // productRange = new ProductRange();
  data: any = [];
  // productQuantityData: Array<Quantarget>;
  productQuantityData: any = [];
  constructor(
    private dealerService: DealerService,
    private productRangeService: ProductRangeService,
    protected changeDetectorRef: ChangeDetectorRef,
    private dealerQuantityTargetService: DealerQuantityTargetService,
    private toastr: ToastrService
  ) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
  }

  ngOnInit() {
    const me = this;
    me.getDealer();
    me.Quantarget.Isedit = 0;
    // me.search();
    // me.serchDealerTargetQuantity();
    const year1 = new Date().getFullYear();
    me.getDealerQtyTarget(11, ""+year1+"");
    const year = new Date('2019').getFullYear();
    
    me.Quantarget.TargetYear = ""+year1+"";
    const range = [];
    const obj1 = { TargetYear: year };
    
    range.push(obj1);
    for (let i = 1; i < 2; i++) {
      const obj = { TargetYear: year + i };
      range.push(obj);
    }
    me.YearList = range;
   
  }

  getDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      // const item = { DealerID: '', DealerName: 'All' };
      // data.Response.splice(0, 0, item);
      me.DealerList = Utils.filterData(data.Response);
      me.Quantarget.DealerID = me.DealerList[0].DealerID;
    });
  }

  search() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      console.log('data.Response', data.Response);
      data.Response.forEach(element => {
        const obj = {
          QtID: (element.QtID ? element.QtID : ''),
          DealerID: 0,
          TargetYear: '',
          TargetQuantity: 0,
          TargetValue: 0,
          ProductRangeID: element.ProductRangeID,
          ProductRangeName: element.ProductRangeName,
          IsActive: false,
          ModifiedBy: 0
        };
        me.data.push(obj);
      });
    });
  }

  onSubmit() {
    const me = this;
    me.productQuantityData = [];
    // console.log(me.Quantarget);

    me.data.forEach(element => {
      const obj = {
        QtID: (element.QtID ? element.QtID : ''),
        DealerID: me.Quantarget.DealerID,
        TargetYear: me.Quantarget.TargetYear,
        TargetQuantity: element.TargetQuantity,
        TargetValue: element.TargetValue,
        ProductRangeID: element.ProductRangeID,
        IsActive: true,
        ModifiedBy: me.sessionData.UserID
      };
      me.productQuantityData.push(obj);
    });


    // console.log(me.productQuantityData);
    // console.log(me.Quantarget.Isedit);
    if (me.Quantarget.Isedit == 1) {
      // console.log('hello');
      me.dealerQuantityTargetService.update(me.productQuantityData).subscribe(data => {
        me.updatedData = data;
        if (me.updatedData.IsSuccess) {
          me.toastr.success(me.updatedData.Message + ' updated  successfully');
        } else {
          me.toastr.error('Something went wrong');
        }
        me.ngOnInit();
        // me.serchDealerTargetQuantity();
      });

    } else {
      // console.log('hiii');
      me.dealerQuantityTargetService.submit(me.productQuantityData).subscribe(data => {
        me.createData = data;
        if (me.createData.IsSuccess) {
          me.toastr.success(me.createData.Message + ' created  successfully');
        } else {
          me.toastr.error('Something went wrong');
        }
        me.ngOnInit();
        // me.serchDealerTargetQuantity();
      });
    }
  }

  serchDealerTargetQuantity() {
    const me = this;
    const datatest = [];
    me.dealerQuantityTargetService.search().subscribe(data => {
      // console.log('serchDealerTargetQuantity', data.Response);
      data.Response.forEach(element => {
        const obj = {
          QtID: (element.QtID ? element.QtID : ''),
          DealerName: (element.DealerName ? element.DealerName : 0),
          DealerID: (element.ProductRangeID ? element.ProductRangeID : 0),
          TargetYear: (element.TargetYear ? element.TargetYear : ''),
          TargetQuantity: (element.TargetQuantity ? element.TargetQuantity : 0),
          TargetValue: (element.TargetValue ? element.TargetValue : 0),
          ProductRangeID: element.ProductrangeID,
          ProductRangeName: element.ProductRangeName,
          IsActive: false,
          ModifiedBy: 0
        };

        datatest.push(obj);
      });
      me.data = datatest;
      me.Quantarget.DealerID = data.Response[0].DealerID;
      me.Quantarget.TargetYear = data.Response[0].TargetYear;
      me.Quantarget.Isedit = 0;
      // me.is_edit=0;
    });
  }

  initialize(dealerQuanTargetform: NgForm) {
    const me = this;
    dealerQuanTargetform.reset();
    me.changeDetectorRef.detectChanges();
    me.Quantarget.IsActive = true;
  }

  edit(index: number) {
    const me = this;
    me.is_edit = index;
    me.Quantarget.Isedit = 1;
    me.disabled = false;
  }

  getDealerQtyTarget(DealerID: number, TargetYear: string) {
    // console.log(DealerID, TargetYear);
    const me = this;
    const test = [];
    me.dealerQuantityTargetService.getDealerQtyTarget(DealerID, TargetYear, me.ModifiedBy).subscribe(data => {
      // console.log('getDealerQtyTarget', data);

      if (typeof data.Response != "undefined" && data.Response != null && data.Response.length > 0) {

        data.Response.forEach(element => {
          const obj = {
            QtID: (element.QtID ? element.QtID : ''),
            DealerName: (element.DealerName ? element.DealerName : 0),
            DealerID: (element.DealerID ? element.DealerID : 0),
            TargetYear: (element.TargetYear ? element.TargetYear : ''),
            TargetQuantity: (element.TargetQuantity ? element.TargetQuantity : 0),
            TargetValue: (element.TargetValue ? element.TargetValue : 0),
            ProductRangeID: element.ProductrangeID,
            ProductRangeName: element.ProductRangeName,
            IsTargetQuantitySet: element.IsTargetQuantitySet,
            IsTargetValueSet: element.IsTargetValueSet,
            IsActive: false,
            ModifiedBy: 0
          };

          test.push(obj);
        });
        console.log(test);

        me.Quantarget.Isedit = 1;
      } else {

        me.productRangeService.search().subscribe(data => {
          // console.log(data.Response);
          data.Response.forEach(element => {
            const obj = {
              QtID: (element.QtID ? element.QtID : ''),
              DealerID: DealerID,
              TargetYear: TargetYear,
              TargetQuantity: 0,
              TargetValue: 0,
              ProductRangeID: element.ProductRangeID,
              ProductRangeName: element.ProductRangeName,
              IsTargetQuantitySet: element.IsTargetQuantitySet,
              IsTargetValueSet: element.IsTargetValueSet,
              IsActive: false,
              ModifiedBy: 0
            };
            test.push(obj);
          });

        });

        me.Quantarget.Isedit = 0;
        // console.log(me.data);

      }
      me.data = test;
    });
  }
}
