import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TopVisits } from './TopVisits';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { NgForm } from '@angular/forms';
import { TopuservisitsService } from 'src/app/service/transaction/topvisits/topuservisits.service';
import { HeaderService } from 'src/app/service/transaction/header/header.service';

@Component({
  selector: 'app-topuservisits',
  templateUrl: './topuservisits.component.html',
  styleUrls: ['./topuservisits.component.css']
})
export class TopuservisitsComponent implements OnInit {

  topvisits = new TopVisits();
  sessionData: any = [];
  topUserVisitsData: any;
  data:any;
  DealerName : any;
  DealerSEName : any;
  VisitCount : number;
  MonthName : any;
  MonthYear : any;
  constructor(
    private tosstr: ToastrService,
    private dashboardServices: DashboardService,
    private changeDetectorRef: ChangeDetectorRef,
    private topuservisitsService: TopuservisitsService,
    private toastr: ToastrService,
    private headerService: HeaderService,
  ) { 
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    const me = this;
    me.search();
  }

  search() {
    const me = this;
    me.topuservisitsService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.topUserVisitsData = data.Response;
      //console.log(data.Response);
      
    });
  }

  submit(topvisits: TopVisits, topUserVisitsform: NgForm) {
    const me = this;
    
    // if (topvisits.ContactID == null && contact.ContactID === undefined) {
    //   me.add(contact, contactform);
    // } else {
      me.update(topvisits, topUserVisitsform);
    // }
  }

  test(){
    const me = this;
    //console.log(me.sessionData.UserID);
    me.headerService.search(me.sessionData.UserID).subscribe(data => {
      //console.log(data.Response);
      if(data.Response)
      {
        me.data = data.Response;
        me.VisitCount = me.data.VisitCount;
        me.DealerName = me.data.DealerName;
        me.DealerSEName = me.data.DealerSEName;
        me.MonthName = me.data.MonthName;
        me.MonthYear = me.data.MonthYear;
      }
      
    });
  }

  update(topvisits: TopVisits, form: NgForm) {
    const me = this;
    me.topuservisitsService.update(topvisits).subscribe(data => {
      me.topUserVisitsData = data;
      if (me.topUserVisitsData.IsSuccess) {
        me.toastr.success(me.topUserVisitsData.Message + ' updated  successfully');
        // me.test();
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
      
      // form.reset();
      // me.topvisits = new TopVisits();
    });
  }

  edit(TopVisitData: TopVisits) {
    const me = this;
    me.topvisits.UserVisitCountID = TopVisitData.UserVisitCountID;
    me.topvisits.DealerName = TopVisitData.DealerName;
    me.topvisits.DealerSEName = TopVisitData.DealerSEName;
    me.topvisits.VisitCount = TopVisitData.VisitCount;
    me.topvisits.MonthName = TopVisitData.MonthName;
    me.topvisits.MonthYear = TopVisitData.MonthYear;
    me.topvisits.ModifiedBy = me.sessionData.UserID;
    me.topvisits.IsActive = TopVisitData.IsActive;
    me.topvisits.ValidateKey = 6645;
    me.topvisits.IsReleaseToAll = TopVisitData.IsReleaseToAll;
  }

  initialize(topUserVisitsform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    // me.contact.ContactID = undefined;
    me.search();
    me.topvisits = new TopVisits();
  }

}
