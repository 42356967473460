import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe, Location } from '@angular/common';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
// import { ReportDatails1 } from './ReportDatails1';

declare var google: any;

@Component({
  selector: 'app-numberof-appgraph',
  templateUrl: './numberof-appgraph.component.html',
  styleUrls: ['./numberof-appgraph.component.css']
})
export class NumberofAppgraphComponent implements OnInit {
  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  sessionData: any = [];
  AllDealer: any[];
  AllProducts: any[];

  next30Days: any = 0;
  next90Days: any = 0;
  pastOverDue: any = 0;
  dateTicks: Array<any> = [];
  ModifiedBy: number;

  RouterParams: any;

  opportunityData: any;

  OpportunityType: any;

  noDataFoundMsg:boolean=false;

  fileName = 'My Team Open Opportunities';

  numberOfOpportunityOptions = {
    title: 'Number Of Opportunities By Sales Phase (Expected date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 } }
  }

  OpportunityValuesBySalesPhaseOptions = {
    title: 'Total Opportunity Value by Sales Phase (Expected date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], format: 'short', textStyle: { fontSize: 13 } }
  }

  numberOfVisitsOptions = {
    title: 'Number of Visits',
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start' },
    isStacked: true,
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3'],
    vAxis: { title: '', ticks: [0], format: '0', textStyle: { fontSize: 13 } }
  }

  numberOfVirtualVisitsOptions = {
    title: 'Number of Virtual Visits',
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start' },
    isStacked: true,
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3'],
    vAxis: { title: '', ticks: [0], format: '0', textStyle: { fontSize: 13 } }
  }

  tragetQuantityAchivmentOptions = {
    title: 'Quantity Vs Achievement (Product Wise)',
    titleTextStyle: { fontSize: 13 },
    chartArea: { left: 50, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    vAxis: { title: '', textStyle: { fontSize: 13 }, ticks: [0] },
    hAxis: { title: '', textStyle: { fontSize: 12 } },
    rAxis: { title: 'Qty.', textStyle: { fontSize: 13 } },
    seriesType: 'bars',
    colors: ['#c8102e', '#43b02a', '#0099cc', '#ffcd00'],
    bar: { groupWidth: 20 }
  };

  numberOfOpportunityOnCreatedDateOptions = {
    title: 'Number Of Opportunities By Sales Phase (Created date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 } }
  }

  @ViewChild('numberOfOpportunity') numberOfOpportunity: ElementRef
  @ViewChild('opportunityValue') opportunityValue: ElementRef
  @ViewChild('numberOfVisits') numberOfVisits: ElementRef
  @ViewChild('numberOfVirtualVisits') numberOfVirtualVisits: ElementRef
  @ViewChild('targetvsAchivement') targetvsAchivement: ElementRef
  @ViewChild('tragetQuantityAchivment') tragetQuantityAchivment: ElementRef
  @ViewChild('opportunityForCoreProducts') opportunityForCoreProducts: ElementRef
  @ViewChild('numberOfOpportunityOnCreatedDate') numberOfOpportunityOnCreatedDate: ElementRef
  @ViewChild('dealerOpenOpportunityProduct1') dealerOpenOpportunityProduct1: ElementRef
  @ViewChild('dealerOpenOpportunityProduct2') dealerOpenOpportunityProduct2: ElementRef
  @ViewChild('dealerOpenOpportunityProduct3') dealerOpenOpportunityProduct3: ElementRef
  @ViewChild('dealerOpenOpportunityProduct4') dealerOpenOpportunityProduct4: ElementRef

  constructor(
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private route: ActivatedRoute, router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private productRangeService: ProductRangeService,
    private location: Location,
    private exportService: ExcelService,
  ) {
    const me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
  }

  drawChart = () => {
    const me = this;
    this.route.params.subscribe(params => {
     
      me.RouterParams = JSON.parse(params['data']);
      const fromDate = me.datePipe.transform(me.RouterParams.FromDate, 'dd/MM/yyyy');
      const toDate = me.datePipe.transform(me.RouterParams.ToDate, 'dd/MM/yyyy');
      const zone = me.RouterParams.ZoneID;
      const dealer = me.RouterParams.DealerID;
      const dealerSelse = me.RouterParams.DealerSEID;
      const datetype = me.RouterParams.datetype;
      me.ReportDatails.flag = me.RouterParams.flag;
      me.ReportDatails.days = me.RouterParams.days;
    
      if (me.RouterParams.ProductRangeID === null || me.RouterParams.ProductRangeID === undefined) {
        let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&DateType=' + datetype;
        me.dashboardService.dashboardGraphs(filter).subscribe(data => {         
          if (data && data.IsSuccess === true) {
            if (me.ReportDatails.flag === 1) {
              if (data.Response && typeof data.Response.NumberOfOpportunityResult != "undefined" && data.Response.NumberOfOpportunityResult != null && data.Response.NumberOfOpportunityResult.length != null
                && data.Response.NumberOfOpportunityResult.length > 0) {

                const numberOfOpportunityData = new google.visualization.DataTable();

                numberOfOpportunityData.addColumn('string', 'month');
                numberOfOpportunityData.addColumn('number', 'Preliminary Stage');
                numberOfOpportunityData.addColumn('number', 'Advanced Stage');
                numberOfOpportunityData.addColumn('number', 'Final Negotiation');

                const numberOfOpportunityRows = [];

                data.Response.NumberOfOpportunityResult.forEach(numOfOppData => {
                  numberOfOpportunityRows.push([numOfOppData.MonthName, numOfOppData.PreliminaryStageValue, numOfOppData.AdvancedStageValue, numOfOppData.FinalNegotiationValue]);
                });

                delete this.numberOfOpportunityOptions.vAxis.ticks;

                numberOfOpportunityData.addRows(numberOfOpportunityRows);

                const numOfOpportunityChart = new google.visualization.ColumnChart(me.numberOfOpportunity.nativeElement);

                numOfOpportunityChart.draw(numberOfOpportunityData, me.numberOfOpportunityOptions);
              } else {
                var numberOfOpportunityData = new google.visualization.DataTable();
                numberOfOpportunityData.addColumn('string', 'Day');
                numberOfOpportunityData.addColumn('number', 'Preliminary Stage');
                numberOfOpportunityData.addColumn('number', 'Advanced Stage');
                numberOfOpportunityData.addColumn('number', 'Final Negotiation');

                numberOfOpportunityData.addRows([
                  ['Jan', 0, 0, 0],
                  ['Feb', 0, 0, 0],
                  ['Mar', 0, 0, 0],
                  ['Apr', 0, 0, 0],
                  ['May', 0, 0, 0],
                  ['June', 0, 0, 0],
                  ['July', 0, 0, 0],
                  ['Aug', 0, 0, 0],
                  ['Sept', 0, 0, 0],
                  ['Oct', 0, 0, 0],
                  ['Nov', 0, 0, 0],
                  ['Dec', 0, 0, 0],
                ]);

                const numOfOpportunityChart = new google.visualization.ColumnChart(me.numberOfOpportunity.nativeElement);

                numOfOpportunityChart.draw(numberOfOpportunityData, me.numberOfOpportunityOptions);
              }
            }

            if (me.ReportDatails.flag === 2) {
              if (data.Response && typeof data.Response.OpportunityValuesBySalesPhaseResult != "undefined" && data.Response.OpportunityValuesBySalesPhaseResult != null && data.Response.OpportunityValuesBySalesPhaseResult.length != null
                && data.Response.OpportunityValuesBySalesPhaseResult.length > 0) {

                const OpportunityValuesBySalesPhaseData = new google.visualization.DataTable();

                OpportunityValuesBySalesPhaseData.addColumn('string', 'Month');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Preliminary Opportunity Value');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Advanced Opportunity Value');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Final Opportunity Value');

                const OpportunityValuesBySalesPhaseRows = [];

                data.Response.OpportunityValuesBySalesPhaseResult.forEach(OppSalesPhaseData => {
                  OpportunityValuesBySalesPhaseRows.push([OppSalesPhaseData.MonthName, OppSalesPhaseData.PreliminaryOpportunityValue, OppSalesPhaseData.AdvanceOpportunityValue, OppSalesPhaseData.FinalOpportunityValue]);
                });

                delete this.OpportunityValuesBySalesPhaseOptions.vAxis.ticks;

                OpportunityValuesBySalesPhaseData.addRows(OpportunityValuesBySalesPhaseRows);

                const OpportunityValuesBySalesPhaseChart = new google.visualization.ColumnChart(me.opportunityValue.nativeElement);

                OpportunityValuesBySalesPhaseChart.draw(OpportunityValuesBySalesPhaseData, me.OpportunityValuesBySalesPhaseOptions);
              } else {
                var OpportunityValuesBySalesPhaseData = new google.visualization.DataTable();
                OpportunityValuesBySalesPhaseData.addColumn('string', 'Month');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Preliminary Opportunity Value');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Advanced Opportunity Value');
                OpportunityValuesBySalesPhaseData.addColumn('number', 'Final Opportunity Value');

                OpportunityValuesBySalesPhaseData.addRows([
                  ['Jan', 0, 0, 0],
                  ['Feb', 0, 0, 0],
                  ['Mar', 0, 0, 0],
                  ['Apr', 0, 0, 0],
                  ['May', 0, 0, 0],
                  ['June', 0, 0, 0],
                  ['July', 0, 0, 0],
                  ['Aug', 0, 0, 0],
                  ['Sept', 0, 0, 0],
                  ['Oct', 0, 0, 0],
                  ['Nov', 0, 0, 0],
                  ['Dec', 0, 0, 0],
                ]);

                const OpportunityValuesBySalesPhaseChart = new google.visualization.ColumnChart(me.opportunityValue.nativeElement);

                OpportunityValuesBySalesPhaseChart.draw(numberOfOpportunityData, me.OpportunityValuesBySalesPhaseOptions);
              }
            }

            if (me.ReportDatails.flag === 3) {
              const numberOfVisitsData = new google.visualization.DataTable();

              numberOfVisitsData.addColumn('string', 'Month Name');
              numberOfVisitsData.addColumn('number', 'New Visit Count');
              numberOfVisitsData.addColumn('number', 'FollowUp Visit Count');

              const numberOfVisitsRows = [];

              if (data.Response && typeof data.Response.NumberOfVisitsResult != "undefined" && data.Response.NumberOfVisitsResult != null && data.Response.NumberOfVisitsResult.length != null
                && data.Response.NumberOfVisitsResult.length > 0) {

                data.Response.NumberOfVisitsResult.forEach(numOfVisit => {
                  numberOfVisitsRows.push([numOfVisit.MonthName, numOfVisit.NewVisitCount, numOfVisit.FollowUpVisitCount])
                });

                numberOfVisitsData.addRows(numberOfVisitsRows);

                delete this.numberOfVisitsOptions.vAxis.ticks;

              } else {
                numberOfVisitsData.addRows([
                  ['Jan', 0, 0],
                  ['Feb', 0, 0],
                  ['Mar', 0, 0],
                  ['Apr', 0, 0],
                  ['May', 0, 0],
                  ['June', 0, 0],
                  ['July', 0, 0],
                  ['Aug', 0, 0],
                  ['Sept', 0, 0],
                  ['Oct', 0, 0],
                  ['Nov', 0, 0],
                  ['Dec', 0, 0],
                ]);
              }

              const numVisitChart = new google.visualization.ColumnChart(me.numberOfVisits.nativeElement);
              numVisitChart.draw(numberOfVisitsData, this.numberOfVisitsOptions);
            }

            if (me.ReportDatails.flag === 13) {
              const numberOfVirtualVisitsData = new google.visualization.DataTable();

              numberOfVirtualVisitsData.addColumn('string', 'Month Name');
              numberOfVirtualVisitsData.addColumn('number', 'Phone Call Count');
              numberOfVirtualVisitsData.addColumn('number', 'Virtual Video Call Count');

              const numberOfVirtualVisitsRows = [];

              if (data.Response && typeof data.Response.NumberOfVirtualVisitsResult != "undefined" && data.Response.NumberOfVirtualVisitsResult != null && data.Response.NumberOfVirtualVisitsResult.length != null
                && data.Response.NumberOfVirtualVisitsResult.length > 0) {

                data.Response.NumberOfVirtualVisitsResult.forEach(numOfVirtualVisit => {
                  numberOfVirtualVisitsRows.push([numOfVirtualVisit.MonthName, numOfVirtualVisit.PhoneCallVisitCount, numOfVirtualVisit.VideoCallVisitCount])
                });

                numberOfVirtualVisitsData.addRows(numberOfVirtualVisitsRows);

                delete this.numberOfVirtualVisitsOptions.vAxis.ticks;

              } else {
                numberOfVirtualVisitsData.addRows([
                  ['Jan', 0, 0],
                  ['Feb', 0, 0],
                  ['Mar', 0, 0],
                  ['Apr', 0, 0],
                  ['May', 0, 0],
                  ['June', 0, 0],
                  ['July', 0, 0],
                  ['Aug', 0, 0],
                  ['Sept', 0, 0],
                  ['Oct', 0, 0],
                  ['Nov', 0, 0],
                  ['Dec', 0, 0],
                ]);
              }

              const numVirtualVisitChart = new google.visualization.ColumnChart(me.numberOfVirtualVisits.nativeElement);
              numVirtualVisitChart.draw(numberOfVirtualVisitsData, this.numberOfVirtualVisitsOptions);
            }

            if (me.ReportDatails.flag === 4) {

              let targetvsAchivementData = [];

              targetvsAchivementData.push(['Dealer Name', 'Yearly Target', 'Year to Date Target', 'Year to Date Achievement', 'Open Opportunity']);

              if (data.Response && typeof data.Response.TargetAchivementResult != "undefined" && data.Response.TargetAchivementResult != null && data.Response.TargetAchivementResult.length != null
                && data.Response.TargetAchivementResult.length > 0) {
                data.Response.TargetAchivementResult.forEach(trAchiveData => {
                  targetvsAchivementData.push([trAchiveData.DealerName, trAchiveData.YearlyTargetValue, trAchiveData.YearlyAchieveValue, trAchiveData.YearlyWonValue, trAchiveData.YearlyOpenValue]);
                });

                // delete this.targetvsAchivementOptions.hAxis.ticks;

                const targetvsAchivementOptions = {
                  title: 'Target Vs Achievement',
                  titleTextStyle: { fontSize: 13 },
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
                  chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
                  hAxis: { title: '', format: 'short', textStyle: { fontSize: 13 } },
                  vAxis: { title: '', format: 'short', textStyle: { fontSize: 13 } },
                  colors: ['#c8102e', '#0099cc', '#43b02a', '#ffcd00']
                }
                const trAchivementData = google.visualization.arrayToDataTable(targetvsAchivementData);
                const trAchivementChart = new google.visualization.ColumnChart(me.targetvsAchivement.nativeElement);

                trAchivementChart.draw(trAchivementData, targetvsAchivementOptions);
              } else {

                const targetvsAchivementOptions = { title: 'Target Vs Achievement', legend: { position: 'top', alignment: 'start' }, hAxis: { title: 'Dealer', ticks: [0] }, vAxis: { title: 'INR / Indian Rupee', format: '0' }, colors: ['#c8102e', '#0099cc', '#43b02a', '#ffcd00'] }

                me.AllDealer.forEach(trAchiveData => {
                  const dealerName = trAchiveData.DealerName.slice(0, 5);
                  targetvsAchivementData.push([dealerName, 0, 0, 0, 0]);
                });

                const trAchivementData = google.visualization.arrayToDataTable(targetvsAchivementData);
                const trAchivementChart = new google.visualization.ColumnChart(me.targetvsAchivement.nativeElement);

                trAchivementChart.draw(trAchivementData, targetvsAchivementOptions);
              }
            }

            if (me.ReportDatails.flag == 5) {
              let tragetQuantityAchivmentData = [];


              tragetQuantityAchivmentData.push(['Dealer Name', 'Yearly Target', 'Year to Date Achievement',]);

              if (data.Response && typeof data.Response.QuantityTargetAchivementResult != "undefined" && data.Response.QuantityTargetAchivementResult != null && data.Response.QuantityTargetAchivementResult.length != null
                && data.Response.QuantityTargetAchivementResult.length > 0) {
                data.Response.QuantityTargetAchivementResult.forEach(trQtyAchivment => {

                  tragetQuantityAchivmentData.push([trQtyAchivment.DealerName, trQtyAchivment.YearlyTargetValue, trQtyAchivment.YearlyAchieveValue]);
                });

                delete this.tragetQuantityAchivmentOptions.vAxis.ticks;
              } else {
                me.AllProducts.forEach(trQtyAchivment => {
                  // const dealerName = trQtyAchivment.ProductRangeName.slice(0, 5);
                  tragetQuantityAchivmentData.push([trQtyAchivment.ProductRangeName, 0, 0]);
                });
              }

              const trQtyAchivmentData = google.visualization.arrayToDataTable(tragetQuantityAchivmentData);
              const tragetQuantityAchivmentChart = new google.visualization.ColumnChart(me.tragetQuantityAchivment.nativeElement);

              tragetQuantityAchivmentChart.draw(trQtyAchivmentData, me.tragetQuantityAchivmentOptions);
            }

            if (me.ReportDatails.flag == 6) {

              let opportunitiesForCoreProductsData = [];

              opportunitiesForCoreProductsData.push(['Values', 'Product Value', 'Product Quantity']);

              if (data.Response && typeof data.Response.ProductwiseValueAndQtyResult != "undefined" && data.Response.ProductwiseValueAndQtyResult != null && data.Response.ProductwiseValueAndQtyResult.length != null
                && data.Response.ProductwiseValueAndQtyResult.length > 0) {
                data.Response.ProductwiseValueAndQtyResult.forEach(oppoForCoreProducts => {
                  opportunitiesForCoreProductsData.push([oppoForCoreProducts.Name, oppoForCoreProducts.NetValue, oppoForCoreProducts.TotalQuantity]);
                });

                // delete this.opportunityForCoreProductsOptions.vAxes[0].ticks;
                // delete this.opportunityForCoreProductsOptions.vAxes[1].ticks;
                const opportunityForCoreProductsOptions = {
                  title: 'Product Wise Open Opportunity Value and Quantity',
                  titleTextStyle: { fontSize: 13 },
                  chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
                  vAxes: [{ title: '', format: 'short' }, { title: '', format: '0' }],
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13, fontWeight: 400 } },
                  vAxis: { 0: { title: '' }, 1: { title: '' }, textStyle: { fontSize: 13 } },
                  hAxis: { title: '', textStyle: { fontSize: 12 } }, rAxis: { title: '' }, seriesType: 'bars', series: { 0: { targetAxisIndex: 0 }, 1: { targetAxisIndex: 1, type: 'line' } },
                  colors: ['#c8102e', '#373D41', '#373D41'],
                  bar: { groupWidth: 20 }
                };

                const oppoForCoreProductsData = google.visualization.arrayToDataTable(opportunitiesForCoreProductsData);
                const oppoForCoreProductsChart = new google.visualization.ComboChart(me.opportunityForCoreProducts.nativeElement);

                oppoForCoreProductsChart.draw(oppoForCoreProductsData, opportunityForCoreProductsOptions);

              } else {
                me.AllProducts.forEach(oppoForCoreProducts => {
                  opportunitiesForCoreProductsData.push([oppoForCoreProducts.ProductRangeName, 0, 0]);
                });

                const opportunityForCoreProductsOptions = {
                  title: 'Product Wise Open Opportunity Value and Quantity',
                  vAxes: [
                    { title: 'Value', format: '0' },
                    { title: 'Quantity', format: '0' }
                  ],
                  legend: { position: 'top', alignment: 'start' },
                  vAxis: {
                    0: { title: 'Value' },
                    1: { title: 'Quantity' },
                  }, hAxis: { title: 'Product' }, rAxis: { title: 'Qty.' }, seriesType: 'bars', series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  }, colors: ['#c8102e', '#373D41', '#373D41'], bar: { groupWidth: 20 }
                };

                const oppoForCoreProductsData = google.visualization.arrayToDataTable(opportunitiesForCoreProductsData);
                const oppoForCoreProductsChart = new google.visualization.ComboChart(me.opportunityForCoreProducts.nativeElement);

                oppoForCoreProductsChart.draw(oppoForCoreProductsData, opportunityForCoreProductsOptions);
              }

            }

            if (me.ReportDatails.flag == 7) {
              if (data.Response && typeof data.Response.OpportunityValuesCreateDateBySalesPhaseResult != "undefined" && data.Response.NumberOfOpportunityResult != null && data.Response.NumberOfOpportunityResult.length != null
                && data.Response.OpportunityValuesCreateDateBySalesPhaseResult.length > 0) {

                const numberOfOpportunityOnCreatedDateData = new google.visualization.DataTable();

                numberOfOpportunityOnCreatedDateData.addColumn('string', 'month');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Preliminary Stage');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Advanced Stage');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Final Negotiation');

                const numberOfOpportunityOnCreatedDateRows = [];

                data.Response.OpportunityValuesCreateDateBySalesPhaseResult.forEach(numOfOppData1 => {
                  numberOfOpportunityOnCreatedDateRows.push([numOfOppData1.MonthName, numOfOppData1.PreliminaryOpportunityValue, numOfOppData1.AdvanceOpportunityValue, numOfOppData1.FinalOpportunityValue]);
                });

                delete this.numberOfOpportunityOnCreatedDateOptions.vAxis.ticks;

                numberOfOpportunityOnCreatedDateData.addRows(numberOfOpportunityOnCreatedDateRows);

                const numOfOpportunityOnCreatedDateChart = new google.visualization.ColumnChart(me.numberOfOpportunityOnCreatedDate.nativeElement);

                numOfOpportunityOnCreatedDateChart.draw(numberOfOpportunityOnCreatedDateData, me.numberOfOpportunityOnCreatedDateOptions);
              } else {
                var numberOfOpportunityOnCreatedDateData = new google.visualization.DataTable();
                numberOfOpportunityOnCreatedDateData.addColumn('string', 'Day');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Preliminary Stage');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Advanced Stage');
                numberOfOpportunityOnCreatedDateData.addColumn('number', 'Final Negotiation');

                numberOfOpportunityOnCreatedDateData.addRows([
                  ['Jan', 0, 0, 0],
                  ['Feb', 0, 0, 0],
                  ['Mar', 0, 0, 0],
                  ['Apr', 0, 0, 0],
                  ['May', 0, 0, 0],
                  ['June', 0, 0, 0],
                  ['July', 0, 0, 0],
                  ['Aug', 0, 0, 0],
                  ['Sept', 0, 0, 0],
                  ['Oct', 0, 0, 0],
                  ['Nov', 0, 0, 0],
                  ['Dec', 0, 0, 0],
                ]);

                const numOfOpportunityOnCreatedDateChart = new google.visualization.ColumnChart(me.numberOfOpportunityOnCreatedDate.nativeElement);

                numOfOpportunityOnCreatedDateChart.draw(numberOfOpportunityOnCreatedDateData, me.numberOfOpportunityOnCreatedDateOptions);
              }
            }
          }
        })
      } else {
        // console.log('hekksldfj');

        const productRange = me.RouterParams.ProductRangeID;
        let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&ProductRangeID=' + productRange + '&RoleName=' + me.sessionData.RoleName + '&DateType=' + datetype;

        me.dashboardService.comparitiveDashboardGraphs(filter).subscribe(data => {
          //console.log(me.ReportDatails.flag);

          if (data && data.IsSuccess === true) {

            if (me.ReportDatails.flag == 8) {
              let dealerOpenOpportuanityProductData1 = [];

              dealerOpenOpportuanityProductData1.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);

              if (data.Response && typeof data.Response.GroupProduct1Result != "undefined" && data.Response.GroupProduct1Result != null && data.Response.GroupProduct1Result.length != null
                && data.Response.GroupProduct1Result.length > 0) {
                data.Response.GroupProduct1Result.forEach(dealerOpenOpportunityProduct1 => {

                  dealerOpenOpportuanityProductData1.push([dealerOpenOpportunityProduct1.Name, dealerOpenOpportunityProduct1.NetValue, dealerOpenOpportunityProduct1.TotalQuantity]);
                });

                // delete this.dealerOpenOpportunityOptions1.vAxes[0].ticks;
                // delete this.dealerOpenOpportunityOptions1.vAxes[1].ticks;
                const dealerOpenOpportunityOptions1 = {
                  title: 'Products : GHS VSD+',
                  // vAxis: {title: 'Value', ticks: [0]},
                  titleTextStyle: { fontSize: 13 },
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
                  chartArea: { left: 40, top: 30, right: 30, width: 100, height: 200 },
                  vAxes: [{ title: '', format: 'short', }, { title: '', format: '0' }],
                  vAxis: { 0: { title: '' }, 1: { title: '' } },
                  hAxis: { title: '', textStyle: { fontSize: 12 } },
                  rAxis: { title: '', textStyle: { fontSize: 13 } },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  colors: ['#0099cc', '#f26522', '#f26522'],
                  bar: {
                    groupWidth: 20
                  }
                }
                const delOpenOppData = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData1);
                const delOpenOppChart = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct1.nativeElement);

                delOpenOppChart.draw(delOpenOppData, dealerOpenOpportunityOptions1);

              } else {
                const dealerOpenOpportunityOptions1 = {
                  title: 'Products : GHS VSD+',
                  // vAxis: {title: 'Value', ticks: [0]},
                  vAxes: [
                    { title: 'Value', format: '0' },
                    { title: 'Quantity', format: '0' }
                  ],
                  vAxis: {
                    0: { title: 'Value' },
                    1: { title: 'Quantity' }
                  },
                  hAxis: { title: 'Dealer' },
                  rAxis: { title: 'Qty.' },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  colors: ['#0099cc', '#f26522', '#f26522'],
                  bar: {
                    groupWidth: 20
                  }
                }

                me.AllDealer.forEach(dealerOpenOpportunityProduct1 => {
                  dealerOpenOpportuanityProductData1.push([dealerOpenOpportunityProduct1.DealerName, 0, 0]);
                });
                const delOpenOppData = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData1);
                const delOpenOppChart = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct1.nativeElement);

                delOpenOppChart.draw(delOpenOppData, dealerOpenOpportunityOptions1);
              }
            }

            if (me.ReportDatails.flag == 9) {
              let dealerOpenOpportuanityProductData2 = [];

              dealerOpenOpportuanityProductData2.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);

              if (data.Response && typeof data.Response.GroupProduct2Result != "undefined" && data.Response.GroupProduct2Result != null && data.Response.GroupProduct2Result.length != null
                && data.Response.GroupProduct2Result.length > 0) {
                data.Response.GroupProduct2Result.forEach(dealerOpenOpportunityProduct2 => {
                  dealerOpenOpportuanityProductData2.push([dealerOpenOpportunityProduct2.Name, dealerOpenOpportunityProduct2.NetValue, dealerOpenOpportunityProduct2.TotalQuantity]);
                });

                // delete this.dealerOpenOpportunityOptions2.vAxes[0].ticks;
                // delete this.dealerOpenOpportunityOptions2.vAxes[1].ticks;
                const dealerOpenOpportunityOptions2 = {
                  title: 'Products : GVS, GVD, GVS VSD+',
                  // vAxis: {title: 'Value', ticks: [0]},
                  vAxes: [
                    { title: '', format: "short" },
                    { title: '', format: "0" },
                  ],
                  titleTextStyle: { fontSize: 13 },
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
                  chartArea: { left: 40, top: 30, right: 30, width: 100, height: 200 },
                  // vAxis: {
                  //   0: {title: 'Value'},
                  //   1: {title: 'Quantity'}
                  // },
                  hAxis: { title: '', textStyle: { fontSize: 13 }, format: "short" },
                  rAxis: { title: '', textStyle: { fontSize: 13 }, format: "short" },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  colors: ['#ffcd00', '#00c0ef', '#00c0ef'],
                  bar: {
                    groupWidth: 20
                  }
                }

                const delOpenOppData2 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData2);
                const delOpenOppChart2 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct2.nativeElement);

                delOpenOppChart2.draw(delOpenOppData2, dealerOpenOpportunityOptions2);

              } else {

                const dealerOpenOpportunityOptions2 = {
                  title: 'Products : GVS, GVD, GVS VSD+',
                  // vAxis: {title: 'Value', ticks: [0]},
                  vAxes: [
                    { title: 'Value', format: "0" },
                    { title: 'Quantity', format: "0" },
                  ],
                  // vAxis: {
                  //   0: {title: 'Value'},
                  //   1: {title: 'Quantity'}
                  // },
                  hAxis: { title: 'Dealer' },
                  rAxis: { title: 'Qty.' },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  colors: ['#ffcd00', '#00c0ef', '#00c0ef'],
                  bar: {
                    groupWidth: 20
                  }
                }

                me.AllDealer.forEach(dealerOpenOpportunityProduct2 => {
                  dealerOpenOpportuanityProductData2.push([dealerOpenOpportunityProduct2.DealerName, 0, 0]);
                });
                const delOpenOppData2 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData2);
                const delOpenOppChart2 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct2.nativeElement);

                delOpenOppChart2.draw(delOpenOppData2, dealerOpenOpportunityOptions2);
              }
            }

            if (me.ReportDatails.flag == 10) {
              let dealerOpenOpportuanityProductData3 = [];

              dealerOpenOpportuanityProductData3.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);

              if (data.Response && typeof data.Response.GroupProduct3Result != "undefined" && data.Response.GroupProduct3Result != null && data.Response.GroupProduct3Result.length != null
                && data.Response.GroupProduct3Result.length > 0) {
                data.Response.GroupProduct3Result.forEach(dealerOpenOpportunityProduct3 => {
                  dealerOpenOpportuanityProductData3.push([dealerOpenOpportunityProduct3.Name, dealerOpenOpportunityProduct3.NetValue, dealerOpenOpportunityProduct3.TotalQuantity]);
                });

                // delete this.dealerOpenOpportunityOptions3.vAxes[0].ticks;
                // delete this.dealerOpenOpportunityOptions3.vAxes[1].ticks;

                const dealerOpenOpportunityOptions3 = {
                  title: 'Products : DZS Vacuum, DZS VSD+ Vacuum, DZS Pressure, DZM',
                  // vAxis: {title: 'Value', ticks: [0]},
                  titleTextStyle: { fontSize: 13 },
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
                  chartArea: { left: 40, top: 30, right: 30, width: 100, height: 200 },
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' },
                  },
                  vAxes: [
                    { title: '', format: "short" },
                    { title: '', format: "0" }
                  ],
                  vAxis: {
                    0: { title: '' },
                    1: { title: '' }
                  },
                  hAxis: { title: '', textStyle: { fontSize: 13 } },
                  rAxis: { title: '', textStyle: { fontSize: 13 } },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},

                  colors: ['#43b02a', '#00c0ef', '#00c0ef'],
                  bar: {
                    groupWidth: 20
                  }
                }

                const delOpenOppData3 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData3);
                const delOpenOppChart3 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct3.nativeElement);

                delOpenOppChart3.draw(delOpenOppData3, dealerOpenOpportunityOptions3);

              } else {

                const dealerOpenOpportunityOptions3 = {
                  title: 'Products : DZS Vacuum, DZS VSD+ Vacuum, DZS Pressure, DZM',
                  // vAxis: {title: 'Value', ticks: [0]},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' },
                  },
                  vAxes: [
                    { title: 'Value', format: "0" },
                    { title: 'Quantity', format: "0" }
                  ],
                  vAxis: {
                    0: { title: 'Value' },
                    1: { title: 'Quantity' }
                  },
                  hAxis: { title: 'Dealer' },
                  rAxis: { title: 'Qty.' },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},

                  colors: ['#43b02a', '#00c0ef', '#00c0ef'],
                  bar: {
                    groupWidth: 20
                  }
                }

                me.AllDealer.forEach(dealerOpenOpportunityProduct3 => {
                  dealerOpenOpportuanityProductData3.push([dealerOpenOpportunityProduct3.DealerName, 0, 0]);
                });

                const delOpenOppData3 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData3);
                const delOpenOppChart3 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct3.nativeElement);

                delOpenOppChart3.draw(delOpenOppData3, dealerOpenOpportunityOptions3);
              }
            }

            if (me.ReportDatails.flag == 11) {
              let dealerOpenOpportuanityProductData4 = [];

              dealerOpenOpportuanityProductData4.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);

              if (data.Response && typeof data.Response.GroupProduct4Result != "undefined" && data.Response.GroupProduct4Result != null && data.Response.GroupProduct4Result.length != null
                && data.Response.GroupProduct4Result.length > 0) {
                data.Response.GroupProduct4Result.forEach(dealerOpenOpportunityProduct4 => {
                  dealerOpenOpportuanityProductData4.push([dealerOpenOpportunityProduct4.Name, dealerOpenOpportunityProduct4.NetValue, dealerOpenOpportunityProduct4.TotalQuantity]);
                });

                // delete this.dealerOpenOpportunityOptions4.vAxes[0].ticks;
                // delete this.dealerOpenOpportunityOptions4.vAxes[1].ticks;
                const dealerOpenOpportunityOptions4 = {
                  // vAxis: {title: 'Value', ticks: [0]},
                  titleTextStyle: { fontSize: 13 },
                  legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
                  chartArea: { left: 40, top: 30, right: 30, width: 100, height: 200 },

                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  vAxes: [
                    { title: '', format: 'short' },
                    { title: '', format: '0' }
                  ],
                  vAxis: {
                    0: { title: '' },
                    1: { title: '' }
                  },
                  hAxis: { title: '' },
                  rAxis: { title: '' },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  colors: ['#c8102e', '#373D41', '#373D41'],
                  bar: {
                    groupWidth: 20
                  }
                }

                const delOpenOppData4 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData4);
                const delOpenOppChart4 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct4.nativeElement);

                delOpenOppChart4.draw(delOpenOppData4, dealerOpenOpportunityOptions4);

              } else {

                const dealerOpenOpportunityOptions4 = {
                  // vAxis: {title: 'Value', ticks: [0]},
                  series: {
                    0: { targetAxisIndex: 0 },
                    1: { targetAxisIndex: 1, type: 'line' }
                  },
                  vAxes: [
                    { title: 'Value', format: '0' },
                    { title: 'Quantity', format: '0' }
                  ],
                  vAxis: {
                    0: { title: 'Value' },
                    1: { title: 'Quantity' }
                  },
                  hAxis: { title: 'Dealer' },
                  rAxis: { title: 'Qty.' },
                  seriesType: 'bars',
                  // series: {1: {type: 'line'}},
                  colors: ['#c8102e', '#373D41', '#373D41'],
                  bar: {
                    groupWidth: 20
                  }
                }
                me.AllDealer.forEach(dealerOpenOpportunityProduct4 => {
                  dealerOpenOpportuanityProductData4.push([dealerOpenOpportunityProduct4.DealerName, 0, 0]);
                });
                const delOpenOppData4 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData4);
                const delOpenOppChart4 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct4.nativeElement);

                delOpenOppChart4.draw(delOpenOppData4, dealerOpenOpportunityOptions4);
              }
            }
          }
        })
      }

    });

  }
  currDate: any;

  ngOnInit() {

    const me = this;
    
    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
   
    let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName;

    me.dashboardService.dashboard(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {

        me.next30Days = (data.Response && data.Response.Net30DaysValue > 0) ? data.Response.Net30DaysValue : 0;
        me.next90Days = (data.Response && data.Response.Net90DaysValue > 0) ? data.Response.Net90DaysValue : 0;
        me.pastOverDue = (data.Response && data.Response.PastOverDueValue > 0) ? data.Response.PastOverDueValue : 0;
      }
    })

    this.route.params.subscribe(params => {     
      me.RouterParams = JSON.parse(params['data']);
      const fromDate = me.datePipe.transform(me.RouterParams.FromDate, 'dd/MM/yyyy');
      const toDate = me.datePipe.transform(me.RouterParams.ToDate, 'dd/MM/yyyy');
      const zone = me.RouterParams.ZoneID;
      const dealer = me.RouterParams.DealerID;
      const dealerSelse = me.RouterParams.DealerSEID;
      me.ReportDatails.flag = me.RouterParams.flag;
      me.ReportDatails.days = me.RouterParams.days;
      //console.log(me.ReportDatails.days);
      if (me.ReportDatails.days === 30) {        
        let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=30';
        me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {
          
          me.opportunityData = data.Response;
          me.OpportunityType = '30 Days';
          if(data.Response.length > 0){
            me.noDataFoundMsg=true;              
          }
        });
      }

      if (me.ReportDatails.days === 90) {       
        let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=90';
        me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {          
          me.opportunityData = data.Response;
          me.OpportunityType = '90 Days';
          if(data.Response.length > 0){
            me.noDataFoundMsg=true;            
          }
        });
      }
      if (me.ReportDatails.days === 0) {        
        let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=0';
        me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {          
          me.opportunityData = data.Response;
          me.OpportunityType = 'Overdue Opportunity';
          if(data.Response.length > 0){
            me.noDataFoundMsg=true;            
          }
        });

      }
      if (me.ReportDatails.days === 100) {       
        let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=100';
        me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {   
          
          me.opportunityData = data.Response;
          me.OpportunityType = 'Overall Opportunity';
          
          if(data.Response.length > 0){
            me.noDataFoundMsg=true;            
          }


        });

      }
     
    });
    // me.getMyTeamOpportunity();
    me.getZone();
    me.getProducts();
    me.currDate = new Date();
    me.ReportDatails.ToDate = me.currDate;
    me.changeDetectorRef.detectChanges();
    me.ReportDatails.FromDate = new Date(
      new Date().getFullYear(),
      0,
      1
    );

    me.getAllDealer();

    for (var m = 1; m <= 12; m++)
      me.dateTicks.push(new Date('2019-' + m + '-1'));
   
    me.onSwitch();

  }
  changeDate(FromDate) {
    this.ReportDatails.ToDate = undefined;
  }
  ngAfterViewInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart);
  }

  onSwitch() {
    const me = this;
    me.route.params.subscribe(params => {
      //console.log(params);
      me.RouterParams = JSON.parse(params['data']);
      if (me.RouterParams.ProductRangeID == null || me.RouterParams.ProductRangeID == undefined) {
        me.location.replaceState('/index');
      } else {
        me.location.replaceState('/com_dashboard');
      }
    });

  }

  CloseWindow() {
    if (confirm("Are you sure you want to close window?")) {
      close();
    }
  }

  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = { ZoneID: '', ZoneName: 'All' }
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = { DealerID: '', DealerName: 'All' }
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getProducts() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      me.AllProducts = data.Response;
    });
  }



  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = { DealerID: '', DealerName: 'All' }
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getAllDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.AllDealer = data.Response;
    });
  }

  getMyTeamOpportunity() {
    const me = this;
    //console.log(me.ReportDatails.days);

    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
    if (me.ReportDatails.days === 30) {
      //console.log(me.sessionData);
      let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=30';
      me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {
        me.opportunityData = data.Response;
        me.OpportunityType = '30';
        //console.log(data.Response);
      });
    }

    if (me.ReportDatails.days === 90) {
      //console.log(me.sessionData);
      let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=90';
      me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {
        me.opportunityData = data.Response;
        me.OpportunityType = '90';
        //console.log(data.Response);
      });
    }

    if (me.ReportDatails.days === 0) {
      //console.log(me.sessionData);
      let filter1 = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&OpportunityType=0';
      me.dashboardService.GetOpportunityDetails(filter1).subscribe(data => {
        me.opportunityData = data.Response;
        me.OpportunityType = 'Overdue';
        //console.log(data.Response);
      });
    }
  }

  export() {
    const me = this;

    if (me.ReportDatails.days === 30) {
      me.fileName = 'My Team Open Opportunities 30 Days';
    }
    if (me.ReportDatails.days === 90) {
      me.fileName = 'My Team Open Opportunities 90 Days';
    }

    if (me.ReportDatails.days === 0) {
      me.fileName = 'My Team Open Opportunities Overdue';
    }

    if (me.ReportDatails.days === 100) {
      me.fileName = 'My Team Open Opportunities Live Overall';
    }

    const sendDataToExport = [];
    me.opportunityData.forEach(element => {
      const obj = {
        'Account Name': element.AccountName,
        'Dealer Name': element.DealerName,
        'Dealer SE Name': element.DealerSEName,
        'Zone Name': element.ZoneName,
        'Lead Name': element.LeadName,
        'Opportunity Name': element.OpportunityName,
        'Quantity ': element.Quantity,
        'Net Value ': element.NetValue,
        'Sales Phase Name': element.SalesPhaseName,
        'Winning Chance Name': element.WinningChanceName,
        'Opportunity Mode Name': element.OpportunityModeName,
        'Opportunity Status Name': element.OpportunityStatusName,
        'Opportunity Expected Date': element.OpportunityExpectedDate,
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }

}