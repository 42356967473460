import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { QuoteUpdate } from '../../../model/transaction/quoteupdate/quoteupdate';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteupdateService {

  constructor(private http: HttpClient) { }


  public submit(quotation): Observable<QuoteUpdate[]> {
    return this.http.post<QuoteUpdate[]>(`${environment.apiUrl}/Quotation/Insert`, quotation);
  }


  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Quotation/GetAll?UserID=` + UserID + `&RoleName=` + RoleName);
  }
  public update(quotation): Observable<QuoteUpdate[]> {
    return this.http.post<QuoteUpdate[]>(`${environment.apiUrl}/Quotation/Update`, quotation);
  }

  // searchLead(): Observable<any> {
  //   return this.http.get<any[]>(`${environment.apiUrl}/Lead`);
  // }
  public searchLead(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Lead/GetAll?UserID=` + UserID + `&RoleName=` + RoleName, {});
  }
  searchSalesPhase(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/SalesPhase`);
  }

  searchWinning(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/WinningChance`);
  }

  searchoppMode(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/OpportunityMode`);
  }

  searchOpportunityStatus(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/OpportunityStatus`);
  }

  searchproductRange(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/ProductRange`);
  }

  searchproductModel(ProductRangeID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/ProductModel?ProductRangeID=` + ProductRangeID);
  }

  searchproductModelAll(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/ProductModel`);
  }

  searchResonWinning(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/WinningReasons`);
  }
  searchResonLosing(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/LossingReasons");
    return this.http.get<any[]>(`${environment.apiUrl}/LossingReasons`);
  }
  searchOrderWon(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/OrderWon");
    return this.http.get<any[]>(`${environment.apiUrl}/OrderWon`);
  }
  searchListById(QuotationID): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/QuotationDetail/Get?QuotationID="+QuotationID);
    return this.http.get<any[]>(`${environment.apiUrl}/QuotationDetail/Get?QuotationID=` + QuotationID);
  }
  searchOpportunityNameById(LeadId) : Observable<any>{
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/QuotationDetail/Get?QuotationID="+QuotationID);
    return this.http.get<any[]>(`${environment.apiUrl}/Lead/GetDataByID?LeadID=` + LeadId);
  }
  serachQuotationByLeadId(LeadId) : Observable<any>{
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/QuotationDetail/Get?QuotationID="+QuotationID);
    return this.http.get<any[]>(`${environment.apiUrl}/Quotation/GetQuotationDataByLeadID?LeadID=` + LeadId);
  }

  searchListByLeadId(LeadId) : Observable<any>{
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/QuotationDetail/Get?QuotationID="+QuotationID);
    return this.http.get<any[]>(`${environment.apiUrl}/QuotationDetail/GetDataByLeadID?LeadID=` + LeadId);
  }
  // public pushFileToStorage(fileToUpload: File): Observable<any> {


  //   const endpoint = "https://krios-growvac.azurewebsites.net/api/Quotation/FileUpload";
  //   const formData: FormData = new FormData();
  //   formData.append("file", fileToUpload, fileToUpload.name);
  //   // let headers = new HttpHeaders();
  //   // headers.append('Content-Type', 'multipart/form-data');
  //   return this.http.post<any[]>(endpoint,formData);
  // }// pushFileToStorage

  pushFileToStorage(body) {
    const fd = new FormData();
    fd.append('file', body);
    // return this.http.post("https://krios-growvac.azurewebsites.net/api/Quotation/FileUpload" ,
    return this.http.post(`${environment.apiUrl}/Quotation/FileUpload`,
      fd,
    );
  }
}
