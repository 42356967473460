import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompetionmakeService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(competionmake): Observable<CompetionmakeService[]> {
    // return this.http.post<CompetionmakeService[]>(this.url+"api/CompetitionMake/Insert", competionmake);
    return this.http.post<CompetionmakeService[]>(`${environment.apiUrl}/CompetitionMake/Insert`, competionmake);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/CompetitionMake");
    return this.http.get<any[]>(`${environment.apiUrl}/CompetitionMake`);
  }
  public update(competionmake): Observable<CompetionmakeService[]> {
    // return this.http.post<CompetionmakeService[]>(this.url+"api/CompetitionMake/Update", competionmake);
    return this.http.post<CompetionmakeService[]>(`${environment.apiUrl}/CompetitionMake/Update`, competionmake);
  }
}
