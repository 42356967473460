import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from '../../../model/master/login/login';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }

  public login(UserName, UserPassword): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Login?UserName="+UserName+"&UserPassword="+UserPassword);
    return this.http.get<any[]>(`${environment.apiUrl}/Login?UserName=` + UserName + `&UserPassword=` + UserPassword);
  }

  public forgotPassword(UserEmail): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Login?UserName="+UserName+"&UserPassword="+UserPassword);
    return this.http.get<any[]>(`${environment.apiUrl}/Login/ForgotPassword?UserEmailID=` + UserEmail);
  }

  public changePassword(OldPassword, NewPassword, UserID, RollName): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Login?UserName="+UserName+"&UserPassword="+UserPassword);
    return this.http.get<any[]>(`${environment.apiUrl}/Login/ChangePassword?OldPassword=`+OldPassword+"&NewPassword="+NewPassword+"&UserID="+UserID+"&RoleName="+RollName);
  }
}
