import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DealerSePerComponent } from './pages/dealer-se-per/dealer-se-per.component';
import { QuotationReportComponent } from './pages/quotation-report/quotation-report.component';
import { VisitreportComponent } from './pages/visitreport/visitreport.component';
import { LeadreportComponent } from './pages/leadreport/leadreport.component';
import { AccConReportComponent } from './pages/acc-con-report/acc-con-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ContactReportComponent } from './pages/contact-report/contact-report.component';
import { AuthGuard } from './authguard/auth.guard';
import { FilterPipe } from './filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ACEnquiriesComponent } from './pages/acenquiries/acenquiries.component';
import { NumberofAppgraphComponent } from './pages/numberof-appgraph/numberof-appgraph.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { PricePipePipe } from './pricePipe.pipe';
import { TopuservisitsComponent } from './pages/topuservisits/topuservisits.component';
import { MassmailerComponent } from './pages/massmailer/massmailer.component';
import { MassMailListComponent } from './pages/mass-mail-list/mass-mail-list.component';
import { MassMailViewComponent } from './pages/mass-mail-view/mass-mail-view.component';
import { ScorecardOpportunityComponent } from './pages/scorecard-opportunity/scorecard-opportunity.component';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    DealerSePerComponent,
    QuotationReportComponent,
    VisitreportComponent,
    LeadreportComponent,
    AccConReportComponent,
    ContactReportComponent,
    FilterPipe,
    PricePipePipe,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ACEnquiriesComponent,
    NumberofAppgraphComponent,
    HelpdeskComponent,
    AnnouncementComponent,
    TopuservisitsComponent,
    MassmailerComponent,
    MassMailListComponent,
    MassMailViewComponent,
    ScorecardOpportunityComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularEditorModule,
    CommonModule,
    NgxPaginationModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
    }),
    NgxSpinnerModule
  ],
  providers: [DatePipe, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
