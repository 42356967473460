import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HelpDesk } from 'src/app/pages/helpdesk/HelpDesk';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

  constructor(private http: HttpClient) { }

  pushFileToStorage(body) {
    const fd = new FormData();
    fd.append('file', body);
    return this.http.post(`${environment.apiUrl}/FileUploadDetail/FileUpload`,
      fd,
    );
  }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/FileUploadDetail/GetData`);
  }

  public submit(helpdesk): Observable<HelpDesk[]> {
    
    return this.http.post<HelpDesk[]>(`${environment.apiUrl}/FileUploadDetail/Insert`, helpdesk);
  }

  public update(HelpDesk): Observable<HelpDesk[]> {
    return this.http.post<HelpDesk[]>(`${environment.apiUrl}/FileUploadDetail/Update`, HelpDesk);
  }
}
