import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(private http: HttpClient) { }


  public submit(data): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/Visit/Insert`, data);
  }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Visit/GetAll?UserID=` + UserID + `&RoleName=` + RoleName);
  }

  public update(data): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/Visit/Update`, data);
  }

  public delete(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/Visit/Delete`, data);
  }

  public getVisitDetailsById(id): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/VisitDetails?VisitID=` + id);
  }

  public getUserDataByID(userID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DealerEng/GetDealerSE?DealerSEID=` + userID);
  }

  public getLeadVisitDataByID(leadID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Lead/GetLeadVisitDataByID?LeadID=` + leadID);
  }

  public GetVirtualCallDetails(VisitID,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Visit/GetVirtualCallDetails?VisitID=` + VisitID + `&RoleName=` + RoleName);
  }

}
