import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }

  public dashboard(filters: String): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/DashBoard");
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard?${filters}`);
  }

  public dashboardGraphs(filters: String): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GraphData?${filters}`);
  }

  public dashboardGraphsanalysis(filters: String): Observable<any> {
    
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetDealerComparativeAnalysisValues?${filters}`);
    //return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetDealerComparativeAnalysisValues?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3&ProductRangeID=7`);
  }

  public comparitiveDashboardGraphs(filters: String): Observable<any> {
    
    return this.http.get<any[]>(`${environment.apiUrl}/ComparativeDashBoard/AllCGraph?${filters}`);
  }

  public GetOpenOpportunityGraph(filters: String): Observable<any> {
    
    return this.http.get<any[]>(`${environment.apiUrl}/ComparativeDashBoard/GetOpenOpportunityGraph?${filters}`);
  }
  
  public GetOpportunityDetails(filters: String): Observable<any> {
    
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetMyTeamOpportunity?${filters}`);
  }

  // public GetTopUserVisitsSearch(UserID, RoleName): Observable<any> {    
  //   return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetAllTopMostUserWiseVisitCount?UserID=` + UserID + `&RoleName=` + RoleName);
  // }
}
