export class ReportDatails {
    FromDate: any;
    ToDate: any;
    ZoneID?: number;
    UserID: number;
    DealerID?: number;
    DealerSEID?: number;
    ProductRangeID?: number;
    flag?:number;
    days?:number;
}
