import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { NgForm } from '@angular/forms';
// import { PricePipePipe } from 'src/app/pricePipe.pipe';

declare var google: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  sessionData: any = [];
  AllDealer: any[];
  AllProducts: any[];

  next30Days: any = 0;
  next90Days: any = 0;
  pastOverDue: any = 0;
  overallValue: any = 0;
  dateTicks: Array<any> = [];
  ModifiedBy: number;
  datetype: any;

  // dealerComparativeOptions = { title : 'Dealer Comparative Performance', vAxis: {title: 'Value', ticks: [0]}, hAxis: {title: 'Dealer'}, rAxis: {title: 'Qty.'}, seriesType: 'bars', series: {1: {type: 'line'}}, colors: ['#f26522','#00c0ef','#00c0ef']};
  numberOfVisitsOptions = {
    title: 'Number of Visits',
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start' },
    isStacked: true,
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3'],
    vAxis: { title: '', ticks: [0], format: '0', textStyle: { fontSize: 13 } }
  }

  numberOfOpportunityOptions = {
    title: 'Number Of Opportunities By Sales Phase (Expected date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 } }
  }

  numberOfOpportunityOnCreatedDateOptions = {
    title: 'Number Of Opportunities By Sales Phase (Created date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], textStyle: { fontSize: 13 } }
  }


  OpportunityValuesBySalesPhaseOptions = {
    title: 'Total Opportunity Value by Sales Phase (Expected date)',
    titleTextStyle: { fontSize: '' },
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    isStacked: true, legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3', '#43b02a'], vAxis: { title: '', ticks: [0], format: 'short', textStyle: { fontSize: 13 } }
  }
  // tragetQuantityAchivmentOptions = { title: ' Quantity Vs Achievement (Product Wise)', chartArea: {width: '50%'}, hAxis: { title: 'INR / Indian Rupee', ticks: [0] }, vAxis: { title: 'Dealer' }, bar: { groupWidth: 50 }, colors: ['#c8102e','#0099cc','#43b02a','#ffcd00'], };

  tragetQuantityAchivmentOptions = {
    title: 'Quantity Vs Achievement (Product Wise)',
    titleTextStyle: { fontSize: 13 },
    chartArea: { left: 50, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
    vAxis: { title: '', textStyle: { fontSize: 13 }, ticks: [0] },
    hAxis: { title: '', textStyle: { fontSize: 12 } },
    rAxis: { title: 'Qty.', textStyle: { fontSize: 13 } },
    seriesType: 'bars',
    colors: ['#c8102e', '#43b02a', '#0099cc', '#ffcd00'],
    bar: { groupWidth: 20 }
  };

  numberOfVirtualVisitsOptions = {
    title: 'Number of Virtual Visits',
    chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
    legend: { position: 'top', alignment: 'start' },
    isStacked: true,
    hAxis: { title: '', format: 'MMM', textStyle: { fontSize: 13 } },
    colors: ['#6d2077', '#0066b3'],
    vAxis: { title: '', ticks: [0], format: '0', textStyle: { fontSize: 13 } }
  }

  // @ViewChild('dealerComparativePerformance') dealerComparativePerformance: ElementRef
  @ViewChild('numberOfVisits') numberOfVisits: ElementRef
  @ViewChild('targetvsAchivement') targetvsAchivement: ElementRef
  @ViewChild('numberOfOpportunity') numberOfOpportunity: ElementRef
  @ViewChild('numberOfOpportunityOnCreatedDate') numberOfOpportunityOnCreatedDate: ElementRef
  @ViewChild('opportunityValue') opportunityValue: ElementRef
  @ViewChild('tragetQuantityAchivment') tragetQuantityAchivment: ElementRef
  @ViewChild('opportunityForCoreProducts') opportunityForCoreProducts: ElementRef
  @ViewChild('numberOfVirtualVisits') numberOfVirtualVisits: ElementRef

  constructor(
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private productRangeService: ProductRangeService,
    // private pricePipePipe: PricePipePipe
  ) {
    const me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
    me.datetype = -1;
  }

  drawChart = (datetype: any) => {
    const me = this;
    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
    //console.log(' drawchart ' + fromDate);
    let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&DateType=' + datetype;

    me.dashboardService.dashboard(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {

        me.next30Days = (data.Response && data.Response.Net30DaysValue > 0) ? data.Response.Net30DaysValue : 0;
        me.next90Days = (data.Response && data.Response.Net90DaysValue > 0) ? data.Response.Net90DaysValue : 0;
        me.pastOverDue = (data.Response && data.Response.PastOverDueValue > 0) ? data.Response.PastOverDueValue : 0;
        me.overallValue = (data.Response && data.Response.OverallOpportunityValue > 0) ? data.Response.OverallOpportunityValue : 0;
      }
    });


    me.dashboardService.dashboardGraphs(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {
        
        const numberOfVisitsData = new google.visualization.DataTable();

        numberOfVisitsData.addColumn('string', 'Month Name');
        numberOfVisitsData.addColumn('number', 'New Visit Count');
        numberOfVisitsData.addColumn('number', 'FollowUp Visit Count');

        const numberOfVisitsRows = [];

        if (data.Response && typeof data.Response.NumberOfVisitsResult != "undefined" && data.Response.NumberOfVisitsResult != null && data.Response.NumberOfVisitsResult.length != null
          && data.Response.NumberOfVisitsResult.length > 0) {

          data.Response.NumberOfVisitsResult.forEach(numOfVisit => {
            numberOfVisitsRows.push([numOfVisit.MonthName, numOfVisit.NewVisitCount, numOfVisit.FollowUpVisitCount])
          });

          numberOfVisitsData.addRows(numberOfVisitsRows);

          delete this.numberOfVisitsOptions.vAxis.ticks;

        } else {
          numberOfVisitsData.addRows([
            ['Jan', 0, 0],
            ['Feb', 0, 0],
            ['Mar', 0, 0],
            ['Apr', 0, 0],
            ['May', 0, 0],
            ['June', 0, 0],
            ['July', 0, 0],
            ['Aug', 0, 0],
            ['Sept', 0, 0],
            ['Oct', 0, 0],
            ['Nov', 0, 0],
            ['Dec', 0, 0],
          ]);
        }

        const numVisitChart = new google.visualization.ColumnChart(me.numberOfVisits.nativeElement);
        numVisitChart.draw(numberOfVisitsData, this.numberOfVisitsOptions);


        const numberOfVirtualVisitsData = new google.visualization.DataTable();

        numberOfVirtualVisitsData.addColumn('string', 'Month Name');
        numberOfVirtualVisitsData.addColumn('number', 'Phone Call Count');
        numberOfVirtualVisitsData.addColumn('number', 'Virtual Video Call Count');

        const numberOfVirtualVisitsRows = [];

        if (data.Response && typeof data.Response.NumberOfVirtualVisitsResult != "undefined" && data.Response.NumberOfVirtualVisitsResult != null && data.Response.NumberOfVirtualVisitsResult.length != null
          && data.Response.NumberOfVirtualVisitsResult.length > 0) {

          data.Response.NumberOfVirtualVisitsResult.forEach(numOfVirtualVisit => {
            numberOfVirtualVisitsRows.push([numOfVirtualVisit.MonthName, numOfVirtualVisit.PhoneCallVisitCount, numOfVirtualVisit.VideoCallVisitCount])
          });

          numberOfVirtualVisitsData.addRows(numberOfVirtualVisitsRows);

          delete this.numberOfVirtualVisitsOptions.vAxis.ticks;

        } else {
          numberOfVirtualVisitsData.addRows([
            ['Jan', 0, 0],
            ['Feb', 0, 0],
            ['Mar', 0, 0],
            ['Apr', 0, 0],
            ['May', 0, 0],
            ['June', 0, 0],
            ['July', 0, 0],
            ['Aug', 0, 0],
            ['Sept', 0, 0],
            ['Oct', 0, 0],
            ['Nov', 0, 0],
            ['Dec', 0, 0],
          ]);
        }

        const numVirtualVisitChart = new google.visualization.ColumnChart(me.numberOfVirtualVisits.nativeElement);
        numVirtualVisitChart.draw(numberOfVirtualVisitsData, this.numberOfVirtualVisitsOptions);

        let targetvsAchivementData = [];

        targetvsAchivementData.push(['Dealer Name', 'Yearly Target', 'Year to Date Target', 'Year to Date Achievement', 'Open Opportunity']);

        if (data.Response && typeof data.Response.TargetAchivementResult != "undefined" && data.Response.TargetAchivementResult != null && data.Response.TargetAchivementResult.length != null
          && data.Response.TargetAchivementResult.length > 0) {
          data.Response.TargetAchivementResult.forEach(trAchiveData => {
            targetvsAchivementData.push([trAchiveData.DealerName, trAchiveData.YearlyTargetValue, trAchiveData.YearlyAchieveValue, trAchiveData.YearlyWonValue, trAchiveData.YearlyOpenValue]);
          });

          // delete this.targetvsAchivementOptions.hAxis.ticks;
          const targetvsAchivementOptions = {
            title: 'Target Vs Achievement',
            titleTextStyle: { fontSize: 13 },
            legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13 } },
            chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
            hAxis: { title: '', format: 'short', textStyle: { fontSize: 13 } },
            vAxis: { title: '', format: 'short', textStyle: { fontSize: 13 } },
            colors: ['#c8102e', '#0099cc', '#43b02a', '#ffcd00']
          }
          const trAchivementData = google.visualization.arrayToDataTable(targetvsAchivementData);
          const trAchivementChart = new google.visualization.ColumnChart(me.targetvsAchivement.nativeElement);

          trAchivementChart.draw(trAchivementData, targetvsAchivementOptions);
        } else {

          const targetvsAchivementOptions = { title: 'Target Vs Achievement', legend: { position: 'top', alignment: 'start' }, hAxis: { title: 'Dealer', ticks: [0] }, vAxis: { title: 'INR / Indian Rupee', format: '0' }, colors: ['#c8102e', '#0099cc', '#43b02a', '#ffcd00'] }

          me.AllDealer.forEach(trAchiveData => {
            const dealerName = trAchiveData.DealerName.slice(0, 5);
            targetvsAchivementData.push([dealerName, 0, 0, 0, 0]);
          });

          const trAchivementData = google.visualization.arrayToDataTable(targetvsAchivementData);
          const trAchivementChart = new google.visualization.ColumnChart(me.targetvsAchivement.nativeElement);

          trAchivementChart.draw(trAchivementData, targetvsAchivementOptions);
        }



        if (data.Response && typeof data.Response.NumberOfOpportunityResult != "undefined" && data.Response.NumberOfOpportunityResult != null && data.Response.NumberOfOpportunityResult.length != null
          && data.Response.NumberOfOpportunityResult.length > 0) {

          const numberOfOpportunityData = new google.visualization.DataTable();

          numberOfOpportunityData.addColumn('string', 'month');
          numberOfOpportunityData.addColumn('number', 'Preliminary Stage');
          numberOfOpportunityData.addColumn('number', 'Advanced Stage');
          numberOfOpportunityData.addColumn('number', 'Final Negotiation');

          const numberOfOpportunityRows = [];

          data.Response.NumberOfOpportunityResult.forEach(numOfOppData => {
            numberOfOpportunityRows.push([numOfOppData.MonthName, numOfOppData.PreliminaryStageValue, numOfOppData.AdvancedStageValue, numOfOppData.FinalNegotiationValue]);
          });

          delete this.numberOfOpportunityOptions.vAxis.ticks;

          numberOfOpportunityData.addRows(numberOfOpportunityRows);

          const numOfOpportunityChart = new google.visualization.ColumnChart(me.numberOfOpportunity.nativeElement);

          numOfOpportunityChart.draw(numberOfOpportunityData, me.numberOfOpportunityOptions);
        } else {
          var numberOfOpportunityData = new google.visualization.DataTable();
          numberOfOpportunityData.addColumn('string', 'Day');
          numberOfOpportunityData.addColumn('number', 'Preliminary Stage');
          numberOfOpportunityData.addColumn('number', 'Advanced Stage');
          numberOfOpportunityData.addColumn('number', 'Final Negotiation');

          numberOfOpportunityData.addRows([
            ['Jan', 0, 0, 0],
            ['Feb', 0, 0, 0],
            ['Mar', 0, 0, 0],
            ['Apr', 0, 0, 0],
            ['May', 0, 0, 0],
            ['June', 0, 0, 0],
            ['July', 0, 0, 0],
            ['Aug', 0, 0, 0],
            ['Sept', 0, 0, 0],
            ['Oct', 0, 0, 0],
            ['Nov', 0, 0, 0],
            ['Dec', 0, 0, 0],
          ]);

          const numOfOpportunityChart = new google.visualization.ColumnChart(me.numberOfOpportunity.nativeElement);

          numOfOpportunityChart.draw(numberOfOpportunityData, me.numberOfOpportunityOptions);
        }
        

        if (data.Response && typeof data.Response.OpportunityValuesBySalesPhaseResult != "undefined" && data.Response.OpportunityValuesBySalesPhaseResult != null && data.Response.OpportunityValuesBySalesPhaseResult.length != null
          && data.Response.OpportunityValuesBySalesPhaseResult.length > 0) {

          const OpportunityValuesBySalesPhaseData = new google.visualization.DataTable();

          OpportunityValuesBySalesPhaseData.addColumn('string', 'Month');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Preliminary Opportunity Value');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Advanced Opportunity Value');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Final Opportunity Value');

          const OpportunityValuesBySalesPhaseRows = [];

          data.Response.OpportunityValuesBySalesPhaseResult.forEach(OppSalesPhaseData => {
            OpportunityValuesBySalesPhaseRows.push([OppSalesPhaseData.MonthName, OppSalesPhaseData.PreliminaryOpportunityValue, OppSalesPhaseData.AdvanceOpportunityValue, OppSalesPhaseData.FinalOpportunityValue]);
          });

          delete this.OpportunityValuesBySalesPhaseOptions.vAxis.ticks;

          OpportunityValuesBySalesPhaseData.addRows(OpportunityValuesBySalesPhaseRows);

          const OpportunityValuesBySalesPhaseChart = new google.visualization.ColumnChart(me.opportunityValue.nativeElement);

          OpportunityValuesBySalesPhaseChart.draw(OpportunityValuesBySalesPhaseData, me.OpportunityValuesBySalesPhaseOptions);
        } else {
          var OpportunityValuesBySalesPhaseData = new google.visualization.DataTable();
          OpportunityValuesBySalesPhaseData.addColumn('string', 'Month');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Preliminary Opportunity Value');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Advanced Opportunity Value');
          OpportunityValuesBySalesPhaseData.addColumn('number', 'Final Opportunity Value');

          OpportunityValuesBySalesPhaseData.addRows([
            ['Jan', 0, 0, 0],
            ['Feb', 0, 0, 0],
            ['Mar', 0, 0, 0],
            ['Apr', 0, 0, 0],
            ['May', 0, 0, 0],
            ['June', 0, 0, 0],
            ['July', 0, 0, 0],
            ['Aug', 0, 0, 0],
            ['Sept', 0, 0, 0],
            ['Oct', 0, 0, 0],
            ['Nov', 0, 0, 0],
            ['Dec', 0, 0, 0],
          ]);

          const OpportunityValuesBySalesPhaseChart = new google.visualization.ColumnChart(me.opportunityValue.nativeElement);

          OpportunityValuesBySalesPhaseChart.draw(numberOfOpportunityData, me.OpportunityValuesBySalesPhaseOptions);
        }


        // ----------------------------------------------------------------------------------------------
        let tragetQuantityAchivmentData = [];


        tragetQuantityAchivmentData.push(['Dealer Name', 'Yearly Target', 'Year to Date Achievement',]);

        if (data.Response && typeof data.Response.QuantityTargetAchivementResult != "undefined" && data.Response.QuantityTargetAchivementResult != null && data.Response.QuantityTargetAchivementResult.length != null
          && data.Response.QuantityTargetAchivementResult.length > 0) {
          data.Response.QuantityTargetAchivementResult.forEach(trQtyAchivment => {

            tragetQuantityAchivmentData.push([trQtyAchivment.DealerName, trQtyAchivment.YearlyTargetValue, trQtyAchivment.YearlyAchieveValue]);
          });

          delete this.tragetQuantityAchivmentOptions.vAxis.ticks;
        } else {
          me.AllProducts.forEach(trQtyAchivment => {
            // const dealerName = trQtyAchivment.ProductRangeName.slice(0, 5);
            tragetQuantityAchivmentData.push([trQtyAchivment.ProductRangeName, 0, 0]);
          });
        }

        const trQtyAchivmentData = google.visualization.arrayToDataTable(tragetQuantityAchivmentData);
        const tragetQuantityAchivmentChart = new google.visualization.ColumnChart(me.tragetQuantityAchivment.nativeElement);

        tragetQuantityAchivmentChart.draw(trQtyAchivmentData, me.tragetQuantityAchivmentOptions);




        let opportunitiesForCoreProductsData = [];

        opportunitiesForCoreProductsData.push(['Values', 'Product Value', 'Product Quantity']);

        if (data.Response && typeof data.Response.ProductwiseValueAndQtyResult != "undefined" && data.Response.ProductwiseValueAndQtyResult != null && data.Response.ProductwiseValueAndQtyResult.length != null
          && data.Response.ProductwiseValueAndQtyResult.length > 0) {
          data.Response.ProductwiseValueAndQtyResult.forEach(oppoForCoreProducts => {
            opportunitiesForCoreProductsData.push([oppoForCoreProducts.Name, oppoForCoreProducts.NetValue, oppoForCoreProducts.TotalQuantity]);
          });

          // delete this.opportunityForCoreProductsOptions.vAxes[0].ticks;
          // delete this.opportunityForCoreProductsOptions.vAxes[1].ticks;
          const opportunityForCoreProductsOptions = {
            title: 'Product Wise Open Opportunity Value and Quantity',
            titleTextStyle: { fontSize: 13 },
            chartArea: { left: 30, top: 50, right: 30, width: 500, height: 210 },
            vAxes: [{ title: '', format: 'short' }, { title: '', format: '0' }],
            legend: { position: 'top', alignment: 'start', textStyle: { fontSize: 13, fontWeight: 400 } },
            vAxis: { 0: { title: '' }, 1: { title: '' }, textStyle: { fontSize: 13 } },
            hAxis: { title: '', textStyle: { fontSize: 12 } }, rAxis: { title: '' }, seriesType: 'bars', series: { 0: { targetAxisIndex: 0 }, 1: { targetAxisIndex: 1, type: 'line' } },
            colors: ['#c8102e', '#373D41', '#373D41'],
            bar: { groupWidth: 20 }
          };

          const oppoForCoreProductsData = google.visualization.arrayToDataTable(opportunitiesForCoreProductsData);
          const oppoForCoreProductsChart = new google.visualization.ComboChart(me.opportunityForCoreProducts.nativeElement);

          oppoForCoreProductsChart.draw(oppoForCoreProductsData, opportunityForCoreProductsOptions);

        } else {
          me.AllProducts.forEach(oppoForCoreProducts => {
            opportunitiesForCoreProductsData.push([oppoForCoreProducts.ProductRangeName, 0, 0]);
          });

          const opportunityForCoreProductsOptions = {
            title: 'Product Wise Open Opportunity Value and Quantity',
            vAxes: [
              { title: 'Value', format: '0' },
              { title: 'Quantity', format: '0' }
            ],
            legend: { position: 'top', alignment: 'start' },
            vAxis: {
              0: { title: 'Value' },
              1: { title: 'Quantity' },
            }, hAxis: { title: 'Product' }, rAxis: { title: 'Qty.' }, seriesType: 'bars', series: {
              0: { targetAxisIndex: 0 },
              1: { targetAxisIndex: 1, type: 'line' }
            }, colors: ['#c8102e', '#373D41', '#373D41'], bar: { groupWidth: 20 }
          };

          const oppoForCoreProductsData = google.visualization.arrayToDataTable(opportunitiesForCoreProductsData);
          const oppoForCoreProductsChart = new google.visualization.ComboChart(me.opportunityForCoreProducts.nativeElement);

          oppoForCoreProductsChart.draw(oppoForCoreProductsData, opportunityForCoreProductsOptions);
        }

        if (data.Response && typeof data.Response.OpportunityValuesCreateDateBySalesPhaseResult != "undefined" && data.Response.NumberOfOpportunityResult != null && data.Response.NumberOfOpportunityResult.length != null
          && data.Response.OpportunityValuesCreateDateBySalesPhaseResult.length > 0) {

          const numberOfOpportunityOnCreatedDateData = new google.visualization.DataTable();

          numberOfOpportunityOnCreatedDateData.addColumn('string', 'month');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Preliminary Stage');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Advanced Stage');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Final Negotiation');

          const numberOfOpportunityOnCreatedDateRows = [];

          data.Response.OpportunityValuesCreateDateBySalesPhaseResult.forEach(numOfOppData1 => {
            numberOfOpportunityOnCreatedDateRows.push([numOfOppData1.MonthName, numOfOppData1.PreliminaryOpportunityValue, numOfOppData1.AdvanceOpportunityValue, numOfOppData1.FinalOpportunityValue]);
          });

          delete this.numberOfOpportunityOnCreatedDateOptions.vAxis.ticks;

          numberOfOpportunityOnCreatedDateData.addRows(numberOfOpportunityOnCreatedDateRows);

          const numOfOpportunityOnCreatedDateChart = new google.visualization.ColumnChart(me.numberOfOpportunityOnCreatedDate.nativeElement);

          numOfOpportunityOnCreatedDateChart.draw(numberOfOpportunityOnCreatedDateData, me.numberOfOpportunityOnCreatedDateOptions);
        } else {
          var numberOfOpportunityOnCreatedDateData = new google.visualization.DataTable();
          numberOfOpportunityOnCreatedDateData.addColumn('string', 'Day');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Preliminary Stage');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Advanced Stage');
          numberOfOpportunityOnCreatedDateData.addColumn('number', 'Final Negotiation');

          numberOfOpportunityOnCreatedDateData.addRows([
            ['Jan', 0, 0, 0],
            ['Feb', 0, 0, 0],
            ['Mar', 0, 0, 0],
            ['Apr', 0, 0, 0],
            ['May', 0, 0, 0],
            ['June', 0, 0, 0],
            ['July', 0, 0, 0],
            ['Aug', 0, 0, 0],
            ['Sept', 0, 0, 0],
            ['Oct', 0, 0, 0],
            ['Nov', 0, 0, 0],
            ['Dec', 0, 0, 0],
          ]);

          const numOfOpportunityOnCreatedDateChart = new google.visualization.ColumnChart(me.numberOfOpportunityOnCreatedDate.nativeElement);

          numOfOpportunityOnCreatedDateChart.draw(numberOfOpportunityOnCreatedDateData, me.numberOfOpportunityOnCreatedDateOptions);
        }



      }
    })
  }
  currDate: any;
  ngOnInit() {

    const me = this;
    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
   
    console.log(' nginit ' + fromDate);
    const datetype = -1;
    let filter = 'FromDate=' + fromDate + '&ToDate=' + toDate + '&ZoneID=' + zone + '&UserID=' + me.sessionData.UserID + '&DealerID=' + dealer + '&DealerSEID=' + dealerSelse + '&RoleName=' + me.sessionData.RoleName + '&DateType=' + datetype;

    me.dashboardService.dashboard(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {

        me.next30Days = (data.Response && data.Response.Net30DaysValue > 0) ? data.Response.Net30DaysValue : 0;
        me.next90Days = (data.Response && data.Response.Net90DaysValue > 0) ? data.Response.Net90DaysValue : 0;
        me.pastOverDue = (data.Response && data.Response.PastOverDueValue > 0) ? data.Response.PastOverDueValue : 0;
        me.overallValue = (data.Response && data.Response.OverallOpportunityValue > 0 ? data.Response.OverallOpportunityValue : 0);
      }
    })

    me.getZone();
    me.getProducts();
    me.currDate = new Date();
    me.ReportDatails.ToDate = me.currDate;
    me.changeDetectorRef.detectChanges();
    me.ReportDatails.FromDate = new Date(new Date().getFullYear(),0,1);

    me.getAllDealer();
    for (var m = 1; m <= 12; m++)
      me.dateTicks.push(new Date('2019-' + m + '-1'));
    //console.log(me.dateTicks);

  }
  changeDate(FromDate) {
    this.ReportDatails.ToDate = undefined;
  }
  ngAfterViewInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart(-1));
  }


  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = { ZoneID: '', ZoneName: 'All' }
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = { DealerID: '', DealerName: 'All' }
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getProducts() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      me.AllProducts = data.Response;
    });
  }



  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = { DealerID: '', DealerName: 'All' }
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getAllDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.AllDealer = data.Response;
    });
  }

  initialize(dashboardfilterform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    dashboardfilterform.reset();
    me.ngOnInit();
    me.ngAfterViewInit();    
  }
}
