import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductRange } from '../../model/master/productRange/productrange';
import { ProductRangeService } from '../../service/master/productRange/product-range.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-productrange',
  templateUrl: './productrange.component.html',
  styleUrls: ['./productrange.component.css']
})
export class ProductrangeComponent implements OnInit {
  term: string;
  p: number;
  productRange = new ProductRange();
  responseMessage: any;
  message: string;
  data: Array<ProductRange>;
  sessionData:any;
  constructor(
    private productRangeService: ProductRangeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.productRange.IsActive = true;
    me.search(me.term);
  }

  serviceSegment() {
    const me = this;
    me.productRange.ModifiedBy = me.sessionData.UserID;
    me.productRangeService.submit(me.productRange).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update() {
    const me = this;
    this.productRangeService.update(me.productRange).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  submit() {
    const me = this;
    if (me.productRange && me.productRange.ProductRangeID == null || me.productRange && me.productRange.ProductRangeID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(productRangeData) {
    const me = this;
    me.productRange.ProductRangeID = productRangeData.ProductRangeID;
    me.productRange.ProductRangeName = productRangeData.ProductRangeName;
    me.productRange.ProductRangeDescription = productRangeData.ProductRangeDescription;
    me.productRange.IsActive = productRangeData.IsActive;
    me.productRange.ModifiedBy = me.sessionData.UserID;
  }

  // search() {
  //   const me = this;
  //   me.productRangeService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }

  search(term: string) {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.ProductRangeName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.productRange = new ProductRange();
  }
}
