import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OppStatus } from '../../../model/master/oppstatus/OppStatus';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OppStatusService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(OpportunityStatus): Observable<OppStatus[]> {
    // return this.http.post<OppStatus[]>(this.url+"api/OpportunityStatus/Insert", OpportunityStatus);
    return this.http.post<OppStatus[]>(`${environment.apiUrl}/OpportunityStatus/Insert`, OpportunityStatus);

  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/OpportunityStatus");
    return this.http.get<any[]>(`${environment.apiUrl}/OpportunityStatus`);
  }
  public update(OpportunityStatus): Observable<OppStatus[]> {
    // return this.http.post<OppStatus[]>(this.url+"api/OpportunityStatus/Update", OpportunityStatus);
    return this.http.post<OppStatus[]>(`${environment.apiUrl}/OpportunityStatus/Update`, OpportunityStatus);
  }
}
