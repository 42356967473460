import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-com-performance',
  templateUrl: './dealer-com-performance.component.html',
  styleUrls: ['./dealer-com-performance.component.css']
})
export class DealerComPerformanceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
