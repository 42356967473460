import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  DealerSEID: number;
  public GetReportData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetReportData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetReportData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  }

  public getDealerName(ZoneID, ModifiedBy): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Dealer/getByZoneID?ZoneID=` + ZoneID);
  }

  public searchACUser(ZoneID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Users?ZoneID=` + ZoneID);
  }

  public GetQuotationAndQuotationDetailsData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetQuotationAndQuotationDetailsData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetQuotationAndQuotationDetailsData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  
  }

  
  public getLeadReportData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetLeadDetailsData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetLeadDetailsData?FromDate=01/12/2019&ToDate=26/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  }

  public GetVisitAndVisitDetailsData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetVisitAndVisitDetailsData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetVisitAndVisitDetailsData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  
  }

  public GetDealerSEPerformanceData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetDealerSEPerformanceData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetDealerSEPerformanceData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  
  }

  public GetAccountDetailsData(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetAccountDetailsData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetAccountDetailsData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  
  }

  public GetUserZoneByRole(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/UsersZone/GetZonesByUserID?UserID=` + UserID + `&RoleName=` + RoleName + '&ModifiedBy=' + UserID, {});
  }

  public GetContactDetailsData(FromDate, ToDate, AccountID, ZoneID, UserID, DealerID, DealerSEID, ModifiedBy,RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetContactDetailsData?FromDate=` + FromDate + `&ToDate=` + ToDate + `&AccountID=` + AccountID + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + '&RoleName=' + RoleName);
    // return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetContactDetailsData?FromDate=01/01/2019&ToDate=23/12/2019&ZoneID=2&UserID=1&DealerID=2&DealerSEID=3`);
  
  }
}
