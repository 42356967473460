import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dealer } from '../../../model/master/dealer/dealer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerTargetService {

  url = 'https://krios-growvac.azurewebsites.net/';
  constructor(private http: HttpClient) { }


  public submit(data): Observable<any[]> {
    return this.http.post<Dealer[]>(`${environment.apiUrl}/DealerTarget/Insert`, data);
  }

  public search(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DealerTarget`);
  }

  public update(data): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/DealerTarget/Update`, data);
  }
}
