import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { Zone } from 'src/app/model/master/zone/zone';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private http: HttpClient) { }

  public submit(zone: Zone): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/Zone/Insert", zone);
    return this.http.post<Role[]>(`${environment.apiUrl}/Zone/Insert`, zone);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/Zone");
    return this.http.get<any[]>(`${environment.apiUrl}/Zone`);
  }
  public update(zone: Zone): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/Zone/Update", zone);
    return this.http.post<Role[]>(`${environment.apiUrl}/Zone/Update`, zone);
  }
}
