import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SalesPhase } from '../../model/master/salesphase/salesphase';
import { SalesphaseService } from '../../service/master/salesphase/salesphase.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-salesphase',
  templateUrl: './salesphase.component.html',
  styleUrls: ['./salesphase.component.css']
})
export class SalesphaseComponent implements OnInit {
  p: number;
  salesPhase = new SalesPhase();
  responseMessage: any;
  message: string;
  data: Array<SalesPhase>;
  sessionData: any;
  constructor(
    private salesphaseService: SalesphaseService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    const me = this;
    me.salesPhase.IsActive = true;
    me.search();
  }

  serviceSegment() {
    const me = this;
    me.salesPhase.ModifiedBy = me.sessionData.UserID;
    me.salesphaseService.submit(me.salesPhase).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.salesphaseService.update(me.salesPhase).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.salesPhase && me.salesPhase.SalesPhaseID == null || me.salesPhase && me.salesPhase.SalesPhaseID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(salesPhaseData) {
    const me = this;
    me.salesPhase.SalesPhaseID = salesPhaseData.SalesPhaseID;
    me.salesPhase.SalesPhaseName = salesPhaseData.SalesPhaseName;
    me.salesPhase.SalesPhaseDescription = salesPhaseData.SalesPhaseDescription;
    me.salesPhase.IsActive = salesPhaseData.IsActive;
    me.salesPhase.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.salesphaseService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.salesPhase = new SalesPhase();
  }
}
