import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }


  public submit(contact): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/Contacts/Insert`, contact);
  }


  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Contacts/GetAll?UserID=` + UserID + `&RoleName=` + RoleName);
  }

  public accountSearch(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Contacts`);
  }

  public update(data): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/Contacts/Update`, data);
  }

  public delete(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/Contacts/Update`, data);
  }
}
