import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DealerAdmin } from 'src/app/model/master/dealer-admin/DealerAdmin';
import { DealerAdminService } from 'src/app/service/master/dealer-admin/dealer-admin.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-dealeradmin',
  templateUrl: './dealeradmin.component.html',
  styleUrls: ['./dealeradmin.component.css']
})
export class DealeradminComponent implements OnInit {

  dealerAdmin = new DealerAdmin();
  data: DealerAdminService[];
  p: number;
  dealerData: any[];
  createData: any;
  updateData: any;
  sessionData: any;
  responseMessage: any;
  message: string;
  constructor(
    private dealerAdminService: DealerAdminService,
    private dealerService: DealerService,
    private toastr: ToastrService, protected changeDetectorRef: ChangeDetectorRef) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }


  ngOnInit() {
    const me = this;
    me.search();
    me.getDealer();
  }

  submit(dealerAdmin: DealerAdmin) {
    const me = this;
    if (dealerAdmin.DealerAdminID == null && dealerAdmin.DealerAdminID === undefined) {
      me.create(dealerAdmin);
    } else {
      me.update(dealerAdmin);
    }
  }

  create(dealerAdmin: DealerAdmin) {
    const me = this;
    me.dealerAdmin.ModifiedBy = me.sessionData.UserID;
    me.dealerAdminService.submit(dealerAdmin).subscribe(data => {
      me.createData = data;
      me.responseMessage = data;
      me.message = me.createData.Message;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update(dealerAdmin: DealerAdmin) {
    const me = this;
    const obj = {
      DealerAdminID: dealerAdmin.DealerAdminID,
      DealerID: dealerAdmin.DealerID,
      FirstName: dealerAdmin.FirstName,
      LastName: dealerAdmin.LastName,
      EmailID: dealerAdmin.EmailID,
      UserName: dealerAdmin.UserName,
      UserPassword: dealerAdmin.UserPassword,
      IsActive: dealerAdmin.IsActive,
      ModifiedBy: me.sessionData.UserID
    };
    me.dealerAdminService.update(obj).subscribe(data => {
      me.updateData = data;
      me.responseMessage = data;
      me.message = me.updateData.Message;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  getDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.dealerData = Utils.filterData(data.Response);
    });
  }

  edit(dealerData: DealerAdmin) {
    const me = this;
    me.dealerAdmin.DealerAdminID = dealerData.DealerAdminID;
    me.dealerAdmin.DealerID = dealerData.DealerID;
    me.dealerAdmin.DealerName = dealerData.DealerName;
    me.dealerAdmin.FirstName = dealerData.FirstName;
    me.dealerAdmin.LastName = dealerData.LastName;
    me.dealerAdmin.EmailID = dealerData.EmailID;
    me.dealerAdmin.UserName = dealerData.UserName;
    me.dealerAdmin.UserPassword = dealerData.UserPassword;
    me.dealerAdmin.IsActive = dealerData.IsActive;
    me.dealerAdmin.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.dealerAdminService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.dealerAdmin = new DealerAdmin();
  }
}
