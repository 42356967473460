import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { Zone } from 'src/app/model/master/zone/zone';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.css']
})
export class ZoneComponent implements OnInit {
  data: any[];
  zone = new Zone();
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private zoneService: ZoneService,
    protected changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(zone: Zone) {
    const me = this;
    if (zone.ZoneID == null && zone.ZoneID === undefined) {
      me.create(zone);
    } else {
      me.update(zone);
    }
  }

  search() {
    const me = this;
    me.zoneService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  create(zone: Zone) {
    const me = this;
    me.zone.ModifiedBy = me.sessionData.UserID;
    me.zoneService.submit(zone).subscribe(data => {
      me.createData = data;
      
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(zone: Zone) {
    const me = this;
    me.zoneService.update(zone).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  edit(zoneData: Zone) {
    const me = this;
    me.zone.ZoneID = zoneData.ZoneID;
    me.zone.ZoneName = zoneData.ZoneName;
    me.zone.ZoneDescription = zoneData.ZoneDescription;
    me.zone.IsActive = zoneData.IsActive;
    me.zone.ModifiedBy = me.sessionData.UserID;
  }

  initialize(zoneform: NgForm) {
    const me = this;
    zoneform.reset();
    me.changeDetectorRef.detectChanges();
    me.zone = new Zone();
  }
}
