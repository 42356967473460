import { QuoteUpdateDetails } from './quoteupdatedetails';

export class QuoteUpdate {
    QuotationID: number;
    QuotationDate: string;
    LeadID: number;
    LeadName: string;
    OpportunityName: string = "";
    ProductRangeID: number;
    ProductRangeName: string
    ProductModelID: number;
    ProductModelName: string;
    Quantity: number;
    NetValue: number;
    SalesPhaseID: number;
    SalesPhaseName: string;
    WinningChanceID: number = undefined;
    ChancePercentage: string;
    OpportunityModeID: number;
    OpportunityModeName: string;
    OpportunityStatusID: number;
    OpportunityStatusName: string
    OpportunityExpectedDate: string = "";
    WinningReasonsID: number = undefined;
    WinningReasonsName: string;
    LossingReasonsID: number = undefined;
    LossingReasonsName: string;
    OrderWonID: number = undefined;
    OrderWonName: string;
    OrderWonDate: string = null;
    CompetitionPrice: number;
    AttachmentPath: string = null;
    DealerPOAttachmentPath: string = null;
    CustomerPOAttachmentPath: string = null;
    DocFileName: string = null;
    DealerFileName: string = null;
    CustomerFileName: string = null;
    IsActive: boolean = true;
    ModifiedBy: number;
    objQuotationDetails: QuoteUpdateDetails[] = [];
}


