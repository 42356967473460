export class TopVisits
{
    UserVisitCountID: number;
    DealerName: string;
    DealerSEName: string;
    VisitCount: number;
    MonthName: string;
    MonthYear: number;
    IsActive: boolean = true;
    ModifiedBy: any;
    IsReleaseToAll: boolean = false;
    ValidateKey?: number;
}