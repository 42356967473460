export class HelpDesk {
    StartDate: Date;
    EndDate: Date;
    FileTitle: number;
    UpFileName: string = null;
    UpFilePath: string = null;
    IsActive: boolean = true;
    UpFileID: number;
    ModifiedBy: number;
}
