import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CompetionMake } from '../../model/master/competionmake/competionmake';
import { CompetionmakeService } from '../../service/master/competionmake/competionmake.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-competionmake',
  templateUrl: './competionmake.component.html',
  styleUrls: ['./competionmake.component.css']
})
export class CompetionmakeComponent implements OnInit {
  term: string;
  p: number;
  competionMake = new CompetionMake();
  responseMessage: any;
  message: string;
  data: any;
  sessionData : any;
  constructor(
    private competionmakeService: CompetionmakeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.competionMake.IsActive = true;
    me.search(me.term);
  }

  serviceRole() {
    const me = this;
    me.competionMake.ModifiedBy = me.sessionData.UserID;
    me.competionmakeService.submit(me.competionMake).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update() {
    const me = this;
    this.competionmakeService.update(me.competionMake).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  submit() {
    const me = this;
    if (me.competionMake && me.competionMake.CompetitionMakeID === null
      || me.competionMake && me.competionMake.CompetitionMakeID === undefined) {
      me.serviceRole();
    } else {
      me.update();
    }

  }

  edit(competionmake) {
    const me = this;
    me.competionMake.CompetitionMakeID = competionmake.CompetitionMakeID;
    me.competionMake.CompetitionMakeName = competionmake.CompetitionMakeName;
    me.competionMake.CompetitionMakeDescription = competionmake.CompetitionMakeDescription;
    me.competionMake.IsActive = competionmake.IsActive;
    me.competionMake.ModifiedBy = me.sessionData.UserID;
  }

  // search() {
  //   const me = this;
  //   me.competionmakeService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }

  search(term: string) {
    const me = this;
    me.competionmakeService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.CompetitionMakeName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.competionMake = new CompetionMake();
  }

}
