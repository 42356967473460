import { Component, OnInit } from '@angular/core';
import { SegmentService } from '../../service/master/segment/segment.service'
import { Segment } from '../../model/master/segment/segment';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { MassMailList} from '../mass-mail-list/MassMailList';
import { MassMailService } from '../../service/master/massmail/massmail.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-mass-mail-list',
  templateUrl: './mass-mail-list.component.html',
  styleUrls: ['./mass-mail-list.component.css']
})
export class MassMailListComponent implements OnInit {
  public segment = new Segment();
  public responseMessage: any;
  public message: string;
  public sessionData: any;  
  ModifiedBy: number;
  public SegmentList: Array<Segment>;
  public AccountList: Array<any>;  
  public MassMailerDetailsList: Array<any>;
  public SegmentID: any;
  public AccountID: any;
  public Subject: any;
  public Body: any;
  public SegmentDropdownHasError = true;
  public AccountDropdownHasError = true;
  MassmailerListDetails = new MassMailList();
  
  ZoneList: any[];
  DealerList: any[];
  responseMessageInsert: any;
  constructor(private segmentService: SegmentService,private massmailservice: MassMailService,private router: Router,private toastr: ToastrService, private reportService: ReportService, private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService) { 
      const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
    this.SegmentID = 0;
    this.AccountID = 0;
    }

  ngOnInit() {
    const me = this;
    this.getZone();
    
    me.sessionData.RoleName = me.sessionData.RoleName;
    if(me.sessionData.RoleName == 'Dealer'){
      me.MassmailerListDetails.DealerID = me.sessionData.UserID;
      this.getSegmentList(me.MassmailerListDetails.DealerID);
    }   

    this.Search();
  }
  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      // me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;
      me.MassmailerListDetails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      // me.ReportDatails.DealerID = me.DealerList[0].DealerID;
      me.MassmailerListDetails.DealerID = me.DealerList[0].DealerID;

      let item2 = {SegmentID:'',SegmentName:'All'}
      data.Response.splice(0, 0, item2);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerListDetails.SegmentID = me.SegmentList[0].SegmentID;
     
    });
  }
  
  getDealerName(ZoneID) {    
    const me = this;    
    me.MassmailerListDetails.UserID = null
    me.MassmailerListDetails.DealerID = null
    me.MassmailerListDetails.DealerSEID = null
    me.MassmailerListDetails.SegmentID = null

    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;      
      me.MassmailerListDetails.DealerID = me.DealerList[0].DealerID;

      let item1 = {SegmentID:'',SegmentName:'All'}
      data.Response.splice(0, 0, item1);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerListDetails.SegmentID = me.SegmentList[0].SegmentID;
     });
  }

  getSegmentList(DealerID) {    
    const me = this;
    me.segmentService.GetSegmentsByDealerID(DealerID).subscribe(data => {
      let item = {SegmentID:'',SegmentName:'All'}
      data.Response.splice(0, 0, item);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerListDetails.SegmentID = me.SegmentList[0].SegmentID;
      
    });
  }

  Search() {    
    const me = this;    
    // var body = {
    //   ZoneID: me.MassmailerListDetails.ZoneID,
    //   UserID: me.sessionData.UserID,
    //   DealerID: me.MassmailerListDetails.DealerID,//null,
    //   DealerSEID: null,
    //   SegmentID: parseInt(this.SegmentID),
    //   RoleName: me.sessionData.RoleName
    // };
    var DealerSEID: null
    this.SegmentID = me.MassmailerListDetails.SegmentID;
    me.massmailservice.search(me.MassmailerListDetails.ZoneID,me.sessionData.UserID,me.MassmailerListDetails.DealerID,DealerSEID,parseInt(this.SegmentID),me.sessionData.RoleName).subscribe(data => {
     
      me.MassMailerDetailsList = data.Response;
    });
  }

  View(item) {    
    localStorage.setItem('MassMailData', JSON.stringify(item));
    localStorage.setItem('RedirectFromMailList', 'true');
    this.router.navigate(['/massmailview']);
  }
}
