import { VistiDetails } from './VistiDetails';

export class VisitUpdate {
    VisitID: number;
    VisitDate: any;
    LeadID: number;
    VisitTypeID: number;
    DealerID: number;
    DealerSEID: number;
    AccountID: number;
    ApplicationID: number;
    CustomerProductModelID: number;
    PotentialType: any;
    LeadTypeID: number;
    ProductRangeID: number;
    ProductModelID: number;
    VisitRemark: string;
    ModifiedBy: any;
    objVistiDetails: Array<VistiDetails> = [];
}