import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SegmentService } from '../../service/master/segment/segment.service';
import { Segment } from '../../model/master/segment/segment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.css']
})
export class SegmentComponent implements OnInit {
  term: string;
  p: number;
  segment = new Segment();
  responseMessage: any;
  message: string;
  data: Array<Segment>;
  sessionData: any;
  constructor(
    private segmentService: SegmentService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.segment.IsActive = true;
    me.search(me.term);
  }

  serviceSegment() {
    const me = this;
    me.segment.ModifiedBy = me.sessionData.UserID;
    me.segmentService.submit(me.segment).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update() {
    const me = this;
    this.segmentService.update(me.segment).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }

      me.search(me.term);
    });
  }

  submit() {
    const me = this;
    if (me.segment && me.segment.SegmentID == null || me.segment && me.segment.SegmentID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }

  }
  edit(roleData) {
    const me = this;
    me.segment.SegmentID = roleData.SegmentID;
    me.segment.SegmentName = roleData.SegmentName;
    me.segment.SegmentDescription = roleData.SegmentDescription;
    me.segment.IsActive = roleData.IsActive;
    me.segment.ModifiedBy = me.sessionData.UserID;
  }

  search(term: string) {
    const me = this;
    me.segmentService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.SegmentName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  initialize(segmentform: NgForm) {
    const me = this;
    segmentform.reset();
    me.changeDetectorRef.detectChanges();
    me.segment = new Segment();
  }
}
