import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
import { VisitService } from 'src/app/service/transaction/visit/visit.service';

@Component({
  selector: 'app-viewvirtualcallhistory',
  templateUrl: './viewvirtualcallhistory.component.html',
  styleUrls: ['./viewvirtualcallhistory.component.css']
})
export class ViewvirtualcallhistoryComponent implements OnInit {
  RouterParams: any;
  ResponseData: any;
  noDataFoundMsg = false;
  fileName = 'Virtual Call Report';

  constructor(
    private route: ActivatedRoute, router: Router,
    private visitService: VisitService,
    private exportService: ExcelService
  ) { }

  ngOnInit() {
    this.OnLoad();
  }

  OnLoad = () => {
    const me = this;
    this.route.params.subscribe(params => {
      me.RouterParams = JSON.parse(params['data']);
      me.visitService.GetVirtualCallDetails(me.RouterParams.VisitID, me.RouterParams.RoleName).subscribe(data => {
        me.ResponseData = data.Response;
      });
    });
  }

  export() {
    const me = this;
    const sendDataToExport = [];
    me.ResponseData.forEach(element => {
      const obj = {
        'Virtual Call Date': element.VirtualCallDate,
        'Visit ID': element.VisitID,
        'Visit Type': element.VisitType,
        'Dealer Name': element.DealerName,
        'Dealer Engineer Name': element.DealerEngineerName,
        'Company Name': element.CompanyName,
        'Application Name': element.ApplicationName
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }
}
