import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ACEnquiries } from './ACEnquiries';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';
import { AcenquiriesService } from 'src/app/service/transaction/acenquiries/acenquiries.service';
import { VisitService } from 'src/app/service/transaction/visit/visit.service';

@Component({
  selector: 'app-acenquiries',
  templateUrl: './acenquiries.component.html',
  styleUrls: ['./acenquiries.component.css']
})
export class ACEnquiriesComponent implements OnInit {
 
  acEnquiries = new ACEnquiries();
  DealerList: any[];
  acUserList: any[];
  DealerSEList: any[];
  accountTypeList: any[];
  sessionData: any = [];
  EnqueryStatusList: any = [];
  currDate: string;
  responseMessage: any;
  message: string;
  data: any;
  userData: any;
  enqStatusList: any = [];

  

  constructor(
    private accountService: AccountService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef,
    private acenquiriesService: AcenquiriesService,
    private visitService: VisitService
    ) { 
      const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {

    const me = this;
    me.getCurrDate();
    me.search();
    me.getAllAcUsers();
    me.searchDealer();
    me.searchAccountType();
    me.GetEnquiryStatus();
    me.enqStatusList.push({ name: 'Yes', VisitStatus: "Yes" },{ name: 'No', VisitStatus: "No" });
    // console.log(me.enqStatusList);
    // me.searchState();
    // me.getUserDataByID(me.sessionData.UserID);
   
  }

  search() {
    const me = this;
    me.acenquiriesService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
      //console.log(data.Response);
      
    });
  }

  getAllAcUsers()
  {
    const me = this;
    me.acenquiriesService.getAllAcUsers(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.acUserList = data.Response;
      // console.log(data.Response);
      
    });
  }

  searchDealer() {
    const me = this;
    me.accountService.searchDealer().subscribe(data => {
      me.DealerList = Utils.filterData(data.Response);
      // console.log(me.DealerList);

    });
  }

  searchDealerSE(DealerID) {
    const me = this;
    me.acEnquiries.DealerSEID = undefined;
    me.accountService.searchDealerSE(DealerID).subscribe(data => {
      me.DealerSEList = Utils.filterData(data.Response);
      // console.log(me.DealerSEList);
    });
  }

  searchAccountType() {
    const me = this;
    me.accountService.searchAccountType().subscribe(data => {
      me.accountTypeList = Utils.filterData(data.Response);
    });
  }

  getCurrDate() {
    const me = this;
    me.currDate = new Date(new Date().getFullYear(), (new Date().getMonth()),
      new Date().getUTCDate(), 0, 0, 0, 0).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  }

  initialize(enquiriesform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    me.acEnquiries.EnqID = undefined;
    me.acEnquiries.EnqStatusID= undefined;
    enquiriesform.reset();
    
    me.search();
  }

  submit() {
    const me = this;
    // console.log(me.acEnquiries.userID);
    
    if (me.acEnquiries && me.acEnquiries.EnqID == null || me.acEnquiries && me.acEnquiries.EnqID === undefined) {
      me.create();
    } else {
      me.update();
    }
  }

  create() {
    const me = this;
    me.acEnquiries.EnqDate = me.currDate;
    me.acEnquiries.ModifiedBy = me.sessionData.UserID;
    me.acEnquiries.UserID = me.sessionData.UserID,
    me.acEnquiries.RoleName = me.sessionData.RoleName
    me.acenquiriesService.submit(me.acEnquiries).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update() {
    const me = this;

    this.acenquiriesService.update(me.acEnquiries).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  getUserByID(userID) {
    const me = this;
    me.acenquiriesService.getUserByID(userID).subscribe(data => {
      me.acUserList = data.Response;
      // console.log(data.Response);
      
    });
  }

  GetEnquiryStatusByID(EnqStatusID) {
    const me = this;
    // console.log(EnqStatusID);
    
    me.acenquiriesService.GetEnquiryStatusByID(EnqStatusID).subscribe(data => {
      me.EnqueryStatusList = data.Response;
      // console.log(data.Response);
      
    });
  }

  GetEnquiryStatus() {
    const me = this;
    me.acenquiriesService.GetEnquiryStatus().subscribe(data => {
      me.EnqueryStatusList = data.Response;
      // console.log(data.Response);
      
    });
  }

  edit(enquiryData) {
    const me = this;    
    me.searchDealerSE(enquiryData.DealerID);
    // me.getUserByID(enquiryData.UserID);
    // me.GetEnquiryStatusByID(enquiryData.EnqStatusID);
    // me.GetEnquiryStatus();
    console.log(enquiryData);
    // console.log(me.acEnquiries);
    me.acEnquiries.EnqID = enquiryData.EnqID;
    me.acEnquiries.EnqDate = enquiryData.EnqDate;
    me.acEnquiries.ACUserID = enquiryData.ACUserID;
    me.acEnquiries.ACUserName = enquiryData.ACUserName;
    me.acEnquiries.DealerID = enquiryData.DealerID;
    me.acEnquiries.DealerName = enquiryData.DealerName;
    me.acEnquiries.DealerSEID = enquiryData.DealerSEID;
    me.acEnquiries.DealerSEName = enquiryData.DealerSEName;
    me.acEnquiries.CompanyName = enquiryData.CompanyName;
    me.acEnquiries.CompanyAddress = enquiryData.CompanyAddress;
    me.acEnquiries.ContactPerson = enquiryData.ContactPerson;
    me.acEnquiries.Designation = enquiryData.Designation;
    me.acEnquiries.PhoneNumber = enquiryData.PhoneNumber;
    me.acEnquiries.EmailID = enquiryData.EmailID;
    me.acEnquiries.EnqStatusID = enquiryData.EnqStatusID;
    me.acEnquiries.EnqStatusName = enquiryData.EnqStatusName;
    me.acEnquiries.Remark = enquiryData.Remark;
    me.acEnquiries.VisitStatus = enquiryData.VisitStatus;
    me.acEnquiries.UserID = enquiryData.ACUserID;
    me.acEnquiries.RoleName = me.sessionData.RoleName;
    me.acEnquiries.ModifiedBy = me.sessionData.UserID;
  }

  delete(enquiryData: ACEnquiries) {
    if(confirm("Are sure you want to delete this enquiry!")) {
    
    const me = this;
    const obj = {
    EnqID: enquiryData.EnqID,
    LeadEnqStatusID: 0,
    EnqDate: enquiryData.EnqDate,
    ACUserID: enquiryData.ACUserID,
    ACUserName: enquiryData.ACUserName,
    DealerID: enquiryData.DealerID,
    DealerName: enquiryData.DealerName,
    DealerSEID: enquiryData.DealerSEID,
    DealerSEName: enquiryData.DealerSEName,
    CompanyName: enquiryData.CompanyName,
    CompanyAddress: enquiryData.CompanyAddress,
    ContactPerson: enquiryData.ContactPerson,
    Designation: enquiryData.Designation,
    PhoneNumber: enquiryData.PhoneNumber,
    EmailID: enquiryData.EmailID,
    EnqStatusID: enquiryData.EnqStatusID,
    EnqStatusName: enquiryData.EnqStatusName,
    Remark: enquiryData.Remark,
    VisitStatus: enquiryData.VisitStatus,
    UserID: enquiryData.ACUserID,
    oleName: me.sessionData.RoleName,
    ModifiedBy: me.sessionData.UserID,
    };
    me.acenquiriesService.delete(obj).subscribe(data => {
      me.search();
      if (data.IsSuccess) {
        me.toastr.success(data.Message + ' Deactivated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
    });
  }
  }

}
