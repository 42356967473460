import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DelarUser } from '../../../model/master/delarUser/delarUser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DelarUserService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(DealerEng): Observable<DelarUser[]> {
    // return this.http.post<DelarUser[]>(this.url+"api/DealerEng/Insert", DealerEng);
    return this.http.post<DelarUser[]>(`${environment.apiUrl}/DealerEng/Insert`, DealerEng);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/DealerEng");
    return this.http.get<any[]>(`${environment.apiUrl}/DealerEng`);
  }
  public update(DealerEng): Observable<DelarUser[]> {
    // return this.http.post<DelarUser[]>(this.url+"api/DealerEng/Update", DealerEng);
    return this.http.post<DelarUser[]>(`${environment.apiUrl}/DealerEng/Update`, DealerEng);
  }
  
  public searchDelar(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Dealer");
    return this.http.get<any[]>(`${environment.apiUrl}/Dealer`);
  }
  
  public searchRole(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Role");
    return this.http.get<any[]>(`${environment.apiUrl}/Role`);
  }
}
