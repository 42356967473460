import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { PumpTechnology } from 'src/app/model/master/pump-technology/PumpTechnology';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PumpTechnologyService {

  constructor(private http: HttpClient) { }

  public submit(pumpTechnologyData: PumpTechnology): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/PumpTechnology/Insert", pumpTechnologyData);
    return this.http.post<Role[]>(`${environment.apiUrl}/PumpTechnology/Insert`, pumpTechnologyData);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/PumpTechnology");
    return this.http.get<any[]>(`${environment.apiUrl}/PumpTechnology`);
  }

  public update(pumpTechnologyData: PumpTechnology): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/PumpTechnology/Update", pumpTechnologyData);
    return this.http.post<Role[]>(`${environment.apiUrl}/PumpTechnology/Update`, pumpTechnologyData);
  }
}
