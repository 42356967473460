import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ContactService } from 'src/app/service/transaction/contact/contact.service';
import { Contact } from 'src/app/model/transaction/contact/contact';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { NgForm } from '@angular/forms';
// /import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  data: any[];
  accountData: any[];
  contact = new Contact();
  p: number;
  createData: any;
  updateData: any;
  sessionData: any = [];
  deleteData: any;

  constructor(
    private contactService: ContactService,
    protected changeDetectorRef: ChangeDetectorRef,
    private accountService: AccountService,
    private toastr: ToastrService) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    const me = this;
    this.search();
    this.accountSearch();
  }

  search() {
    const me = this;
    me.contactService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
      //console.log(data.Response);
    });
  }

  submit(contact: Contact, contactform: NgForm) {
    const me = this;
    
    if (contact.ContactID == null && contact.ContactID === undefined) {
      me.add(contact, contactform);
    } else {
      me.update(contact, contactform);
    }
  }

  update(contact: Contact, form: NgForm) {
    const me = this;
    me.contactService.update(contact).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
      form.reset();
      me.contact = new Contact();
    });
  }

  accountSearch() {
    const me = this;
    me.accountService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.accountData = data.Response;
    });
  }

  add(contact, contactform) {
    const me = this;
    contact.ModifiedBy = me.sessionData.UserID;
    me.contactService.submit(contact).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
      contactform.reset();
      me.contact = new Contact();
    });
  }

  edit(contactData: Contact) {
    const me = this;
    //console.log(me);
    
    me.contact.ContactID = contactData.ContactID;
    me.contact.PersonName = contactData.PersonName;
    me.contact.Designation = contactData.Designation;
    me.contact.EmailID = contactData.EmailID;
    me.contact.MobileNumber = contactData.MobileNumber;
    me.contact.LandLineNumber = contactData.LandLineNumber;
    me.contact.ReportingID = contactData.ReportingID;
    me.contact.ReportingName = contactData.ReportingID;
    me.contact.AccountID = contactData.AccountID;
    me.contact.Department = contactData.Department;
    me.contact.ModifiedBy = me.sessionData.UserID;
    me.contact.IsActive = contactData.IsActive;
    
  }

  delete(contactData: Contact) {
    const me = this;
    const obj = {
      ContactID: contactData.ContactID,
      PersonName: contactData.PersonName,
      Designation: contactData.Designation,
      EmailID: contactData.EmailID,
      MobileNumber: contactData.MobileNumber,
      LandLineNumber: contactData.LandLineNumber,
      ReportingID: contactData.ReportingID,
      ReportingName: contactData.ReportingID,
      AccountID: contactData.AccountID,
      Department: contactData.Department,
      IsActive: false,
      ModifiedBy: me.sessionData.UserID
    };
    me.contactService.delete(obj).subscribe(data => {
      me.search();
      if (data.IsSuccess) {
        me.toastr.success(data.Message + ' Deactivated  successfully');
        me.changeDetectorRef.detectChanges();
        me.contact.ContactID = undefined;
        me.contact = new Contact();

      } else {
        me.toastr.error('Something went wrong');
      }
    });
  }

  initialize(contactform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    me.contact.ContactID = undefined;
    me.search();
    me.contact = new Contact();
  }
}
