import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { changePass } from './changePass';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/master/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  objChangePass = new changePass();
  responseMessage: any;
  message: string;
  sessionData: any = [];
  constructor(
    private router: Router,
    private loginService: LoginService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef,
    ) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
      console.log(me.sessionData);
      
     }

  ngOnInit() {
  }

  submit() {
    const me = this;
    if (me.objChangePass && me.objChangePass.OldPassword && me.objChangePass.NewPassword) {
      me.loginService.changePassword(me.objChangePass.OldPassword, me.objChangePass.NewPassword, me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
        me.responseMessage = data;
        console.log(data);
        
        me.message = me.responseMessage.Message;
        if (me.responseMessage.IsSuccess) {
          me.toastr.success('Your password has been changed successfully!');
          setTimeout(() => {
            localStorage.setItem('isLoggedIn','false');
            localStorage.removeItem('currentUser');
            me.router.navigate(['/', 'login']);
         }, 5000);
                 
        } else {
          me.toastr.error(me.message);
        }
      });
    }
  }

  initialize(myform: NgForm) {
    const me = this;    
    myform.reset();
    me.changeDetectorRef.detectChanges();
    me.objChangePass = new changePass();
  }

  clearForm(myform: NgForm) {
    const me = this;
    myform.reset();
    me.changeDetectorRef.detectChanges();    
   }

}
