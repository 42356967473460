import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OpportunityCreatedMode } from 'src/app/model/master/opportunity-created-mode/OpportunityCreatedMode';
import { OpportunityCreatedModeService } from 'src/app/service/master/opportunity-created-mode/opportunity-created-mode.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-oppcremode',
  templateUrl: './oppcremode.component.html',
  styleUrls: ['./oppcremode.component.css']
})
export class OppcremodeComponent implements OnInit {

  opportunityCreatedMode = new OpportunityCreatedMode();
  data: OpportunityCreatedMode[];
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private opportunityCreatedModeService: OpportunityCreatedModeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(opportunityCreatedMode: OpportunityCreatedMode) {
    const me = this;
    if (opportunityCreatedMode.OpportunityModeID == null && opportunityCreatedMode.OpportunityModeID === undefined) {
      me.create(opportunityCreatedMode);
    } else {
      me.update(opportunityCreatedMode);
    }
  }

  create(opportunityCreatedMode: OpportunityCreatedMode) {
    const me = this;
    opportunityCreatedMode.ModifiedBy = me.sessionData.UserID;
    me.opportunityCreatedModeService.submit(opportunityCreatedMode).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(opportunityCreatedMode: OpportunityCreatedMode) {
    const me = this;
    me.opportunityCreatedModeService.update(opportunityCreatedMode).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  search() {
    const me = this;
    me.opportunityCreatedModeService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  edit(oppCreatedData: OpportunityCreatedMode) {
    const me = this;
    me.opportunityCreatedMode.OpportunityModeID = oppCreatedData.OpportunityModeID;
    me.opportunityCreatedMode.OpportunityModeName = oppCreatedData.OpportunityModeName;
    me.opportunityCreatedMode.OpportunityModeDescription = oppCreatedData.OpportunityModeDescription;
    me.opportunityCreatedMode.IsActive = oppCreatedData.IsActive;
    me.opportunityCreatedMode.ModifiedBy = me.sessionData.UserID;
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.opportunityCreatedMode = new OpportunityCreatedMode();
  }
}
