export class ACEnquiries {
    EnqID: number;
    EnqDate: string;    
    ACUserID: number;
    ACUserName: string;
    DealerID: number;
    DealerName: string;
    DealerSEID: number;
    DealerSEName: string;
    CompanyName: string;
    CompanyAddress: string;
    ContactPerson: string;
    Designation: string;
    PhoneNumber: number;
    EmailID: string;
    EnqStatusID: number;
    EnqStatusName: string;
    Remark: string;
    VisitStatus: string;
    // IsActive: boolean = true;
    UserID: string;
    RoleName: string;
    ModifiedBy: number;
    LeadEnqStatusID?: number;
}