import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient) { }

  public search(UserID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetTopMostCount`);
  }

  public SetTopMostCount(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/SetTopMostCount?UserID=` + UserID + `&RoleName=` + RoleName);
  }  
}
