export class QuoteUpdateDetails {
     QuotationDetailID: number;
    // QuotationID: number;
    // LeadID: number;
    // OpportunityName: string;
    ProductRangeID: number;
    //ProductRangeName: string;
    ProductModelID: number;
    //ProductModelName: string;
    Quantity: number;
    NetValue: number;
    IsActive: boolean =true;
}