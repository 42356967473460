export class Quantarget {
    QtID: number;
    DealerID: number;
    TargetYear: string;
    TargetQuantity: number;
    TargetValue: number;
    ProductRangeID:number;
    ProductRangeName: string;
    IsActive: Boolean = true;
    ModifiedBy: number;
    Isedit:number;
    IsTargetQuantitySet: number;
    IsTargetValueSet: number;
}