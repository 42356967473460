import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { User } from 'src/app/model/master/user/user';
import { UserService } from 'src/app/service/master/user/user.service';
import { RoleService } from 'src/app/service/master/role/role.service';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DesignationService } from '../../service/master/designation/designation.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';
import { Designation } from 'src/app/model/master/designation/desingation';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  user = new User();
  data: User[];
  // roleData: any[];
  zoneData: any[];
  zoneList: any;
  p: number;
  createData: any;
  updateData: any;
  ModifiedBy: number;
  sessionData: any = [];

  designationList: Array<Designation>;

  responseMessage: any;
  message: string;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private zoneService: ZoneService,
    private designationService: DesignationService,
    protected changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService) {
      const  me = this
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
      me.ModifiedBy = me.sessionData.UserID;
     }

  ngOnInit() {
    const me = this;
    me.search();    
    me.getZone();
    me.getDesignation();
  }

  submit(user: User) {
    const me = this;
    if (user.UserID == null && user.UserID === undefined) {
      me.create(user);
    } else {
      me.update(user);
    }
  }

  create(user: User) {
    const me = this;
    me.user.ModifiedBy = me.sessionData.UserID;
    me.userService.submit(user).subscribe(data => {
      me.createData = data;
      me.responseMessage = data;
      me.message = me.createData.Message;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update(user: User) {
    const me = this;
    //console.log(user);
    me.userService.update(user).subscribe(data => {
      me.updateData = data;
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  search() {
    const me = this;
    me.userService.search().subscribe(data => {
      //console.log(data.Response);
      me.data = data.Response;
    });
  }

  getDesignation() {
    const me = this;
    me.designationService.search().subscribe(data => {
      me.designationList = Utils.filterData(data.Response);
    });
  }

  edit(userData) {
    const me = this;
    //console.log(userData);
    const obj: Array<any> = [];
    me.userService.GetZonesByUserID(userData.UserID, userData.RoleName, me.ModifiedBy).subscribe(data => {
      data.Response.forEach((element, i) => {
        obj[i] = element.ZoneID;
      });
      //console.log(obj);
      me.user.objZoneID = obj;
      //console.log(me.user.objZoneID);
    });
    me.user.UserID = userData.UserID;
    me.user.RoleID = userData.RoleID;
    me.user.LoginName = userData.LoginName;
    me.user.UserPassword = userData.UserPassword;
    me.user.FirstName = userData.FirstName;
    me.user.LastName = userData.LastName;
    me.user.UserEmailID = userData.UserEmailID;
    me.user.MobileNumber = userData.MobileNumber;
    me.user.IsFirstLogin = userData.IsFirstLogin;
    me.user.IsActive = userData.IsActive;
    me.user.ModifiedBy = me.sessionData.UserID;
    me.user.DesignationID = userData.DesignationID;
  }

  // getRole() {
  //   const me = this;
  //   me.roleService.search().subscribe(data => {
  //     me.roleData = data.Response;
  //   });
  // }

  getZone() {
    const me = this;
    me.zoneService.search().subscribe(data => {
      me.zoneData = Utils.filterData(data.Response);
    });
  }

  initialize(userform: NgForm) {
    const me = this;
    userform.reset();
    me.changeDetectorRef.detectChanges();
    me.user = new User();
    me.user.ModifiedBy = me.sessionData.UserID;
  }
}
