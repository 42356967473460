import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { DealerAdmin } from 'src/app/model/master/dealer-admin/DealerAdmin';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DealerAdminService {

  constructor(private http: HttpClient) { }

  public submit(data: DealerAdmin): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/DealerAdmin/Insert`, data);
  }

  public search(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DealerAdmin`);
  }

  public update(data): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/DealerAdmin/Update`, data);
  }
}
