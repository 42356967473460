import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-visitreport',
  templateUrl: './visitreport.component.html',
  styleUrls: ['./visitreport.component.css']
})
export class VisitreportComponent implements OnInit {

  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  AseList: any[];
  DealerSEList: any[];
  ACUserList: any[];
  sessionData: any = [];
  ModifiedBy: number;
  p: any;
  fileName = 'Visit Report';

  constructor(
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private exportService: ExcelService
  ) {
    const  me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
   }

  ngOnInit() {
    const me = this;
    me.getZone();
    
    me.ReportDatails.ToDate = new Date(new Date().getFullYear(), 11, 31);
    me.ReportDatails.FromDate = new Date(
        new Date().getFullYear(),
        0, 
        1
    );
    me.search();
  }
  changeDate(FromDate){
    this.ReportDatails.ToDate= undefined;
    }
  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }
  
  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
     });
  }

  search() {
    const me = this;
    // if (me.ReportDatails && me.ReportDatails.UserID != null || me.ReportDatails &&
    //   me.ReportDatails.DealerID !== undefined || me.ReportDatails && me.ReportDatails.DealerSEID !== undefined) {
      const FromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
      const ToDate = me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
      me.reportService.GetVisitAndVisitDetailsData(FromDate, ToDate, me.ReportDatails.ZoneID,
        (me.ReportDatails.UserID ? me.ReportDatails.UserID : me.sessionData.UserID), (me.ReportDatails.DealerID ? me.ReportDatails.DealerID : me.sessionData.DealerID), me.ReportDatails.DealerSEID, me.ModifiedBy,me.sessionData.RoleName).subscribe(data => {
          me.data = data.Response;
        });
    // }
  }

  export() {
    const me = this;
    const sendDataToExport = [];
    me.data.forEach(element => {
      const obj = {
        'Visit Date': element.VisitDate,
        'Visit Type Name': element.VisitTypeName,
        'Dealer Name': element.DealerName,
        'Dealer SE Name': element.DealerSEName,
        'Account Name': element.AccountName,
        'Application Name': element.ApplicationName,
        'Customer Product Model Name': element.CustomerProductModelName,
        'Potential Type': element.PotentialType,
        'Lead Type Name': element.LeadTypeName,
        'Product Range Name': element.ProductRangeName,
        'Visit Remark': element.VisitRemark,
        'Competition Make': element.CompetitionMake,
        'Competition Qty': element.CompetitionQty,
        'No of Stand By Pumps': element.NoofStandByPumps,
        'No of Working Pumps': element.NoofWorkingPumps,
        'Pump Technologies': element.PumpTechnologies
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }

  reset() {
    const me = this;
    me.ReportDatails = new ReportDatails();
    me.data = [];
  }

  initialize(visitreportfilterform: NgForm) {
    const me = this;
    // me.changeDetectorRef.detectChanges();
    visitreportfilterform.reset();
    me.ngOnInit();
  }
}
