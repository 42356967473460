import { Component, OnInit } from '@angular/core';
import { stringify } from '@angular/core/src/util';
import { MassMailService } from '../../service/master/massmail/massmail.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mass-mail-view',
  templateUrl: './mass-mail-view.component.html',
  styleUrls: ['./mass-mail-view.component.css']
})
export class MassMailViewComponent implements OnInit {
  private MailData: any;
  public varstatus: string;
  sessionData: any = [];
  responseMessageInsert: any;
  public responseMessage: any;
  public message: string;
  constructor(private massmailservice: MassMailService,private toastr: ToastrService,private router: Router) { 
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    this.MailData = JSON.parse(localStorage.getItem('MassMailData'));
    //console.log(this.MailData);
  }

  Submit(IsTrue) {
    //console.log(IsTrue);
    const me = this;   
    if(IsTrue == true){
      this.varstatus ='Approved';
    }
    else{
      this.varstatus ='Rejected';
    }

    var body = {     

       MMID : this.MailData.MMID,
         MMailSubject : this.MailData.MMailSubject,
         MMailBCCList : this.MailData.MMailBCCList,
         MMailToList : this.MailData.MMailToList,
         MMailCCList : this.MailData.MMailCCList,
         MMailDealerID : this.MailData.MMailDealerID,
         DealerName : this.MailData.DealerName,
         MMailZoneID : this.MailData.MMailZoneID,
         ZoneName : this.MailData.ZoneName,
         MMSegmentID : this.MailData.MMSegmentID,
         SegmentName : this.MailData.SegmentName,
         MMailBody : this.MailData.MMailBody,
         MMailStatus : this.varstatus,//this.MailData.MMailStatus,
         MMRemark : this.MailData.MMRemark,
         IsActive : this.MailData.IsActive,
         CreatedBy : me.sessionData.UserID,
         CreatedDate : this.MailData.CreatedDate,
         ModifiedBy : me.sessionData.UserID,
         ModifiedDate : this.MailData.ModifiedDate,
         RoleName : me.sessionData.RoleName
    };

    //new code by yogesh b
    me.massmailservice.update(body).subscribe(data => {
      me.responseMessageInsert = data;
      me.message = me.responseMessageInsert.Message;
      if (me.responseMessageInsert.IsSuccess === true) {
        me.toastr.success(me.message);
        //location.reload();

        if (localStorage.getItem('MassMailData') !== null && localStorage.getItem('MassMailData') !== '') {
          localStorage.removeItem('MassMailData');
          localStorage.removeItem('RedirectFromMailList');
          this.router.navigate(['/massmaillist']);
        }
      } else {
        me.toastr.error(me.message);
      }     
    });
  }   
  
  SendMail(){
    const me = this;
    //console.log(this.MailData);
    this.MailData.RoleName = me.sessionData.RoleName;
    me.massmailservice.SendMassMail(this.MailData).subscribe(data => {
      me.responseMessageInsert = data;
      me.message = me.responseMessageInsert.Message;
      if (me.responseMessageInsert.IsSuccess === true) {
        me.toastr.success(me.message);
        //location.reload();

        if (localStorage.getItem('MassMailData') !== null && localStorage.getItem('MassMailData') !== '') {
          localStorage.removeItem('MassMailData');
          localStorage.removeItem('RedirectFromMailList');
          this.router.navigate(['/massmaillist']);
        }
      } else {
        me.toastr.error(me.message);
      }     
    });
  }
  Cancel(){
    if (localStorage.getItem('MassMailData') !== null && localStorage.getItem('MassMailData') !== '') {
      localStorage.removeItem('MassMailData');
      localStorage.removeItem('RedirectFromMailList');
      this.router.navigate(['/massmaillist']);
    }
  }
}
