import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Quantarget } from 'src/app/pages/dealer-quan-tar/Quantarget';

@Injectable({
  providedIn: 'root'
})
export class DealerQuantityTargetService {

  constructor(private http: HttpClient) { }

  public submit(dealer): Observable<Quantarget[]> {
    // return this.http.post<Dealer[]>(this.url+"api/Dealer/Insert", dealer);
    return this.http.post<Quantarget[]>(`${environment.apiUrl}/DealerQtyTarget/Insert`, dealer);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Dealer");
    return this.http.get<any[]>(`${environment.apiUrl}/DealerQtyTarget`);
  }
  public update(dealer): Observable<Quantarget[]> {
    // return this.http.post<Dealer[]>(this.url+"api/Dealer/Update", dealer);
    return this.http.post<Quantarget[]>(`${environment.apiUrl}/DealerQtyTarget/Update`, dealer);
  }

  public getDealerQtyTarget(DealerID, TargetYear, ModifiedBy): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DealerQtyTarget/GetAll?DealerID=` + DealerID + '&TargetYear=' + TargetYear+ '&ModifiedBy=' + ModifiedBy);
  }
}
