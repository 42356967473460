import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { VisitUpdate } from 'src/app/model/transaction/visit-update/VisitUpdate';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { VisitTypeService } from 'src/app/service/master/visit-type/visit-type.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { DelarUserService } from 'src/app/service/master/delaruser/delar-user.service';
import { ApplicationService } from 'src/app/service/master/application/application.service';
import { LeadTypeService } from 'src/app/service/master/lead-type/lead-type.service';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { VistiDetails } from 'src/app/model/transaction/visit-update/VistiDetails';
import { CompetionmakeService } from 'src/app/service/master/competionmake/competionmake.service';
import { PumpTechnologyService } from 'src/app/service/master/pump-technology/pump-technology.service';
import { VisitService } from 'src/app/service/transaction/visit/visit.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadService } from 'src/app/service/transaction/lead/lead.service';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent implements OnInit {

  visitUpdate = new VisitUpdate();
  vistiDetails = new VistiDetails();
  AccountData: any;
  visitTypeData: any;
  dealerData: any;
  dealerUserData: any;
  applicationData: any;
  leadTypeData: any;
  productionData: any;
  computationMakeData: any;
  pumpTechnologyData: any;
  visitData: any;
  visitCreateResponse: any;
  p: number;
  visitUpdateResponse: any;
  userID: any;
  userData: any;
  DealerSEList: any;
  sessionData: any = [];
  noDataFoundMsg = false;
  leadNameList: any;
  currDate: any;
  PotentialTypeList: any[] = [{ id: 'Yes', value: 'Yes' }, { id: 'No', value: 'No' }];

  constructor(
    private accountService: AccountService,
    private visitTypeService: VisitTypeService,
    private dealerService: DealerService,
    private delarUserService: DelarUserService,
    private applicationService: ApplicationService,
    private leadTypeService: LeadTypeService,
    private productionService: ProductRangeService,
    private computationMakeService: CompetionmakeService,
    private pumpTechnologyService: PumpTechnologyService,
    private visitService: VisitService,
    private toastr: ToastrService,
    // private spinner: NgxSpinnerService,
    private leadService: LeadService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    const me = this;
    me.currDate = new Date();
    me.getUserDataByID(me.sessionData.UserID);
    me.getAccount();
    me.getVisitType();
    me.getDealer();
    me.getApplication();
    me.getLeadType();
    me.getApplication();
    me.getProduct();
    me.getComputationMake();
    me.getComputationPumpTech();
    me.search();
    me.getLeadName();
    // me.spinner.show();
  }

  getUserDataByID(userID) {
    const me = this;
    me.visitService.getUserDataByID(userID).subscribe(data => {
      me.userData = data.Response;
    });
  }

  edit(visitdata: VisitUpdate) {
    const me = this;
    me.visitUpdate.VisitID = visitdata.VisitID;
    me.visitUpdate.VisitDate = visitdata.VisitDate;
    me.visitUpdate.VisitTypeID = visitdata.VisitTypeID;
    me.visitUpdate.DealerID = visitdata.DealerID;
    me.visitUpdate.DealerSEID = visitdata.DealerSEID;
    me.visitUpdate.AccountID = visitdata.AccountID;
    me.visitUpdate.ApplicationID = visitdata.ApplicationID;
    me.visitUpdate.CustomerProductModelID = visitdata.CustomerProductModelID;
    me.visitUpdate.PotentialType = visitdata.PotentialType;
    me.visitUpdate.LeadTypeID = visitdata.LeadTypeID;
    me.visitUpdate.ProductRangeID = visitdata.ProductRangeID;
    me.visitUpdate.ProductModelID = visitdata.ProductModelID;
    me.visitUpdate.VisitRemark = visitdata.VisitRemark;
    me.visitUpdate.LeadID = visitdata.LeadID;
    me.visitUpdate.ProductRangeID = visitdata.ProductRangeID;
    me.visitUpdate.ModifiedBy = me.sessionData.UserID;
    me.visitUpdate.objVistiDetails = [];
    me.searchDealerSE(me.visitUpdate.DealerID, 'Edit');
    
    me.visitService.getVisitDetailsById(visitdata.VisitID).subscribe(data => {
      if (data.Response.length !=0) {
        me.visitUpdate.objVistiDetails = data.Response;
      }
      
    });
  }

  submit(visitUpdate: VisitUpdate) {
    const me = this;
    if (visitUpdate.VisitID == null && visitUpdate.VisitID === undefined) {
      me.create(visitUpdate);
    } else {
      me.update(visitUpdate);
    }
  }

  create(visitUpdate: VisitUpdate) {
    const me = this;
    // this.spinner.show();
    visitUpdate.ModifiedBy = me.sessionData.UserID;
    me.visitService.submit(visitUpdate).subscribe(data => {
      me.visitCreateResponse = data;
      if (me.visitCreateResponse.IsSuccess) {
        me.toastr.success(me.visitCreateResponse.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(visitUpdate: VisitUpdate) {
    const me = this;
    // this.spinner.show();
    const obj1 = [];
    visitUpdate.objVistiDetails.forEach(element => {
      obj1.push({
        VisitDetailID: element.VisitDetailID ? element.VisitDetailID : null,
        CompetitionMakeID: element.CompetitionMakeID,
        CompetitionMake: element.CompetitionMake,
        Quantity: element.Quantity,
        NumberOfWorkingPumps: element.NumberOfWorkingPumps,
        NumberOfStandByPumps: element.NumberOfStandByPumps,
        PumpTID: element.PumpTID,
        IsActive: element.IsActive
      }
      );
    });
    const obj = {
      VisitID: visitUpdate.VisitID,
      AccountID: visitUpdate.AccountID,
      VisitDate: visitUpdate.VisitDate,
      VisitTypeID: visitUpdate.VisitTypeID,
      DealerID: visitUpdate.DealerID,
      DealerSEID: visitUpdate.DealerSEID,
      ApplicationID: visitUpdate.ApplicationID,
      CustomerProductModelID: visitUpdate.CustomerProductModelID,
      LeadTypeID: visitUpdate.LeadTypeID,
      ProductRangeID: visitUpdate.ProductRangeID,
      VisitRemark: visitUpdate.VisitRemark,
      PotentialType: visitUpdate.PotentialType,
      LeadID: visitUpdate.LeadID,
      ModifiedBy : me.sessionData.UserID,
      objVistiDetails: obj1
    };
    me.visitService.update(obj).subscribe(data => {
      me.visitUpdateResponse = data;
      if (me.visitUpdateResponse.IsSuccess) {
        me.toastr.success(me.visitUpdateResponse.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  getAccount() {
    const me = this;
    me.accountService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.AccountData = data.Response;
    });
  }

  getVisitType() {
    const me = this;
    me.visitTypeService.search().subscribe(data => {
      me.visitTypeData = Utils.filterData(data.Response);
    });
  }

  getDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.dealerData = Utils.filterData(data.Response);
    });
  }

  getApplication() {
    const me = this;
    me.applicationService.search().subscribe(data => {
      me.applicationData = Utils.filterData(data.Response);
    });
  }

  getLeadType() {
    const me = this;
    me.leadTypeService.search().subscribe(data => {
      me.leadTypeData = Utils.filterData(data.Response);
    });
  }

  getProduct() {
    const me = this;
    me.productionService.search().subscribe(data => {
      me.productionData = Utils.filterData(data.Response);
    });
  }

  pushDetails(vistiDetails) {
    const obj = {
      VisitDetailID: vistiDetails.VisitDetailID,
      CompetitionMakeID: vistiDetails.CompetitionMakeID,
      CompetitionMake: vistiDetails.CompetitionMake,
      Quantity: vistiDetails.Quantity,
      NumberOfWorkingPumps: vistiDetails.NumberOfWorkingPumps,
      NumberOfStandByPumps: vistiDetails.NumberOfStandByPumps,
      PumpTID: vistiDetails.PumpTID,
      IsActive: vistiDetails.IsActive
    };
    this.visitUpdate.objVistiDetails.push(JSON.parse(JSON.stringify(obj)));
    this.vistiDetails = new VistiDetails();
  }

  deleteDetails(index: number) {
    const me = this;    
    // me.visitUpdate.objVistiDetails.splice(index, 1);
    if(confirm("Are you sure to deactivate?")) {
      if (me.visitUpdate && me.visitUpdate.VisitID == null || me.visitUpdate && me.visitUpdate.VisitID === undefined) {
        me.visitUpdate.objVistiDetails.splice(index, 1);
        
      } else {
        me.visitUpdate.objVistiDetails.forEach((item, tempindex) => {
          //console.log(tempindex);
          
          if (index === tempindex) { item.IsActive = false; }
        });
      }
    }
  }

  getComputationMake() {
    const me = this;
    me.computationMakeService.search().subscribe(data => {
      me.computationMakeData = Utils.filterData(data.Response);
    });
  }

  getComputationPumpTech() {
    const me = this;
    me.pumpTechnologyService.search().subscribe(data => {
      me.pumpTechnologyData = Utils.filterData(data.Response);
    });
  }

  search() {
    const me = this;
    me.visitService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.visitData = data.Response;
      // setTimeout(() => {
      //   /** spinner ends after 5 seconds */
      //   console.log('spinner ends after 5 seconds');
      //   this.spinner.hide();
      // }, 5000);
    });
  }

  clearValues(visitform: NgForm, visitupdatetableform: NgForm) {
    const me = this;
    visitform.reset();
    visitupdatetableform.reset();
    me.autoBinding();
  }

  autoBinding() {
    const me = this;
    me.visitUpdate = new VisitUpdate();
    this.visitUpdate.objVistiDetails = [];
    this.changeDetectorRef.detectChanges();
    me.visitUpdate.VisitDate = new Date();
    if (me.sessionData.RoleName === 'Dealer User') {
      me.searchDealerSE(me.userData.DealerID, 'Edit');
      me.visitUpdate.DealerID = me.userData.DealerID;
      me.visitUpdate.DealerSEID = me.sessionData.UserID;
    } else if (me.sessionData.RoleName === 'Dealer') {
      me.searchDealerSE(me.sessionData.UserID, 'Add');
      me.visitUpdate.DealerID = me.sessionData.UserID;
    }
  }

  searchDealerSE(DealerID, status: string) {
    const me = this;
    if (status === 'Add') {
      me.visitUpdate.DealerSEID = undefined;
    }
    me.changeDetectorRef.detectChanges();
    if (DealerID || !DealerID === undefined || !DealerID === null) {
      me.accountService.searchDealerSE(DealerID).subscribe(data => {
        me.DealerSEList = Utils.filterData(data.Response);
      });
    }
  }

  getLeadName() {
    const me = this;
    me.leadService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.leadNameList = data.Response;
    });
  }

  tableFormReset(tb: NgForm) {
    const me = this;
    tb.reset();
  }

  getExistingLead(LeadID1)
  {
    // console.log(LeadID);
    
    const me = this;
    //console.log(me);
    const LeadID = (LeadID1 ? LeadID1 : 0);
    me.leadService.GetVisitDetailsByLeadID(LeadID, me.sessionData.UserID, me.sessionData.RoleName).subscribe((data: any) => {
      // me.leadNameList = data.Response;
      //console.log(data.Response);
      if (data.Response.length !=0) {        
      me.visitUpdate.objVistiDetails = data.Response;
      }
    });
    
  }
}
