import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';

declare var google: any;

@Component({
  selector: 'app-dealer-performance-analysis',
  templateUrl: './dealer-performance-analysis.component.html',
  styleUrls: ['./dealer-performance-analysis.component.css']
})
export class DealerPerformanceAnalysisComponent implements OnInit, AfterViewInit {
  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  sessionData: any = [];
  ProductList: any[];
  AllDealer: any[];

  next30Days: any;
  next90Days: any;
  dateTicks: Array<any> = [];
  ModifiedBy: number;

  dealerComparativeOptions = { title : 'Dealer Performance Analysis', vAxis: {title: '',ticks: [0]}, hAxis: {title: 'Dealer'}, seriesType: 'bars', series: {1: {type: 'line'}}, bar: { groupWidth: 30}, legend: { position: 'top'}, colors: ['#f26522','#00c0ef']}
 
  @ViewChild('dealerPerformanceAnalysis') dealerPerformanceAnalysis: ElementRef
  
  constructor(
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private datePipe: DatePipe,
    private productRangeService: ProductRangeService,
    protected changeDetectorRef: ChangeDetectorRef
    ) { 
      const  me = this
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
    }

  drawChart = () => {

    const me = this;

    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate =  me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
    const productrange = me.ReportDatails.ProductRangeID;

    let filter = 'FromDate='+fromDate+'&ToDate='+toDate+'&ZoneID='+zone+'&UserID='+me.sessionData.UserID+'&DealerID='+dealer+'&DealerSEID='+dealerSelse+'&ProductRangeID='+productrange;

    me.dashboardService.dashboardGraphsanalysis(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {
        console.log(data);
          
          let dealerComparativeData = [];

          dealerComparativeData.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);

          if (data.Response.length > 0) {

            data.Response.forEach(delCmpData => {
              dealerComparativeData.push([delCmpData.Name, delCmpData.NetValue, delCmpData.TotalQuantiry]);
            });

        }else{
            me.AllDealer.forEach(delCmpData => {
              dealerComparativeData.push([delCmpData.DealerName, 0, 0]);
              });
        }

        var delComparativeData = google.visualization.arrayToDataTable(dealerComparativeData);
        const dealerComparativeChart = new google.visualization.ComboChart(me.dealerPerformanceAnalysis.nativeElement);
        dealerComparativeChart.draw(delComparativeData, this.dealerComparativeOptions);
          // dealerComparativeChart.draw(data, this.dealerComparativeOptions);
      }
    })
  }
 currYear:any;
 currYearT:any;
  ngOnInit() {

    const me = this;
    me.getZone();
    me.getProducts();
    me.ReportDatails.ToDate = new Date();
    me.ReportDatails.FromDate = new Date(
        new Date().getFullYear(),
        0, 
        1
    );
   
   
    me.getAllDealer();
  }
  
  changeDate(FromDate){
    this.ReportDatails.ToDate= undefined;
    
    }
  ngAfterViewInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart);
  }

  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getProducts() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      let item = {ProductRangeID:'',ProductRangeName:'All'}
      data.Response.splice(0, 0, item);
      me.ProductList = data.Response;
      me.ReportDatails.ProductRangeID = me.ProductList[0].ProductRangeID;
    });
  }
  
  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
     });
  }

  getAllDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.AllDealer = data.Response;
     });
  }
}
