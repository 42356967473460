import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OppStatus } from '../../model/master/oppstatus/OppStatus';
import { OppStatusService } from '../../service/master/oppstatus/opp-status.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-opp-status',
  templateUrl: './opp-status.component.html',
  styleUrls: ['./opp-status.component.css']
})
export class OppStatusComponent implements OnInit {

  p: number;
  oppStatus = new OppStatus();
  responseMessage: any;
  message: string;
  data: Array<OppStatus>;
  sessionData:any;
  constructor(
    private oppStatusService: OppStatusService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.oppStatus.IsActive = true;
    me.search();
  }

  serviceSegment() {
    const me = this;
    me.oppStatus.ModifiedBy = me.sessionData.UserID;
    me.oppStatusService.submit(me.oppStatus).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.oppStatusService.update(me.oppStatus).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.oppStatus && me.oppStatus.OpportunityStatusID == null || me.oppStatus && me.oppStatus.OpportunityStatusID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(oppStatusData) {
    const me = this;
    me.oppStatus.OpportunityStatusID = oppStatusData.OpportunityStatusID;
    me.oppStatus.OpportunityStatusName = oppStatusData.OpportunityStatusName;
    me.oppStatus.OpportunityStatusDescription = oppStatusData.OpportunityStatusDescription;
    me.oppStatus.IsActive = oppStatusData.IsActive;
    me.oppStatus.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.oppStatusService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.oppStatus = new OppStatus();
  }
}
