import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Segment } from '../../../model/master/segment/segment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
  })
  
export class MassMailService {

    url : string = "https://krios-growvac.azurewebsites.net/"
    constructor(private http: HttpClient) { }

    // public search(FromDate, ToDate, ZoneID, UserID, DealerID, DealerSEID, SegmentID,RoleName): Observable<any> {
    //     return this.http.get<any[]>(`${environment.apiUrl}/MassMail/GetAllMassMailerDetails?FromDate=` + FromDate + `&ToDate=` + ToDate + `&ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + `SegmentID=` + SegmentID + '&RoleName=' + RoleName);
    // }
    public search(ZoneID, UserID, DealerID, DealerSEID, SegmentID,RoleName): Observable<any> {
      return this.http.get<any[]>(`${environment.apiUrl}/MassMail/GetAllMassMailerDetails?ZoneID=` + ZoneID + `&UserID=` + UserID + `&DealerID=` + DealerID + `&DealerSEID=` + DealerSEID + `&SegmentID=` + SegmentID + '&RoleName=' + RoleName);
  }
     
    public submit(body): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}/MassMail/SaveMassMail`, body);
    }

    public update(body): Observable<any[]> {
        return this.http.post<any[]>(`${environment.apiUrl}/MassMail/UpdateMassMail`, body);
      }
    
    public SendMassMail(body): Observable<any> {
      return this.http.post<any>(`${environment.apiUrl}/MassMail/SendMassMailNew`,body);
    }
  }