import { Component, OnInit } from '@angular/core';
import { UserRole } from 'src/app/model/master/role/role';
import { User } from 'src/app/model/master/user/user';
declare var jquery: any;
declare var $: any;

export interface menu {
  key: string;
  icon: string;
  item: string;
  roleIds: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  navs: Array<menu> = [];
  navs1: Array<menu> = [];
  navs2: Array<menu> = [];
  defaultActive: string = 'index';
  currentUser: User;
  menuConfig: Array<any> = [
    {
      key: 'index',
      icon: 'dashboard',
      item: 'Dashboard',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    {
      key: 'master',
      icon: 'cogs',
      item: 'Configuration Wizard',
      roleIds: UserRole.BLMAdmin
    },
    {
      key: 'account',
      icon: 'address-book',
      item: 'Account Creation',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ','  + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    {
      key: 'contact',
      icon: 'th',
      item: 'Contact Creation',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ','  + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    {
      key: 'visit',
      icon: 'handshake-o',
      item: 'Visit Updation',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ','  + UserRole.Dealer + ',' + UserRole.DealerUser
    },

    {
      key: 'lead',
      icon: 'list',
      item: 'Leads',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ','  + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    {
      key: 'quote',
      icon: 'file-text',
      item: 'Opportunity (Quote) Updation',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ','  + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    {
      key: 'com_dashboard',
      icon: 'dashboard', 
      item: 'Comparative Dashboard', 
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser
    },
    {
      key: 'scorecard_opportunity',
      icon: 'dashboard', 
      item: 'Performance Scorecard', 
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser
    },
    {
      key: 'acenquiries',
      icon: 'question-circle', 
      item: 'Enquiries Shared By AC', 
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer + ',' + UserRole.DealerUser
    },
    // {
    //   key: 'topuservisits',
    //   icon: 'trophy', 
    //   item: 'Top Visits', 
    //   roleIds: UserRole.BLMAdmin
    // },
    // {
    //   key: 'helpdesk',
    //   icon: 'support', 
    //   item: 'Help Desk', 
    //   roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer + ',' + UserRole.DealerUser
    // },
    // {
    //   key: 'announcement',
    //   icon: 'bullhorn', 
    //   item: 'Announcement', 
    //   roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer + ',' + UserRole.DealerUser
    // },
    // {
    //   key: 'dealer-performance-analysis',
    //   icon: 'bar-chart',
    //   item: 'Dealer Performance Analysis',
    //   roleIds: UserRole.BLMAdmin
    // },
    // {
    //   key: 'dealer_com_performance',
    //   icon: 'bar-chart', 
    //   item: 'Dealer Comparative Performance', 
    //   roleIds: UserRole.BLMAdmin
    // },


    // { 
    //   key: 'report',
    //   icon: 'file-text-o', 
    //   item: 'Report', 
    //   roleIds: UserRole.BLMAdmin+','+UserRole.ACUser+','+UserRole.Dealer+','+UserRole.DealerUser,
    //   submenu:{
    //     key: 'account-con-report', 
    //     icon: 'circle-o',
    //     item: 'Account and Contact Report'
    //   }
    // }


  ];

  menuConfig1: Array<any> = [
    {
      key: 'account-con-report',
      icon: 'circle-o',
      item: 'Account Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
    {
      key: 'contact-report',
      icon: 'circle-o',
      item: 'Contact Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
    {
      key: 'leadreport',
      icon: 'circle-o',
      item: 'Lead Wise Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },

    {
      key: 'visitreport',
      icon: 'circle-o',
      item: 'Visit Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
    {
      key: 'quotationreport',
      icon: 'circle-o',
      item: 'Quotation Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
    {
      key: 'dealer_se_per',
      icon: 'circle-o',
      item: 'Dealer Eng. Performance',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
    {
      key: 'report',
      icon: 'circle-o',
      item: 'Master Report',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.ACUser + ',' + UserRole.Dealer,
    },
  ];

  menuConfig2: Array<any> = [
    {
      key: 'massmaillist',
      icon: 'circle-o',
      item: 'Mail List',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.Dealer,
    },
    {
      key: 'massmailer',
      icon: 'circle-o',
      item: 'Create New Mail',
      roleIds: UserRole.BLMAdmin + ',' + UserRole.Dealer,
    },
    
  ];

  constructor() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.menuConfig.forEach(element => {

      const roles = JSON.parse("[" + element.roleIds + "]");

      if (roles.indexOf(this.currentUser.RoleID) >= 0) {
        this.navs.push(element);
      }
    });

    this.menuConfig1.forEach(element => {
      const roles = JSON.parse("[" + element.roleIds + "]");
      if (roles.indexOf(this.currentUser.RoleID) >= 0) {
        this.navs1.push(element);
      }
    });

    this.menuConfig2.forEach(element => {
      const roles = JSON.parse("[" + element.roleIds + "]");
      if (roles.indexOf(this.currentUser.RoleID) >= 0) {
        this.navs2.push(element);
      }
    });

  }

  ngOnInit() {
    $(".sidebar-menu li a").click(function(){
      $("body").removeClass("sidebar-open");
    });
    
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      trees.tree();
    });
  }

}
