import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalesPhase } from '../../../model/master/salesphase/salesphase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesphaseService {
  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(role): Observable<SalesPhase[]> {
    // return this.http.post<SalesPhase[]>(this.url+"api/SalesPhase/Insert", role);
    return this.http.post<SalesPhase[]>(`${environment.apiUrl}/SalesPhase/Insert`, role);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/SalesPhase");
    return this.http.get<any[]>(`${environment.apiUrl}/SalesPhase`);
  }
  public update(role): Observable<SalesPhase[]> {
    // return this.http.post<SalesPhase[]>(this.url+"api/SalesPhase/Update", role);
    return this.http.post<SalesPhase[]>(`${environment.apiUrl}/SalesPhase/Update`, role);
  }
}
