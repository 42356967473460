import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LeadType } from 'src/app/model/master/lead-type/LeadType';
import { LeadTypeService } from 'src/app/service/master/lead-type/lead-type.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-leadtype',
  templateUrl: './leadtype.component.html',
  styleUrls: ['./leadtype.component.css']
})
export class LeadtypeComponent implements OnInit {

  leadType = new LeadType();
  data: LeadType[];
  p: number;
  updateData: any;
  createData: any;
  sessionData: any;
  constructor(
    private leadTypeService: LeadTypeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
     }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(leadType) {
    const me = this;
    if (leadType.LeadTypeID == null && leadType.LeadTypeID === undefined) {
      me.create(leadType);
    } else {
      me.update(leadType);
    }
  }

  create(leadType: LeadType) {
    const me = this;
    leadType.ModifiedBy = me.sessionData.UserID;
    me.leadTypeService.submit(leadType).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(leadType: LeadType) {
    const me = this;
    me.leadTypeService.update(leadType).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updeted  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  search() {
    const me = this;
    me.leadTypeService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  edit(leadTypeData: LeadType) {
    const me = this;
    me.leadType.LeadTypeID = leadTypeData.LeadTypeID;
    me.leadType.LeadTypeName = leadTypeData.LeadTypeName;
    me.leadType.LeadTypeDescription = leadTypeData.LeadTypeDescription;
    me.leadType.IsActive = leadTypeData.IsActive;
    me.leadType.ModifiedBy = me.sessionData.UserID;
  }

  intitialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.leadType = new LeadType();
  }
}
