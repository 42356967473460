import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { AccountType } from 'src/app/model/master/account-type/AccountType';
import { OpportunityCreatedMode } from 'src/app/model/master/opportunity-created-mode/OpportunityCreatedMode';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OpportunityCreatedModeService {

  constructor(private http: HttpClient) { }

  public submit(data: OpportunityCreatedMode): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/OpportunityMode/Insert`, data);
  }

  public search(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/OpportunityMode`);
  }

  public update(data: OpportunityCreatedMode): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/OpportunityMode/Update`, data);
  }

}
