import { Component, OnInit } from '@angular/core';
import { QuoteUpdate } from '../../model/transaction/quoteupdate/quoteupdate';
import { ToastrService } from 'ngx-toastr';
import { QuoteupdateService } from '../../service/transaction/quoteupdate/quoteupdate.service';
import { QuoteUpdateDetails } from '../../model/transaction/quoteupdate/quoteupdatedetails';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-quoteupdate',
  templateUrl: './quoteupdate.component.html',
  styleUrls: ['./quoteupdate.component.css']
})
export class QuoteupdateComponent implements OnInit {

  p: number;
  totalRec: number;
  quoteUpdate = new QuoteUpdate();
  quoteUpdate1: any;
  quoteUpdate2: any;
  responseMessage: any;
  message: string;
  data: Array<QuoteUpdate>;
  quoteUpdateDetailstemp = new QuoteUpdateDetails();
  leadList: any[];
  salesPhaseList: any[]
  winningList: any[];
  oppModeList: any[];
  OpportunityStatusList: any[];
  productRangeList: any[];
  productModelList: any[];
  resonWinningList: any[];
  resonLosingList: any[];
  orderWonList: any[];
  productModelListAll: any[];
  dataById: QuoteUpdateDetails[] = [];
  AttachmentPathRes: any = null;
  selectedFilesDealerPOAttachmentPath: any;
  currentFileUploadDealerPOAttachmentPath: any;
  DealerPOAttachmentPathRes: any = null;
  selectedFilesCustomerPOAttachmentPath: any;
  currentFileUploadCustomerPOAttachmentPath: any;
  CustomerPOAttachmentPathRes: any = null;
  currentFileUpload: any;
  selectedFiles: any;
  uploadresponse: any;
  AttachmentPathtemp: string;
  CustomerPOAttachmentPathtemp: string;
  DealerPOAttachmentPathtemp: string;
  OpportunityNameData: any;
  tempOpportunityName: string;
  //sessionData: any = [];
  // constructor(private quoteupdateService: QuoteupdateService, private toastr: ToastrService,
  //   private route: ActivatedRoute, private router: Router) { }
  test: any;
  title: string;
  titleBtn: string;
  sessionData: any = [];
  constructor(
    private quoteupdateService: QuoteupdateService,
    private toastr: ToastrService,
    private route: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
  }

  LeadStatus: string;
  ngOnInit() {
    const me = this;

    this.route.params.subscribe(params => {
      if (params['data']) {

        me.quoteUpdate1 = JSON.parse(params['data']);
        me.quoteUpdate.LeadID = me.quoteUpdate1.LeadID;
        me.LeadStatus = me.quoteUpdate1.LeadStatus;
        //console.log(me.LeadStatus)
        if (me.LeadStatus == 'Quote') {
          me.quoteupdateService.serachQuotationByLeadId(me.quoteUpdate.LeadID).subscribe(data => {
            me.quoteUpdate = data.Response.objQuotation;
            me.edit(me.quoteUpdate);
            me.editList(me.quoteUpdate.LeadID);
          });
        }

        //me.quoteUpdate.OpportunityName = me.quoteUpdate1.OpportunityName;
      } else {
        me.quoteUpdate = new QuoteUpdate();
      }
    });

    me.search();
    me.searchLead();
    me.searchoppMode();
    me.searchOpportunityStatus();
    me.searchproductModelAll();
    me.searchproductRange();
    me.searchSalesPhase();
    me.searchWinning();
    me.searchResonLosing();
    me.searchResonWinning();
    me.searchOrderWon();
    me.title = 'Add New Opportunity';
    me.titleBtn = 'Add New Opportunity';
  }

  selectFileAttachmentPath(event) {
    this.selectedFiles = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.quoteupdateService.pushFileToStorage(this.currentFileUpload).subscribe(data => {
      this.AttachmentPathRes = data;
    });
    this.selectedFiles = undefined;
    this.currentFileUpload = undefined;
  }// upload

  selectFileDealerPOAttachmentPath(event) {
    this.selectedFilesDealerPOAttachmentPath = event.target.files;
    this.currentFileUploadDealerPOAttachmentPath = this.selectedFilesDealerPOAttachmentPath.item(0);
    this.quoteupdateService.pushFileToStorage(this.currentFileUploadDealerPOAttachmentPath).subscribe(data => {
      this.DealerPOAttachmentPathRes = data;
    });
    this.selectedFiles = undefined;
    this.currentFileUpload = undefined;
  }

  selectFileCustomerPOAttachmentPath(event) {
    this.selectedFilesCustomerPOAttachmentPath = event.target.files;
    this.currentFileUploadCustomerPOAttachmentPath = this.selectedFilesCustomerPOAttachmentPath.item(0);
    this.quoteupdateService.pushFileToStorage(this.currentFileUploadCustomerPOAttachmentPath).subscribe(data => {
      //console.log(data);
      this.CustomerPOAttachmentPathRes = data;
      //console.log(this.CustomerPOAttachmentPathRes);
    });
    this.selectedFiles = undefined;
    this.currentFileUpload = undefined;
  }

  open(content) {
    console.log(this.quoteUpdate);
    // //if( this.quoteUpdate.OpportunityStatusID == 1 && (this.DealerPOAttachmentPathRes === null || this.quoteUpdate.DealerFileName === null) ){
    //   if( this.quoteUpdate.OpportunityStatusID == 1 && (this.quoteUpdate.DealerPOAttachmentPath === null || this.quoteUpdate.DealerFileName === null) ){
    //   alert('Please attach Dealer PO');
    //   return;
    //       }


      this.modalService.open(content, {
        keyboard: false,
        backdrop: 'static'
      })
    
  }
  addQuoteDetail(quoteUpdateDetailstemp, quotedetailsform: NgForm) {
    const obj = {
      QuotationDetailID: undefined,
      ProductRangeID: quoteUpdateDetailstemp.ProductRangeID,
      ProductModelID: quoteUpdateDetailstemp.ProductModelID,
      Quantity: quoteUpdateDetailstemp.Quantity,
      NetValue: quoteUpdateDetailstemp.NetValue,
      IsActive: quoteUpdateDetailstemp.IsActive
      // OpportunityName: this.quoteUpdate.OpportunityName
    };
    this.quoteUpdate.objQuotationDetails.push(obj);
    this.quoteUpdateDetailstemp.ProductModelID = undefined;
    this.quoteUpdateDetailstemp.ProductRangeID = undefined;
    this.quoteUpdateDetailstemp.NetValue = undefined;
    this.quoteUpdateDetailstemp.Quantity = undefined;

    quotedetailsform.reset();
  }

  removeList(index) {
    const me = this;
    if(confirm("Are you sure to deactivate?")) {
      if (me.quoteUpdate && me.quoteUpdate.QuotationID == null || me.quoteUpdate && me.quoteUpdate.QuotationID === undefined) {
        this.quoteUpdate.objQuotationDetails.splice(index, 1);
  
      } else {
        this.quoteUpdate.objQuotationDetails.forEach((item, tempindex) => {
          if (index === tempindex) { item.IsActive = false; }
        });
      }
    }
  }

  serviceQuote() {
    const me = this;
       
    me.quoteUpdate.OpportunityName = "";
    if (me.quoteUpdate.WinningReasonsID == undefined) {
      me.quoteUpdate.WinningReasonsID = 0;
    }

    me.quoteUpdate.ModifiedBy = me.sessionData.UserID;
    me.quoteUpdate.OpportunityExpectedDate = me.quoteUpdate.OpportunityExpectedDate;
    me.quoteUpdate.AttachmentPath = me.AttachmentPathRes && me.AttachmentPathRes.Response;
    me.quoteUpdate.DocFileName = me.AttachmentPathRes && me.AttachmentPathRes.FileName;
    me.quoteUpdate.CustomerPOAttachmentPath = me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.Response;
    me.quoteUpdate.CustomerFileName = me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.FileName;
    me.quoteUpdate.DealerPOAttachmentPath = me.DealerPOAttachmentPathRes && me.DealerPOAttachmentPathRes.Response;
    me.quoteUpdate.DealerFileName = me.DealerPOAttachmentPathRes && me.DealerPOAttachmentPathRes.FileName;
    //console.log('serviceQuote');
    //console.log(me.quoteUpdate);
    me.quoteupdateService.submit(me.quoteUpdate).subscribe(data => {
      //console.log(data);
      
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update() {
    const me = this;
    //alert('update');
    const objdetail = [];
    me.quoteUpdate.objQuotationDetails.forEach(item => {
      objdetail.push({
        QuotationDetailID: item.QuotationDetailID,
        ProductRangeID: item.ProductRangeID,
        ProductModelID: item.ProductModelID,
        Quantity: item.Quantity,
        NetValue: item.NetValue,
        IsActive: item.IsActive
      });
    });
   

    // console.log(me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.Response);
    // console.log(me.quoteUpdate.CustomerPOAttachmentPath);
    // return;
    if(me.AttachmentPathRes && me.AttachmentPathRes.Response != null)
    {
      me.quoteUpdate.AttachmentPath = me.AttachmentPathRes && me.AttachmentPathRes.Response;
      me.quoteUpdate.DocFileName = me.AttachmentPathRes && me.AttachmentPathRes.FileName;
    }
    if(me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.Response != null)
    {
      me.quoteUpdate.CustomerPOAttachmentPath = me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.Response;
      me.quoteUpdate.CustomerFileName = me.CustomerPOAttachmentPathRes && me.CustomerPOAttachmentPathRes.FileName;
    }
  
    if(me.DealerPOAttachmentPathRes && me.DealerPOAttachmentPathRes.Response != null)
    {
    me.quoteUpdate.DealerPOAttachmentPath = me.DealerPOAttachmentPathRes && me.DealerPOAttachmentPathRes.Response;
    me.quoteUpdate.DealerFileName = me.DealerPOAttachmentPathRes && me.DealerPOAttachmentPathRes.FileName;
    }

    //console.log(me.DealerPOAttachmentPathRes);

   
    //console.log(me.CustomerPOAttachmentPathRes.Response);
    const obj = {
      QuotationID: me.quoteUpdate.QuotationID,
      LossingReasonsID: me.quoteUpdate.LossingReasonsID,
      OrderWonDate: me.quoteUpdate.OrderWonDate,
      CompetitionPrice: me.quoteUpdate.CompetitionPrice,
      DealerFileName: me.quoteUpdate.DealerFileName,
      CustomerFileName: me.quoteUpdate.CustomerFileName,
      DocFileName: me.quoteUpdate.DocFileName,
      LeadID: me.quoteUpdate.LeadID,
      OpportunityName: me.quoteUpdate.OpportunityName,
      SalesPhaseID: me.quoteUpdate.SalesPhaseID,
      WinningChanceID: me.quoteUpdate.WinningChanceID,
      OpportunityModeID: me.quoteUpdate.OpportunityModeID,
      OpportunityStatusID: me.quoteUpdate.OpportunityStatusID,
      OpportunityExpectedDate: me.quoteUpdate.OpportunityExpectedDate,
      AttachmentPath: me.quoteUpdate.AttachmentPath,
      DealerPOAttachmentPath: me.quoteUpdate.DealerPOAttachmentPath,
      CustomerPOAttachmentPath: me.quoteUpdate.CustomerPOAttachmentPath,
      ModifiedBy: me.sessionData.UserID,
      objQuotationDetails: objdetail

    };
    //console.log(obj);
  //return;

  console.log('update');
    console.log(obj);

    this.quoteupdateService.update(obj).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error(me.message);
      }
      me.quoteUpdate = new QuoteUpdate();
      me.search();
    });
  }

  submit() {
    const me = this;   
    

    if (me.quoteUpdate && me.quoteUpdate.QuotationID == null || me.quoteUpdate && me.quoteUpdate.QuotationID === undefined) {
      me.serviceQuote();
    } else {
      me.update();
    }
  }

  edit(quoteUpdate12) {

   

    const me = this;

    me.AttachmentPathtemp = null;
    me.CustomerPOAttachmentPathtemp = null;
    me.DealerPOAttachmentPathtemp = null;
    
    me.title = 'Update Opportunity';
    me.titleBtn = 'Update Opportunity';
    //console.log(quoteUpdate12);
    // me.getOpportunityName(quoteUpdate.LeadID);
    me.quoteUpdate.QuotationID = quoteUpdate12.QuotationID;
    me.quoteUpdate.DealerPOAttachmentPath = quoteUpdate12.DealerPOAttachmentPath;
    me.quoteUpdate.AttachmentPath = quoteUpdate12.AttachmentPath;
    me.quoteUpdate.CustomerPOAttachmentPath = quoteUpdate12.CustomerPOAttachmentPath;
    me.quoteUpdate.DealerFileName = quoteUpdate12.DealerFileName;
    me.quoteUpdate.CustomerFileName = quoteUpdate12.CustomerFileName;
    me.quoteUpdate.DocFileName = quoteUpdate12.DocFileName;
    me.quoteUpdate.LeadID = quoteUpdate12.LeadID;
    me.quoteUpdate.OpportunityName = quoteUpdate12.OpportunityName;
    me.quoteUpdate.SalesPhaseID = quoteUpdate12.SalesPhaseID;
    me.quoteUpdate.WinningChanceID = quoteUpdate12.WinningChanceID;
    me.quoteUpdate.OpportunityModeID = quoteUpdate12.OpportunityModeID;
    me.quoteUpdate.OpportunityStatusID = quoteUpdate12.OpportunityStatusID;
    me.quoteUpdate.OpportunityExpectedDate = quoteUpdate12.OpportunityExpectedDate;
    me.quoteUpdate.WinningReasonsID = quoteUpdate12.WinningReasonsID;
    me.quoteUpdate.OrderWonID = quoteUpdate12.OrderWonID;
    me.quoteUpdate.OrderWonDate = quoteUpdate12.OrderWonDate;
    me.quoteUpdate.CompetitionPrice= quoteUpdate12.CompetitionPrice;
    me.quoteUpdate.LossingReasonsID = quoteUpdate12.LossingReasonsID;
    me.quoteUpdate.ModifiedBy = me.sessionData.UserID;
  }

  editList(LeadID) {
    const me = this;
    // me.quoteupdateService.searchListById(LeadID).subscribe(data => {
    me.quoteupdateService.searchListByLeadId(LeadID).subscribe(data => {      
      me.dataById = data.Response;
      me.quoteUpdate.objQuotationDetails = me.dataById;
    });
  }

  search() {
    const me = this;
    me.quoteupdateService.search(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.data = data.Response;
      me.totalRec = data.Response.length;
    });
  }

  searchLead() {
    const me = this;
    me.quoteupdateService.searchLead(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      me.leadList = Utils.filterData(data.Response);
    });
  }

  searchSalesPhase() {
    const me = this;
    me.quoteupdateService.searchSalesPhase().subscribe(data => {
      me.salesPhaseList = Utils.filterData(data.Response);
    });
  }

  searchWinning() {
    const me = this;
    me.quoteupdateService.searchWinning().subscribe(data => {
      me.winningList = Utils.filterData(data.Response);
    });
  }

  searchoppMode() {
    const me = this;
    me.quoteupdateService.searchoppMode().subscribe(data => {
      me.oppModeList = Utils.filterData(data.Response);
    });
  }

  searchOpportunityStatus() {
    const me = this;
    me.quoteupdateService.searchOpportunityStatus().subscribe(data => {
      me.OpportunityStatusList = Utils.filterData(data.Response);
    });
  }

  searchproductRange() {
    const me = this;
    me.quoteupdateService.searchproductRange().subscribe(data => {
      me.productRangeList = Utils.filterData(data.Response);
    });
  }

  searchproductModel(ProductRangeID) {
    const me = this;
    me.productModelList = [];
    me.quoteUpdateDetailstemp.ProductModelID = undefined;
    if (ProductRangeID !== undefined && ProductRangeID !== null)
      me.quoteupdateService.searchproductModel(ProductRangeID).subscribe(data => {
        me.productModelList = Utils.filterData(data.Response);
      });
  }

  searchproductModelAll() {
    const me = this;
    me.quoteupdateService.searchproductModelAll().subscribe(data => {
      me.productModelListAll = Utils.filterData(data.Response);
    });
  }

  searchResonWinning() {
    const me = this;
    me.quoteupdateService.searchResonWinning().subscribe(data => {
      me.resonWinningList = Utils.filterData(data.Response);
    });
  }

  searchResonLosing() {
    const me = this;
    me.quoteupdateService.searchResonLosing().subscribe(data => {
      me.resonLosingList = Utils.filterData(data.Response);
    });
  }

  searchOrderWon() {
    const me = this;
    me.quoteupdateService.searchOrderWon().subscribe(data => {
      me.orderWonList = Utils.filterData(data.Response);
    });
  }

  initialize(quotefrom: NgForm, quotedetailsform: NgForm) {
    const me = this;
    me.title = 'Add new Opportunity';
    me.titleBtn = 'Add New Opportunity';
    me.quoteUpdate.QuotationID = null;
    me.quoteUpdate.DealerPOAttachmentPath = null;
    me.quoteUpdate.AttachmentPath = null;
    me.quoteUpdate.CustomerPOAttachmentPath = null;
    me.AttachmentPathtemp = null;
    me.CustomerPOAttachmentPathtemp = null;
    me.DealerPOAttachmentPathtemp = null;
    me.quoteUpdate.DealerFileName = null;
    me.quoteUpdate.CustomerFileName = null;
    me.quoteUpdate.DocFileName = null;
    me.quoteUpdate.objQuotationDetails = [];
    quotefrom.reset();
    quotedetailsform.reset();
  }
}
