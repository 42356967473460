import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Role } from '../../model/master/role/role';
import { RoleService } from '../../service/master/role/role.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  p: number;
  role = new Role();
  responseMessage: any;
  message: string;
  data: any;
  sessionData:any;
  constructor(
    private roleService: RoleService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
   }

  ngOnInit() {
    const me = this;
    me.role.IsActive = true;
    me.search();
  }

  serviceRole() {
    const me = this;
    me.role.ModifiedBy = me.sessionData.UserID;
    me.roleService.submit(me.role).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.roleService.update(me.role).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    
    if (me.role && me.role.RoleID == null || me.role && me.role.RoleID === undefined) {
      me.serviceRole();
    } else {
      me.update();
    }
  }

  edit(roleData) {
    const me = this;
    me.role.RoleID = roleData.RoleID;
    me.role.RoleName = roleData.RoleName;
    me.role.RoleDescription = roleData.RoleDescription;
    me.role.IsActive = roleData.IsActive;
    me.role.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.roleService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.role = new Role();
  }
}
