import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { ReportDatails } from '../report/ReportDatails';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  next30Days: number = 0;
  next90Days: number = 0;
  ReportDatails = new ReportDatails();
  sessionData: any = [];

  constructor(
    private dashboardService: DashboardService,
    private datePipe: DatePipe
    ) { 
      const  me = this
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {

    const me = this;
    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate =  me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;

    // let filter = 'FromDate='+fromDate+'&ToDate='+toDate+'&ZoneID='+zone+'&UserID='+me.sessionData.UserID+'&DealerID='+dealer+'&DealerSEID='+dealerSelse+'&RoleName='+me.sessionData.RoleName;

    // me.dashboardService.dashboard(filter).subscribe(data => {

    //   if (data && data.IsSuccess === true) {

    //     me.next30Days = data.Response && data.Response.Net30DaysValue;
    //     me.next90Days = data.Response && data.Response.Net90DaysValue;
    //   }
    // })
  }

}
