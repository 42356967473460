import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductModel } from 'src/app/model/master/product-model/ProductModel';
import { ProductModelService } from 'src/app/service/master/product-model/product-model.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-productmodel',
  templateUrl: './productmodel.component.html',
  styleUrls: ['./productmodel.component.css']
})
export class ProductmodelComponent implements OnInit {
  term: string;
  productModel = new ProductModel();
  productRangeData: any[];
  data: ProductModel[];
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private productModelService: ProductModelService,
    private toastr: ToastrService,
    
    protected changeDetectorRef: ChangeDetectorRef) {
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser')); }

  ngOnInit() {
    const me = this;
    me.search(me.term);
    me.searchProductRange();
  }

  submit(data: ProductModel) {
    const me = this;
    if (data.ProductModelID == null && data.ProductModelID === undefined) {
      me.create(data);
    } else {
      me.update(data);
    }
  }

  create(data: ProductModel) {
    const me = this;
    me.productModel.ModifiedBy = me.sessionData.UserID;
    me.productModelService.submit(data).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update(data: ProductModel) {
    const me = this;
    me.productModelService.update(data).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  // search() {
  //   const me = this;
  //   me.productModelService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }

  search(term: string) {
    const me = this;
    me.productModelService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.ProductModelName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  searchProductRange() {
    const me = this;
    me.productModelService.searchProductRange().subscribe(data => {
      me.productRangeData = Utils.filterData(data.Response);
    });
  }

  edit(productModelData: ProductModel) {
    const me = this;
    me.productModel.ProductModelID = productModelData.ProductModelID;
    me.productModel.ProductModelName = productModelData.ProductModelName;
    me.productModel.ProductModelDescription = productModelData.ProductModelDescription;
    me.productModel.ProductRangeID = productModelData.ProductRangeID;
    me.productModel.ProductRangeName = productModelData.ProductRangeName;
    me.productModel.IsActive = productModelData.IsActive;
    me.productModel.ModifiedBy = me.sessionData.UserID;
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.productModel = new ProductModel();
  }
}
