import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OrderWon } from '../../model/master/orderwon/orderwon';
import { OrderwonService } from '../../service/master/orderwon/orderwon.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-order-won',
  templateUrl: './order-won.component.html',
  styleUrls: ['./order-won.component.css']
})
export class OrderWonComponent implements OnInit {
  term: string;
  p: number;
  orderWon = new OrderWon();
  responseMessage: any;
  message: string;
  data: Array<OrderWon>;
  sessionData: any;
  constructor(
    private orderwonService: OrderwonService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.orderWon.IsActive = true;
    me.search(me.term);
  }

  serviceSegment() {
    const me = this;
    me.orderWon.ModifiedBy = me.sessionData.UserID;
    me.orderwonService.submit(me.orderWon).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update() {
    const me = this;
    this.orderwonService.update(me.orderWon).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  submit() {
    const me = this;
    if (me.orderWon && me.orderWon.OrderWonID == null || me.orderWon && me.orderWon.OrderWonID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(orderWonData) {
    const me = this;
    me.orderWon.OrderWonID = orderWonData.OrderWonID;
    me.orderWon.OrderWonName = orderWonData.OrderWonName;
    me.orderWon.OrderWonDescription = orderWonData.OrderWonDescription;
    me.orderWon.IsActive = orderWonData.IsActive;
    me.orderWon.ModifiedBy = me.sessionData.UserID;
  }

  // search() {
  //   const me = this;
  //   me.orderwonService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }

  search(term: string) {
    const me = this;
    me.orderwonService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.OrderWonName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.orderWon.IsActive = true;
    me.orderWon = new OrderWon();
  }
}
