import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { WinningReson } from '../../model/master/winningreson/winningReson';
import { WinningReasonService } from '../../service/master/winningReson/winning-reason.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reason-winning',
  templateUrl: './reason-winning.component.html',
  styleUrls: ['./reason-winning.component.css']
})
export class ReasonWinningComponent implements OnInit {

  p: number;
  winningReson = new WinningReson();
  responseMessage: any;
  message: string;
  data: Array<WinningReson>;
  sessionData: any;
  constructor(
    private winningReasonService: WinningReasonService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.winningReson.IsActive = true;
    me.search();
  }

  serviceSegment() {
    const me = this;
    me.winningReson.ModifiedBy = me.sessionData.UserID;
    me.winningReasonService.submit(me.winningReson).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.winningReasonService.update(me.winningReson).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.winningReson && me.winningReson.WinningReasonsID == null || me.winningReson && me.winningReson.WinningReasonsID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(winningData: WinningReson) {
    const me = this;
    me.winningReson.WinningReasonsID = winningData.WinningReasonsID;
    me.winningReson.WinningReasonsName = winningData.WinningReasonsName;
    me.winningReson.WinningReasonsDescription = winningData.WinningReasonsDescription;
    me.winningReson.IsActive = winningData.IsActive;
    me.winningReson.ModifiedBy = me.sessionData.UserID;
    
  }

  search() {
    const me = this;
    me.winningReasonService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.winningReson = new WinningReson();
  }
}
