import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dealer } from '../../../model/master/dealer/dealer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(dealer): Observable<Dealer[]> {
    // return this.http.post<Dealer[]>(this.url+"api/Dealer/Insert", dealer);
    return this.http.post<Dealer[]>(`${environment.apiUrl}/Dealer/Insert`, dealer);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Dealer");
    return this.http.get<any[]>(`${environment.apiUrl}/Dealer`);
  }
  public update(dealer): Observable<Dealer[]> {
    // return this.http.post<Dealer[]>(this.url+"api/Dealer/Update", dealer);
    return this.http.post<Dealer[]>(`${environment.apiUrl}/Dealer/Update`, dealer);
  }

}
