import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SegmentService } from '../../service/master/segment/segment.service'
import { MassMailService } from '../../service/master/massmail/massmail.service'
import { Segment } from '../../model/master/segment/segment';
import { ToastrService } from 'ngx-toastr';
// import { ReportDatails } from '../report/ReportDatails';
import { MassmailerDatails} from '../massmailer/MassmailerDetails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';

@Component({
  selector: 'app-massmailer',
  templateUrl: './massmailer.component.html',
  styleUrls: ['./massmailer.component.css']
})
export class MassmailerComponent implements OnInit {
  public htmlContent;
  public segment = new Segment();
  public responseMessage: any;
  public message: string;
  public sessionData: any;  
  ModifiedBy: number;
  public SegmentList: Array<Segment>;
  public AccountList: Array<any>;
  public MassMailerContactDetailsList: Array<any>;
  public SegmentID: any;
  public AccountID: any;
  public Subject: any;
  public Body: any;
  public SegmentDropdownHasError = true;
  public AccountDropdownHasError = true;

  // ReportDatails = new ReportDatails();
  MassmailerDetails = new MassmailerDatails();
  ZoneList: any[];
  DealerList: any[];
  TResponse: any[];
  responseMessageInsert: any;

  constructor(private segmentService: SegmentService,private massmailservice: MassMailService,private toastr: ToastrService, private reportService: ReportService, private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,) {
    
    const me = this;
    me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    me.ModifiedBy = me.sessionData.UserID;
    this.SegmentID = 0;
    this.AccountID = 0;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '500',
    minHeight: '500',
    maxHeight: '1000',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',    
    //uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //   ['bold', 'italic'],
    //   ['fontSize']
    // ]
  };

  ngOnInit() {
    const me = this;    
    //this.getSegmentList();    
   
    me.sessionData.RoleName = me.sessionData.RoleName;
    if(me.sessionData.RoleName == 'Dealer'){
      me.MassmailerDetails.DealerID = me.sessionData.UserID;

      //test new code
      me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
        //console.log(data);
        // let item = {ZoneID:'',ZoneName:'All'}
        // data.Response.splice(0, 0, item);
        me.ZoneList = data.Response;   
        //console.log(me.ZoneList);       
        me.MassmailerDetails.ZoneID = me.ZoneList[0].ZoneID;
       
  
        //console.log(me.MassmailerDetails.ZoneID);
  
        
        let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;      
      me.MassmailerDetails.DealerID = me.DealerList[0].DealerID;

      let item2 = {SegmentID:'',SegmentName:'All'}
      data.Response.splice(0, 0, item2);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerDetails.SegmentID = me.SegmentList[0].SegmentID;

      // let item3 = {AccountID:'',AccountName:'All'}
      // data.Response.splice(0, 0, item3);
      // me.AccountList = data.Response;
      // this.AccountID=me.AccountList[0].AccountID;
      // me.MassmailerDetails.AccountID = me.AccountList[0].AccountID;
  
        
      });

      this.getSegmentList(me.MassmailerDetails.DealerID);
    }
    else{
      this.getZone();
    }

    
  }
  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      //console.log(data);
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;   
      //console.log(me.ZoneList);
      if(me.sessionData.RoleName == 'Dealer'){
        me.MassmailerDetails.ZoneID = me.ZoneList[1].ZoneID;
      }
      else{
        me.MassmailerDetails.ZoneID = me.ZoneList[0].ZoneID;
      }

      

      //console.log(me.MassmailerDetails.ZoneID);

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;      
      me.MassmailerDetails.DealerID = me.DealerList[0].DealerID;

      let item2 = {SegmentID:'',SegmentName:'All'}
      data.Response.splice(0, 0, item2);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerDetails.SegmentID = me.SegmentList[0].SegmentID;

      // let item3 = {AccountID:'',AccountName:'All'}
      // data.Response.splice(0, 0, item3);
      // me.AccountList = data.Response;
      // console.log(me.AccountList[0].AccountID);
      // this.AccountID=me.AccountList[0].AccountID;
      // me.MassmailerDetails.AccountID = me.AccountList[0].AccountID;
    });
  }
  
  getDealerName(ZoneID) {
    //console.log(ZoneID);
    
    const me = this;    
    me.MassmailerDetails.UserID = null
    me.MassmailerDetails.DealerID = null
    me.MassmailerDetails.DealerSEID = null
    me.MassmailerDetails.SegmentID = null
    me.MassmailerDetails.AccountID=null;

    if(ZoneID == 0 || ZoneID == null || ZoneID == undefined){
     // alert('blank');
      
      let item = {DealerID:'',DealerName:'All'}      
      me.DealerList =[];
      me.DealerList.splice(0, 0, item);      
      //console.log(me.DealerList);
      me.MassmailerDetails.DealerID = me.DealerList[0].DealerID;

      //let item1 = {SegmentID:'',SegmentName:'All',SegmentDescription: '',  IsActive :  true,  ModifiedBy : 0}
      let item4 = {SegmentID:0,SegmentName:'All',SegmentDescription: '',  IsActive :  true,  ModifiedBy : 0}
       me.SegmentList =[];
       me.SegmentList.splice(0, 0, item4);
       //me.SegmentList.splice(0, 0, {SegmentID:'',SegmentName:'All'});      
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerDetails.SegmentID = me.SegmentList[0].SegmentID;

      //  let item2 = {AccountID:'',AccountName:'All'}      
      //  me.AccountList =[];
      //  me.AccountList.splice(0, 0, item2);
      //  this.AccountID=me.AccountList[0].AccountID;
      //  me.MassmailerDetails.AccountID=me.AccountList[0].AccountID;

    }
    else{
      me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
        let item = {DealerID:'',DealerName:'All'}
        data.Response.splice(0, 0, item);
        me.DealerList = data.Response;      
        me.MassmailerDetails.DealerID = me.DealerList[0].DealerID;
  
        let item1 = {SegmentID:'',SegmentName:'All'}
        data.Response.splice(0, 0, item1);
        me.SegmentList = data.Response;
        this.SegmentID=me.SegmentList[0].SegmentID;
        me.MassmailerDetails.SegmentID = me.SegmentList[0].SegmentID;
  
        // let item2 = {AccountID:'',AccountName:'All'}
        // data.Response.splice(0, 0, item2);
        // me.AccountList = data.Response;
        // this.AccountID=me.AccountList[0].AccountID;
        // me.MassmailerDetails.AccountID=me.AccountList[0].AccountID;
        //console.log(me.AccountList);
  
       });
    }

    
  }

  getSegmentList(DealerID) {   
    const me = this;
    me.segmentService.GetSegmentsByDealerID(DealerID).subscribe(data => {    
      //console.log(data.Response); 
      //let item = {SegmentID:'',SegmentName:'All'}
      let item = {SegmentID:0,SegmentName:'All',SegmentDescription: '',  IsActive :  true, CreatedDate:'',LastModifiedDate:'',  ModifiedBy : 0,UpdateStamp:''}
      data.Response.splice(0, 0, item);
      me.SegmentList = data.Response;
      this.SegmentID=me.SegmentList[0].SegmentID;
      me.MassmailerDetails.SegmentID = me.SegmentList[0].SegmentID;

      // let item1 = {AccountID:'',AccountName:'All'}
      // data.Response.splice(0, 0, item1);
      // me.AccountList = data.Response;
      // this.AccountID=me.AccountList[0].AccountID;
      // me.MassmailerDetails.AccountID=me.AccountList[0].AccountID;

    });
  }
  
  getAccountList(id) {
    const me = this;
    //console.log(id);
    
    if(me.sessionData.RoleName == 'Dealer' || me.sessionData.RoleName == 'Dealer User'){
      //me.MassmailerDetails.ZoneID = undefined;
      me.MassmailerDetails.DealerID = me.sessionData.UserID;
    }
    //console.log(me.MassmailerDetails.DealerID);
    this.SegmentID=parseInt(id);
    me.MassmailerDetails.SegmentID = parseInt(id);
//alert(id);
    if (id === '0') {
      this.SegmentDropdownHasError = true;
    }
    else {
      this.SegmentDropdownHasError = false;
      var body = {
        SegmentID: parseInt(id)
      };
      // me.segmentService.GetAccountsBySegmentID(parseInt(id),me.ReportDatails.DealerID,me.ReportDatails.ZoneID).subscribe(data => { 
        me.segmentService.GetAccountsBySegmentID(parseInt(id),me.MassmailerDetails.DealerID,me.MassmailerDetails.ZoneID).subscribe(data => {      
       //console.log(data);
          let item = {AccountID:'',AccountName:'All'}
        data.Response.splice(0, 0, item);
        me.AccountList = data.Response;
        this.AccountID=me.AccountList[0].AccountID;
        me.MassmailerDetails.AccountID=me.AccountList[0].AccountID;
        this.AccountID = 0;
      });
    }
    this.AccountDropdownHasError = true;
  }

  // getAccountList(id) {
  //   console.log(id);
  //   const me = this;
  //   if (id === '0') {
  //     this.SegmentDropdownHasError = true;
  //   }
  //   else {
  //     this.SegmentDropdownHasError = false;
  //     var body = {
  //       SegmentID: parseInt(id)
  //     };
  //     me.segmentService.GetAccountsBySegment(body).subscribe(data => {
  //       let item = {AccountID:'',AccountName:'All'}
  //       data.Response.splice(0, 0, item);
  //       me.AccountList = data.Response;
  //       this.AccountID = 0;
  //     });
  //   }
  //   this.AccountDropdownHasError = true;
  // }

  ValidateAccount(id) {
    if (id === '0') {
      this.AccountDropdownHasError = true;
    }
    else {
      this.AccountDropdownHasError = false;
    }
  }

  Cancel() {
    this.SegmentID = 0;
    this.AccountID = 0;
  }

  Search() {
    const me = this;
    // var body = {
    //   ZoneID: me.ReportDatails.ZoneID,
    //   UserID: me.sessionData.UserID,
    //   DealerID: me.ReportDatails.DealerID,//null,
    //   DealerSEID: null,
    //   SegmentID: parseInt(this.SegmentID),
    //   RoleName: me.sessionData.RoleName
    // };
    var body = {
      ZoneID: me.MassmailerDetails.ZoneID,
      UserID: me.sessionData.UserID,
      DealerID: me.MassmailerDetails.DealerID,//null,
      DealerSEID: null,
      SegmentID: parseInt(this.SegmentID),
      RoleName: me.sessionData.RoleName
    };
    
    me.segmentService.GetMassMailerContactDetails(body).subscribe(data => {
      me.MassMailerContactDetailsList = data.Response;
      console.log(me.MassMailerContactDetailsList);
    });
  }

  Submit() {
    const me = this;
    // var body = {
    //   ZoneID: me.ReportDatails.ZoneID,
    //   UserID: me.sessionData.UserID,
    //   DealerID: me.ReportDatails.DealerID,//null,,
    //   DealerSEID: null,
    //   SegmentID: parseInt(this.SegmentID),
    //   RoleName: me.sessionData.RoleName,
    //   Subject: me.Subject,
    //   EmailContent: me.Body
    // };

    var body = {
      ZoneID: me.MassmailerDetails.ZoneID,
      UserID: me.sessionData.UserID,
      DealerID: me.MassmailerDetails.DealerID,//null,,
      DealerSEID: null,
      SegmentID: parseInt(this.SegmentID),
      RoleName: me.sessionData.RoleName,
      Subject: me.Subject,
      EmailContent: me.Body
    };
//console.log(body);
    //new code by yogesh b
    me.massmailservice.submit(body).subscribe(data => {
      me.responseMessageInsert = data;
      //console.log(me.responseMessageInsert);
      me.message = me.responseMessageInsert.Message;
      if (me.responseMessageInsert.IsSuccess === true) {
        me.toastr.success(me.message);
        location.reload();
      } else {
        me.toastr.error(me.message);
      }     
    });
    
    // me.segmentService.SendMassMail(body).subscribe(data => {
    //   if (data.IsSuccess) {
    //     alert('Mail send successfully.');
    //     location.reload();
    //   }
    //   else {
    //     alert('Mail not send successfully.');
    //   }
    // });
  }
}
