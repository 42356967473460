export class DealerAdmin {
    DealerAdminID: number;
    DealerID: number;
    DealerName: string;
    FirstName: string;
    LastName: string;
    EmailID: string;
    UserName: string;
    UserPassword: string;
    IsActive: boolean = true;
    ModifiedBy: number;
}