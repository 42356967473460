export class Announcement {
    StartDate: Date;
    EndDate: Date;
    AnnouncementTitle: number;
    UpFileName: string = null;
    UpFilePath: string = null;
    AnnouncementDescription: string = null;
    IsActive: boolean = true;
    AnnouceID: number;
    ModifiedBy: number;
}
