import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Account } from '../../../model/transaction/account/account';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }


  public submit(account): Observable<Account[]> {
    return this.http.post<Account[]>(`${environment.apiUrl}/Accounts/Insert`, account);
  }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Accounts/GetAll?UserID=` + UserID + `&RoleName=` + RoleName);
  }

  public update(account): Observable<Account[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/Accounts/Update`, account);
  }

  public searchDistrict(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/District`);
  }

  public searchState(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/State`);
  }

  public searchSegment(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Segment`);
  }

  public searchDealer(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Dealer`);
  }

  public searchDealerSE(DealerId): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DealerEng?DealerId=` + DealerId);
  }

  public searchAccountType(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/AccountType`);
  }

}
