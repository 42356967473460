import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/model/master/role/role';

@Injectable({
  providedIn: 'root'
})
export class TopuservisitsService {

  constructor(
    private http: HttpClient,
  ) { }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/DashBoard/GetAllTopMostUserWiseVisitCount?UserID=` + UserID + `&RoleName=` + RoleName);
  }

  public update(data): Observable<Role[]> {
    return this.http.post<Role[]>(`${environment.apiUrl}/DashBoard/UpdateTopMost`, data);
  }

}
