import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { ApplicationService } from '../../service/master/application/application.service';
import { ApplicationType } from '../../model/master/application/application';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  term: string;
  p: number;
  applicationType = new ApplicationType();
  responseMessage: any;
  message: string;
  data: Array<ApplicationType>;
  sessionData: any;
  constructor(
    private applicationService: ApplicationService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.applicationType.IsActive = true;
    me.search(me.term);
  }

  serviceSegment() {
    const me = this;
    me.applicationType.ModifiedBy = me.sessionData.UserID;
    me.applicationService.submit(me.applicationType).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  update() {
    const me = this;
    this.applicationService.update(me.applicationType).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search(me.term);
    });
  }

  submit() {
    const me = this;
    if (me.applicationType && me.applicationType.ApplicationID == null ||
      me.applicationType && me.applicationType.ApplicationID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(applicationData) {
    const me = this;
    me.applicationType.ApplicationID = applicationData.ApplicationID;
    me.applicationType.ApplicationName = applicationData.ApplicationName;
    me.applicationType.ApplicationDescription = applicationData.ApplicationDescription;
    me.applicationType.IsActive = applicationData.IsActive;
    me.applicationType.ModifiedBy = me.sessionData.UserID;
  }

  // search() {
  //   const me = this;
  //   me.applicationService.search().subscribe(data => {
  //     me.data = data.Response;
  //   });
  // }

  search(term: string) {
    const me = this;
    me.applicationService.search().subscribe(data => {
      if(!term) {
        me.data = data.Response;
      } else {
        me.data = data.Response.filter(x => 
           x.ApplicationName.trim().toLowerCase().includes(term.trim().toLowerCase())
        );
      }      
    });
  }

  initialize(applicationform: NgForm) {
    const me = this;
    applicationform.reset();
    me.changeDetectorRef.detectChanges();
    me.applicationType = new ApplicationType();
  }
}
