import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ACEnquiries } from 'src/app/pages/acenquiries/ACEnquiries';

@Injectable({
  providedIn: 'root'
})
export class AcenquiriesService {

  constructor(private http: HttpClient) { }

  public getAllAcUsers(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Users/GetAll`);
  }

  public submit(acEnquiries): Observable<ACEnquiries[]> {
    return this.http.post<ACEnquiries[]>(`${environment.apiUrl}/LeadEnquiry/Insert`, acEnquiries);
  }

  public search(UserID, RoleName): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/LeadEnquiry/GetAll?UserID=` + UserID + `&RoleName=` + RoleName);
  }

  public update(acEnquiries): Observable<ACEnquiries[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/LeadEnquiry/Update`, acEnquiries);
  }

  public getUserByID(UserID): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/Users/GetUserByID?UserID=`+ UserID);
  }

  public GetEnquiryStatus(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/LeadEnquiry/GetEnquiryStatus`);
  }
  public GetEnquiryStatusByID(EnqStatusID): Observable<any> {
    console.log(EnqStatusID);
    
    return this.http.get<any[]>(`${environment.apiUrl}/LeadEnquiry/GetEnquiryStatusByID?StatusID=`+ EnqStatusID);
  }

  public delete(data: any): Observable<any> {
    return this.http.post<any[]>(`${environment.apiUrl}/LeadEnquiry/DeleteAllLeadEnquiry`, data);
  }
  
}
