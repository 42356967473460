import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReasonForLosing } from 'src/app/model/master/reason-for-losing/ReasonForLosing';
import { ReasonForLosingService } from 'src/app/service/master/reason-for-lossing/reason-for-lossing.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reason-losing',
  templateUrl: './reason-losing.component.html',
  styleUrls: ['./reason-losing.component.css']
})
export class ReasonLosingComponent implements OnInit {

  reasonForLosing = new ReasonForLosing();
  data: ReasonForLosing[];
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private reasonForLosingService: ReasonForLosingService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(reasonForLosing: ReasonForLosing) {
    const me = this;
    if (reasonForLosing.LossingReasonsID == null && reasonForLosing.LossingReasonsID === undefined) {
      me.create(reasonForLosing);
    } else {
      me.update(reasonForLosing);
    }
  }

  create(reasonForLosing: ReasonForLosing) {
    const me = this;
    reasonForLosing.ModifiedBy = me.sessionData.UserID;
    me.reasonForLosingService.submit(reasonForLosing).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(reasonForLosing: ReasonForLosing) {
    const me = this;
    me.reasonForLosingService.update(reasonForLosing).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  edit(lossingReasonData: ReasonForLosing) {
    const me = this;
    me.reasonForLosing.LossingReasonsID = lossingReasonData.LossingReasonsID;
    me.reasonForLosing.LossingReasonsName = lossingReasonData.LossingReasonsName;
    me.reasonForLosing.LossingReasonsDescription = lossingReasonData.LossingReasonsDescription;
    me.reasonForLosing.IsActive = lossingReasonData.IsActive;
    me.reasonForLosing.ModifiedBy = me.sessionData.UserID;
    
  }

  search() {
    const me = this;
    me.reasonForLosingService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    this.changeDetectorRef.detectChanges();
    me.reasonForLosing = new ReasonForLosing();
  }
}
