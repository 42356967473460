import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { AccountType } from 'src/app/model/master/account-type/AccountType';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {

  constructor(private http: HttpClient) { }


  public submit(accountType: AccountType): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/AccountType/Insert", accountType);
    return this.http.post<Role[]>(`${environment.apiUrl}/AccountType/Insert`, accountType);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/AccountType");
    return this.http.get<any[]>(`${environment.apiUrl}/AccountType`);
  }

  public update(accountType: AccountType): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/AccountType/Update", accountType);
    return this.http.post<Role[]>(`${environment.apiUrl}/AccountType/Update`, accountType);
  }


  
  // public changestats(teamId): Observable<any> {
  //   return this.http.get<any[]>("http://localhost:8080/changestatus?teamId=" + teamId);
  // }

}
