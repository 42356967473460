import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { LeadReport } from './LeadReport';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/service/transaction/Excel-Report/excel.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-leadreport',
  templateUrl: './leadreport.component.html',
  styleUrls: ['./leadreport.component.css']
})
export class LeadreportComponent implements OnInit {

  leadReport = new LeadReport();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  selected: any[];
  sessionData: any = [];
  ModifiedBy: number;
  p: any;
  fileName = 'LeadReport';

  constructor(
    private zoneService: ZoneService,
    private userService: UserService,
    private reportService: ReportService,
    private accountService: AccountService,
    protected changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private exportService: ExcelService) {
      const  me = this
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
      me.ModifiedBy = me.sessionData.UserID;
     }

    ngOnInit() {
      const me = this;
      me.getZone();
      
      me.leadReport.ToDate = new Date(new Date().getFullYear(), 11, 31);
      me.leadReport.FromDate = new Date(
          new Date().getFullYear(),
          0, 
          1
      );
      me.search();
    }
    changeDate(FromDate){
      this.leadReport.ToDate= undefined;
      }
    getZone() {
      const me = this;
      me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
        let item = {ZoneID:'',ZoneName:'All'}
        data.Response.splice(0, 0, item);
        me.ZoneList = data.Response;
        me.leadReport.ZoneID = me.ZoneList[0].ZoneID;
  
        let item1 = {DealerID:'',DealerName:'All'}
        data.Response.splice(0, 0, item1);
        me.DealerList = data.Response;
        me.leadReport.DealerID = me.DealerList[0].DealerID;
      });
    }
    
    getDealerName(ZoneID) {
      const me = this;
      me.leadReport.UserID = null
      me.leadReport.DealerID = null
      me.leadReport.DealerSEID = null
      me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
        let item = {DealerID:'',DealerName:'All'}
        data.Response.splice(0, 0, item);
        me.DealerList = data.Response;
        me.leadReport.DealerID = me.DealerList[0].DealerID;
       });
    }

  search() {
    const me = this;
    // if (me.leadReport && me.leadReport.UserID != null || me.leadReport
    //   && me.leadReport.DealerID !== undefined || me.leadReport && me.leadReport.DealerSEID !== undefined) {
      const FromDate = me.datePipe.transform(me.leadReport.FromDate, 'dd/MM/yyyy');
      const ToDate = me.datePipe.transform(me.leadReport.ToDate, 'dd/MM/yyyy');
      me.reportService.getLeadReportData(FromDate, ToDate, me.leadReport.ZoneID,
        (me.leadReport.UserID ? me.leadReport.UserID : me.sessionData.UserID), (me.leadReport.DealerID ? me.leadReport.DealerID : me.sessionData.DealerID), me.leadReport.DealerSEID, me.ModifiedBy,me.sessionData.RoleName).subscribe(data => {
          me.data = data.Response;
        });
    // }
  }

  export() {
    const me = this;
    const sendDataToExport = [];
    me.data.forEach(element => {
      const obj = {
        'Dealer Name': element.DealerName,
        'Dealer SE Name': element.DealerSEName,
        'Account Name': element.AccountName,
        'Application Name': element.ApplicationName,

        'Lead Name': element.LeadName,
        'Opportunity Name': element.OpportunityName,
        'Visit Date': element.VisitDate,
        'Visit Type': element.VisitType,
        
        'Customer Product Model Name': element.CustomerProductModelNamell,
        'Potential Type': element.PotentialType,
        'Lead Type Name': element.LeadTypeName,
        'Product Range Name': element.ProductRangeName,
        'Visit Remark': element.VisitRemark
      };
      sendDataToExport.push(obj);
    });
    me.exportService.exportExcel(sendDataToExport, me.fileName);
  }

  reset() {
    const me = this;
    me.leadReport = new LeadReport();
    me.data = [];
  }

  initialize(contactreportfilterform: NgForm) {
    const me = this;
    // me.changeDetectorRef.detectChanges();
    contactreportfilterform.reset();
    me.ngOnInit();
  }
}
