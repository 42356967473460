import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductRange } from '../../../model/master/productRange/productrange';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductRangeService {

  url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(productRange): Observable<ProductRange[]> {
    // return this.http.post<ProductRange[]>(this.url+"api/ProductRange/Insert", productRange);
    return this.http.post<ProductRange[]>(`${environment.apiUrl}/ProductRange/Insert`, productRange);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/ProductRange");
    return this.http.get<any[]>(`${environment.apiUrl}/ProductRange`);
  }
  public update(productRange): Observable<ProductRange[]> {
    // return this.http.post<ProductRange[]>(this.url+"api/ProductRange/Update", productRange);
    return this.http.post<ProductRange[]>(`${environment.apiUrl}/ProductRange/Update`, productRange);
  }
}
