export class Dealer{
    DealerID : number;
    DealerName : string;
    DealerDescription : string;
    ZoneID : number;
    ZoneName : string;
    MobileNumber : string;
    GSTNNumber : string;
    PANNumber : string;
    EmailID : string;
    DealerAddress : string;
    IsActive : boolean = true;
    ModifiedBy : number;
}