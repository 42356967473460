import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/service/master/dashboard/dashboard.service';
import { ReportDatails } from '../report/ReportDatails';
import { ReportService } from 'src/app/service/transaction/report/report.service';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/service/master/zone/zone.service';
import { DealerService } from 'src/app/service/master/dealer/dealer.service';
import { UserService } from 'src/app/service/master/user/user.service';
import { AccountService } from 'src/app/service/transaction/account/account.service';
import { DatePipe } from '@angular/common';
import { ProductRangeService } from 'src/app/service/master/productRange/product-range.service';
import { format } from 'url';
import { NgForm } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'app-comp-dashboard',
  templateUrl: './comp-dashboard.component.html',
  styleUrls: ['./comp-dashboard.component.css']
})
export class CompDashboardComponent implements OnInit, AfterViewInit {

  ReportDatails = new ReportDatails();
  data: any;
  ZoneList: any[];
  DealerList: any[];
  ProductList: any[];
  sessionData: any = [];
  AllDealer: any[];

  next30Days: any = 0;
  next90Days: any = 0;
  pastOverDue: any = 0;
  dateTicks: Array<any> = [];
  ModifiedBy:number;
  datetype: any;

  dealerwisenumberOfVisitsOptions = { 
    title: 'Dealerwise Number of Visits',
    chartArea:{left:30,top:50, right:30, width:100, height:300},
    // chartArea:{left:50,top:70,right:30, width:100, height:300,textStyle:{fontSize:13}},
    legend:{position:'top', alignment:'start'},  
    isStacked: true, 
    hAxis: { title: '', format: 'MMM',textStyle: { fontSize: 13 }}, 
    colors: ['#6d2077', '#0066b3'], 
    vAxis: { title: '', format: '0',textStyle: { fontSize: 13}}
  }

  @ViewChild('dealer30DaysOpenOpportunity') dealer30DaysOpenOpportunity: ElementRef
  @ViewChild('dealer90DaysOpenOpportunity') dealer90DaysOpenOpportunity: ElementRef
  @ViewChild('dealerOverdueOpenOpportunity') dealerOverdueOpenOpportunity: ElementRef
  @ViewChild('dealerPerformanceOrderWon') dealerPerformanceOrderWon: ElementRef
  @ViewChild('dealerOpenQuotation') dealerOpenQuotation: ElementRef
  @ViewChild('dealernumberOfVisits') dealernumberOfVisits: ElementRef
  @ViewChild('dealerOpenOpportunityProduct1') dealerOpenOpportunityProduct1: ElementRef
  @ViewChild('dealerOpenOpportunityProduct2') dealerOpenOpportunityProduct2: ElementRef
  @ViewChild('dealerOpenOpportunityProduct3') dealerOpenOpportunityProduct3: ElementRef
  @ViewChild('dealerOpenOpportunityProduct4') dealerOpenOpportunityProduct4: ElementRef
  
  constructor(
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private router: Router,
    private zoneService: ZoneService,
    private dealerService: DealerService,
    private userService: UserService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private productRangeService: ProductRangeService,
    protected changeDetectorRef: ChangeDetectorRef,
    ) {
      const  me = this
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
      me.ModifiedBy = me.sessionData.UserID;
      me.datetype=-1;
     }

  drawChart = (datetype:any) => {

    const me = this;

    const fromDate = me.datePipe.transform(me.ReportDatails.FromDate, 'dd/MM/yyyy');
    const toDate =  me.datePipe.transform(me.ReportDatails.ToDate, 'dd/MM/yyyy');
    const zone = me.ReportDatails.ZoneID;
    const dealer = me.ReportDatails.DealerID;
    const dealerSelse = me.ReportDatails.DealerSEID;
    const productRange = me.ReportDatails.ProductRangeID;

    let filter = 'FromDate='+fromDate+'&ToDate='+toDate+'&ZoneID='+zone+'&UserID='+me.sessionData.UserID+'&DealerID='+dealer+'&DealerSEID='+dealerSelse+'&ProductRangeID='+productRange+'&RoleName='+me.sessionData.RoleName + '&DateType=' + datetype;

    me.dashboardService.GetOpenOpportunityGraph(filter).subscribe(data => {
      
      // if (data && data.IsSuccess === true) {
        
        let dealer30DaysOpenOpportunityData = [];

        dealer30DaysOpenOpportunityData.push(['DealerName', 'NetValue']);
        
          if (data.Response && typeof data.Response.Net30DaysValue != "undefined" && data.Response.Net30DaysValue != null && data.Response.Net30DaysValue.length != null
          && data.Response.Net30DaysValue.length > 0) {
            
          data.Response.Net30DaysValue.forEach(del30DaysOpenOpp => {
            dealer30DaysOpenOpportunityData.push([del30DaysOpenOpp.DealerName, del30DaysOpenOpp.NetValue]);
          });

          const Dealer30DaysOpenOpportunityOption = {
            title: "Open opportunities for 30 days In MINR",
            chartArea:{left:50,top:70,right:30, width:100, height:300},
            titleTextStyle: {fontSize:14},
            vAxis: {title: '', format: 'short', textStyle:{fontSize:13}},
            hAxis: {title: '', textStyle:{fontSize:12}},
            bar: {groupWidth: "20"},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            colors: ['#0099cc','#ffcd00','#43b02a'],
          };

          const Dealer30DaysOpenOppData = google.visualization.arrayToDataTable(dealer30DaysOpenOpportunityData);
          var Dealer30DaysOpenOppview = new google.visualization.DataView(Dealer30DaysOpenOppData);

          Dealer30DaysOpenOppview.setColumns([0, 1,
            { calc: "stringify",
              sourceColumn: 1,
              type: "string",
              role: "annotation" }
            ]);

          var dealer30DaysOpenOppchart = new google.visualization.ColumnChart(me.dealer30DaysOpenOpportunity.nativeElement);

          dealer30DaysOpenOppchart.draw(Dealer30DaysOpenOppview, Dealer30DaysOpenOpportunityOption);
         
        }else{
          const Dealer30DaysOpenOpportunityOption = {
            title: "30 days",
            vAxis: {title: 'Value', format: '0'},
            hAxis: {title: 'Dealer'},
            bar: {groupWidth: "20"},
            legend: { position: "top" },
            colors: ['#0099cc','#ffcd00','#43b02a'],
          };
            me.AllDealer.forEach(dealer30DaysOpenOppo => {
              dealer30DaysOpenOpportunityData.push([dealer30DaysOpenOppo.DealerName, 0]);
            });
            const Dealer30DaysOpenOppData = google.visualization.arrayToDataTable(dealer30DaysOpenOpportunityData);
            var Dealer30DaysOpenOppview = new google.visualization.DataView(Dealer30DaysOpenOppData);
            Dealer30DaysOpenOppview.setColumns([0, 1,
              { calc: "stringify",
                sourceColumn: 1,
                type: "string",
                role: "annotation" }
              ]);

            var dealer30DaysOpenOppchart = new google.visualization.ColumnChart(me.dealer30DaysOpenOpportunity.nativeElement);

            dealer30DaysOpenOppchart.draw(Dealer30DaysOpenOppview, Dealer30DaysOpenOpportunityOption);
          }
          
          
         
          
          
          let dealer90DaysOpenOpportunityData = [];

        dealer90DaysOpenOpportunityData.push(['DealerName', 'NetValue']);
        
          if (data.Response && typeof data.Response.Net90DaysValue != "undefined" && data.Response.Net90DaysValue != null && data.Response.Net90DaysValue.length != null
          && data.Response.Net90DaysValue.length > 0) {
            
          data.Response.Net90DaysValue.forEach(del90DaysOpenOpp => {
            dealer90DaysOpenOpportunityData.push([del90DaysOpenOpp.DealerName, del90DaysOpenOpp.NetValue]);
          });

          // delete this.Dealer90DaysOpenOpportunityOption.vAxis.ticks;

         const Dealer90DaysOpenOpportunityOption = {
            title:"Open opportunities for 90 days In MINR",
            titleTextStyle: {fontSize:14},
            chartArea:{left:50,top:70, right:30, width:500, height:300},
            vAxis: {title:'', format: 'short',textStyle: {fontSize:14}},
            hAxis: {title:'',textStyle: {fontSize:12}},
            bar: {groupWidth:"20"},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            colors: ['#0099cc','#ffcd00','#43b02a'],
          };

          const Dealer90DaysOpenOppData = google.visualization.arrayToDataTable(dealer90DaysOpenOpportunityData);
          var Dealer90DaysOpenOppview = new google.visualization.DataView(Dealer90DaysOpenOppData);
          Dealer90DaysOpenOppview.setColumns([0, 1,
                           { calc: "stringify",
                             sourceColumn: 1,
                             type: "string",
                             role: "annotation" }
                           ]);

          var dealer90DaysOpenOppchart = new google.visualization.ColumnChart(me.dealer90DaysOpenOpportunity.nativeElement);
          
          dealer90DaysOpenOppchart.draw(Dealer90DaysOpenOppview, Dealer90DaysOpenOpportunityOption);
         
        }else{

            const Dealer90DaysOpenOpportunityOption = {
              title: "90 days",
              vAxis: {title: 'Value', format: '0'},
              hAxis: {title: 'Dealer'},
              bar: {groupWidth: "20"},
              legend: { position: "top" },
              colors: ['#0099cc','#ffcd00','#43b02a'],
            };

            me.AllDealer.forEach(dealer90DaysOpenOppo => {
              dealer90DaysOpenOpportunityData.push([dealer90DaysOpenOppo.DealerName, 0]);
            });

            const Dealer90DaysOpenOppData = google.visualization.arrayToDataTable(dealer90DaysOpenOpportunityData);
            var Dealer90DaysOpenOppview = new google.visualization.DataView(Dealer90DaysOpenOppData);
            Dealer90DaysOpenOppview.setColumns([0, 1,
                            { calc: "stringify",
                              sourceColumn: 1,
                              type: "string",
                              role: "annotation" }
                            ]);

            var dealer90DaysOpenOppchart = new google.visualization.ColumnChart(me.dealer90DaysOpenOpportunity.nativeElement);
            
            dealer90DaysOpenOppchart.draw(Dealer90DaysOpenOppview, Dealer90DaysOpenOpportunityOption);
          }

          

          
          let dealerOverdueDaysOpenOpportunityData = [];

          dealerOverdueDaysOpenOpportunityData.push(['DealerName', 'NetValue']);
         
          if (data.Response &&  typeof data.Response.PastOverDueValue != "undefined" && data.Response.PastOverDueValue != null && data.Response.PastOverDueValue.length != null
          && data.Response.PastOverDueValue.length > 0) {
            
          data.Response.PastOverDueValue.forEach(delOverdueDaysOpenOpp => {
            dealerOverdueDaysOpenOpportunityData.push([delOverdueDaysOpenOpp.DealerName, delOverdueDaysOpenOpp.NetValue]);
          });

          // delete this.DealerOverdueDaysOpenOpportunityOption.vAxis.ticks;

          const DealerOverdueDaysOpenOpportunityOption = {
            title: "Overdue open opportunities in MINR",
            titleTextStyle: {fontSize:14},
            chartArea:{left:50,top:70,right:30, width:100, height:300},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            vAxis: {title: '', format: 'short'},
            hAxis: {title: '',textStyle: {fontSize:12}},
            bar: {groupWidth: "20"},
            colors: ['#0099cc','#ffcd00','#43b02a'],
          };

          const DealerOverdueDaysOpenOppData = google.visualization.arrayToDataTable(dealerOverdueDaysOpenOpportunityData);
            var DealerOverdueDaysOpenOppview = new google.visualization.DataView(DealerOverdueDaysOpenOppData);
            DealerOverdueDaysOpenOppview.setColumns([0, 1,
                            { calc: "stringify",
                              sourceColumn: 1,
                              type: "string",
                              role: "annotation" }
                            ]);

            var dealerOverdueDaysOpenOppchart = new google.visualization.ColumnChart(me.dealerOverdueOpenOpportunity.nativeElement);
            
            dealerOverdueDaysOpenOppchart.draw(DealerOverdueDaysOpenOppview, DealerOverdueDaysOpenOpportunityOption);
         
        }else{
            const DealerOverdueDaysOpenOpportunityOption = {
              title: "Overdue open opportunities in MINR",
              vAxis: {title: 'Value', format: '0'},
              hAxis: {title: 'Dealer'},
              bar: {groupWidth: "20"},
              legend: { position: "top" },
              colors: ['#0099cc','#ffcd00','#43b02a'],
            };

            me.AllDealer.forEach(dealerOverdueDaysOpenOppo => {
              dealerOverdueDaysOpenOpportunityData.push([dealerOverdueDaysOpenOppo.DealerName, 0]);
            });

            const DealerOverdueDaysOpenOppData = google.visualization.arrayToDataTable(dealerOverdueDaysOpenOpportunityData);
            var DealerOverdueDaysOpenOppview = new google.visualization.DataView(DealerOverdueDaysOpenOppData);
            DealerOverdueDaysOpenOppview.setColumns([0, 1,
                            { calc: "stringify",
                              sourceColumn: 1,
                              type: "string",
                              role: "annotation" }
                            ]);

            var dealerOverdueDaysOpenOppchart = new google.visualization.ColumnChart(me.dealerOverdueOpenOpportunity.nativeElement);
            
            dealerOverdueDaysOpenOppchart.draw(DealerOverdueDaysOpenOppview, DealerOverdueDaysOpenOpportunityOption);

          }
      // }
    
    });


    



    me.dashboardService.comparitiveDashboardGraphs(filter).subscribe(data => {

      if (data && data.IsSuccess === true) {

          let dealerPerformanceOrderWonData = [];

          dealerPerformanceOrderWonData.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.DealerPerformanceOrderWonResult != "undefined" && data.Response.DealerPerformanceOrderWonResult != null && data.Response.DealerPerformanceOrderWonResult.length != null
          && data.Response.DealerPerformanceOrderWonResult.length > 0) {
          data.Response.DealerPerformanceOrderWonResult.forEach(delPerformanceOrderWon => {
            dealerPerformanceOrderWonData.push([delPerformanceOrderWon.Name, delPerformanceOrderWon.NetValue, delPerformanceOrderWon.TotalQuantity]);
          });
          // console.log(dealerPerformanceOrderWonData);
          
          // delete this.dealerPerformanceOrderWonOptions.vAxes[0].ticks;
          // delete this.dealerPerformanceOrderWonOptions.vAxes[1].ticks;
          const dealerPerformanceOrderWonOptions = {
            title : 'Dealer Performance (Order Won) Value In MINR',
            titleTextStyle: {fontSize:14},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            chartArea:{left:50,top:70,right:30, width:100, height:300},
            // vAxis: {title: 'Value', ticks: [0]},
            vAxes: [{title: '', format: 'short'},{title:'', format: '0'},],
            vAxis: {0: {title: 'Value'},1: {title: 'Quantity'}},
            hAxis: {title: '', textStyle:{fontSize:12}},
            rAxis: {title: ''},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {0: {targetAxisIndex: 0},1: {targetAxisIndex: 1,type: 'line'}},
            colors: ['#0066b3','#008c95','#008c95'],
            bar: {groupWidth: 20}
         }
          const delPereOrderWonData = google.visualization.arrayToDataTable(dealerPerformanceOrderWonData);
          const delPereOrderWonChart = new google.visualization.ComboChart(me.dealerPerformanceOrderWon.nativeElement);
        
          delPereOrderWonChart.draw(delPereOrderWonData, dealerPerformanceOrderWonOptions);

        }else{

          const dealerPerformanceOrderWonOptions = {
            title : 'Dealer Performance (Order Won) Value In MINR',
            // vAxis: {title: 'Value', ticks: [0]},
            vAxes: [
              {title: 'Value', format: '0'},
              {title:'Quantity', format: '0'},
              
            ],
            vAxis: {
              0: {title: 'Value'},
              1: {title: 'Quantity'}
            },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#0066b3','#008c95','#008c95'],
            bar: {
                 groupWidth: 20
             }
         }

            me.AllDealer.forEach(delPerformanceOrderWon => {
              dealerPerformanceOrderWonData.push([delPerformanceOrderWon.DealerName, 0, 0]);
            });

            const delPereOrderWonData = google.visualization.arrayToDataTable(dealerPerformanceOrderWonData);
            const delPereOrderWonChart = new google.visualization.ComboChart(me.dealerPerformanceOrderWon.nativeElement);
        
            delPereOrderWonChart.draw(delPereOrderWonData, dealerPerformanceOrderWonOptions);            
          }
          


          let dealerOpenQuotationData = [];

          dealerOpenQuotationData.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.OpenOpportunitiesOpenQuotationResult != "undefined" && data.Response.OpenOpportunitiesOpenQuotationResult != null && data.Response.OpenOpportunitiesOpenQuotationResult.length != null
          && data.Response.OpenOpportunitiesOpenQuotationResult.length > 0) {
          data.Response.OpenOpportunitiesOpenQuotationResult.forEach(delOpenQuotation => {
            dealerOpenQuotationData.push([delOpenQuotation.Name, delOpenQuotation.NetValue, delOpenQuotation.TotalQuantity]);
          });

          // delete this.dealerOpenQuotaionOptions.vAxes[0].ticks;
          // delete this.dealerOpenQuotaionOptions.vAxes[1].ticks;
          const dealerOpenQuotaionOptions = {
            title : 'Open Opportunities (Open Quotation) Product Wise',
            titleTextStyle: {fontSize:13},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            chartArea:{left:50,top:70,right:30, width:100, height:300,textStyle:{fontSize:13}},
            vAxes: [{title: '', format: 'short'},{ title:'', format: '0'}],
            vAxis: { 0: {title: 'Value'},1: {title: 'Quantity'}},
            hAxis: {title: '',textStyle:{fontSize:12}},
            rAxis: {title: '', textStyle:{fontSize:13}},
            seriesType: 'bars',
            series: {0: {targetAxisIndex: 0}, 1: {targetAxisIndex: 1,type: 'line'}},
            colors: ['#c8102e','#373D41','#373D41'],
            bar: {groupWidth:20}
          }
          const delOpenQuotatioData = google.visualization.arrayToDataTable(dealerOpenQuotationData);
          const delOpenQuotatioChart = new google.visualization.ComboChart(me.dealerOpenQuotation.nativeElement);
      
          delOpenQuotatioChart.draw(delOpenQuotatioData, dealerOpenQuotaionOptions);

        }else{
          
          const dealerOpenQuotaionOptions = {
            title : 'Open Opportunities (Open Quotation) Product Wise',
            vAxes: [
              {title: 'Value', format: '0'},
              { title:'Quantity', format: '0'}
            ],
            vAxis: {
              0: {title: 'Value'},
              1: {title: 'Quantity'}
            },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#c8102e','#373D41','#373D41'],
            bar: {
                 groupWidth: 20
             }
          }

            me.AllDealer.forEach(delOpenQuotation => {
              dealerOpenQuotationData.push([delOpenQuotation.DealerName, 0, 0]);
            });
            const delOpenQuotatioData = google.visualization.arrayToDataTable(dealerOpenQuotationData);
            const delOpenQuotatioChart = new google.visualization.ComboChart(me.dealerOpenQuotation.nativeElement);
        
            delOpenQuotatioChart.draw(delOpenQuotatioData, dealerOpenQuotaionOptions);
          }


          // Dealer Wise Visit Count
          if(me.sessionData.LoginName == 'blmadmin')
          {
          
          const dealernumberOfVisitsData = new google.visualization.DataTable();
        
          dealernumberOfVisitsData.addColumn('string', 'Dealer Name');
          dealernumberOfVisitsData.addColumn('number', 'New Visit Count');
          dealernumberOfVisitsData.addColumn('number', 'FollowUp Visit Count');
          
          const dealernumberOfVisitsRows = [];

          if (data.Response && typeof data.Response.DealerWiseNumberOfVisitResult != "undefined" && data.Response.DealerWiseNumberOfVisitResult != null && data.Response.DealerWiseNumberOfVisitResult.length != null
          && data.Response.DealerWiseNumberOfVisitResult.length > 0) {
          
            data.Response.DealerWiseNumberOfVisitResult.forEach(numOfVisit => {
              
              dealernumberOfVisitsRows.push([numOfVisit.DealerName, numOfVisit.NewVisitCount, numOfVisit.FollowUpVisitCount])
            });
            
            dealernumberOfVisitsData.addRows(dealernumberOfVisitsRows);

            // delete this.dealerwisenumberOfVisitsOptions.vAxis.ticks;
  
          }else{
            me.AllDealer.forEach(delNoVisit => {
              dealernumberOfVisitsRows.push([delNoVisit.DealerName, 0, 0]);
            });
            dealernumberOfVisitsData.addRows(dealernumberOfVisitsRows);
          }

          
          const dealernumVisitChart = new google.visualization.ColumnChart(me.dealernumberOfVisits.nativeElement);
          dealernumVisitChart.draw(dealernumberOfVisitsData, me.dealerwisenumberOfVisitsOptions);
        }


          let dealerOpenOpportuanityProductData1 = [];

          dealerOpenOpportuanityProductData1.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.GroupProduct1Result != "undefined" && data.Response.GroupProduct1Result != null && data.Response.GroupProduct1Result.length != null
          && data.Response.GroupProduct1Result.length > 0) {
          data.Response.GroupProduct1Result.forEach(dealerOpenOpportunityProduct1 => {
            
            dealerOpenOpportuanityProductData1.push([dealerOpenOpportunityProduct1.Name, dealerOpenOpportunityProduct1.NetValue, dealerOpenOpportunityProduct1.TotalQuantity]);
          });

          // delete this.dealerOpenOpportunityOptions1.vAxes[0].ticks;
          // delete this.dealerOpenOpportunityOptions1.vAxes[1].ticks;
          const dealerOpenOpportunityOptions1 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            titleTextStyle: {fontSize:13},
            legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
            chartArea:{left:40,top:30,right:30, width:100, height:200},
            vAxes: [{title: '', format: 'short',}, { title:'', format: '0'}],
            vAxis: {0: {title: ''},1: {title: ''}},
            hAxis: {title: '',textStyle:{fontSize:12}},
            rAxis: {title: '',textStyle:{fontSize:13}},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#0099cc','#f26522','#f26522'],
            bar: {
                 groupWidth: 20
             }
          }
          const delOpenOppData = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData1);
          const delOpenOppChart = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct1.nativeElement);
      
          delOpenOppChart.draw(delOpenOppData, dealerOpenOpportunityOptions1);

        }else{
          const dealerOpenOpportunityOptions1 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            vAxes: [
              {title: 'Value', format: '0'},
              { title:'Quantity', format: '0'}
            ],
            vAxis: {
              0: {title: 'Value'},
              1: {title: 'Quantity'}
            },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#0099cc','#f26522','#f26522'],
            bar: {
                 groupWidth: 20
             }
          }

            me.AllDealer.forEach(dealerOpenOpportunityProduct1 => {
              dealerOpenOpportuanityProductData1.push([dealerOpenOpportunityProduct1.DealerName, 0, 0]);
            });
            const delOpenOppData = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData1);
            const delOpenOppChart = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct1.nativeElement);
        
            delOpenOppChart.draw(delOpenOppData, dealerOpenOpportunityOptions1);
          }
          


          
          let dealerOpenOpportuanityProductData2 = [];

          dealerOpenOpportuanityProductData2.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.GroupProduct2Result != "undefined" && data.Response.GroupProduct2Result != null && data.Response.GroupProduct2Result.length != null
          && data.Response.GroupProduct2Result.length > 0) {
          data.Response.GroupProduct2Result.forEach(dealerOpenOpportunityProduct2 => {
            dealerOpenOpportuanityProductData2.push([dealerOpenOpportunityProduct2.Name, dealerOpenOpportunityProduct2.NetValue, dealerOpenOpportunityProduct2.TotalQuantity]);
          });

          // delete this.dealerOpenOpportunityOptions2.vAxes[0].ticks;
          // delete this.dealerOpenOpportunityOptions2.vAxes[1].ticks;
          const dealerOpenOpportunityOptions2 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            vAxes: [
              {title: '', format: "short" },
              { title:'', format: "0"},
            ],
            titleTextStyle: {fontSize:13},
legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
chartArea:{left:40,top:30,right:30, width:100, height:200},
            // vAxis: {
            //   0: {title: 'Value'},
            //   1: {title: 'Quantity'}
            // },
            hAxis: {title:'',textStyle:{fontSize:13},format:"short"},
            rAxis: {title:'',textStyle:{fontSize:13},format:"short"},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#ffcd00','#00c0ef','#00c0ef'],
            bar: {
                 groupWidth: 20
             }
          }

          const delOpenOppData2 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData2);
          const delOpenOppChart2 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct2.nativeElement);
      
          delOpenOppChart2.draw(delOpenOppData2, dealerOpenOpportunityOptions2);

        }else{

          const dealerOpenOpportunityOptions2 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            vAxes: [
              {title: 'Value', format: "0"},
              { title:'Quantity', format: "0"},
            ],
            // vAxis: {
            //   0: {title: 'Value'},
            //   1: {title: 'Quantity'}
            // },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            colors: ['#ffcd00','#00c0ef','#00c0ef'],
            bar: {
                 groupWidth: 20
             }
          }

            me.AllDealer.forEach(dealerOpenOpportunityProduct2 => {
              dealerOpenOpportuanityProductData2.push([dealerOpenOpportunityProduct2.DealerName, 0, 0]);
            });
            const delOpenOppData2 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData2);
            const delOpenOppChart2 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct2.nativeElement);
        
            delOpenOppChart2.draw(delOpenOppData2, dealerOpenOpportunityOptions2);
          }
          


          let dealerOpenOpportuanityProductData3 = [];

          dealerOpenOpportuanityProductData3.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.GroupProduct3Result != "undefined" && data.Response.GroupProduct3Result != null && data.Response.GroupProduct3Result.length != null
          && data.Response.GroupProduct3Result.length > 0) {
          data.Response.GroupProduct3Result.forEach(dealerOpenOpportunityProduct3 => {
            dealerOpenOpportuanityProductData3.push([dealerOpenOpportunityProduct3.Name, dealerOpenOpportunityProduct3.NetValue, dealerOpenOpportunityProduct3.TotalQuantity]);
          });

          // delete this.dealerOpenOpportunityOptions3.vAxes[0].ticks;
          // delete this.dealerOpenOpportunityOptions3.vAxes[1].ticks;

          const dealerOpenOpportunityOptions3 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            titleTextStyle: {fontSize:13},
legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
chartArea:{left:40,top:30,right:30, width:100, height:200},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'},
            },
            vAxes: [
              {title: '', format: "short"},
              { title:'', format: "0"}
            ],
            vAxis: {
              0: {title: ''},
              1: {title: '' }
            },
            hAxis: {title: '',textStyle:{fontSize:13}},
            rAxis: {title: '',textStyle:{fontSize:13} },
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            
            colors: ['#43b02a','#00c0ef','#00c0ef'],
            bar: {
                 groupWidth: 20
             }
          }

          const delOpenOppData3 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData3);
          const delOpenOppChart3 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct3.nativeElement);
      
          delOpenOppChart3.draw(delOpenOppData3, dealerOpenOpportunityOptions3);

        }else{

          const dealerOpenOpportunityOptions3 = {  
            // vAxis: {title: 'Value', ticks: [0]},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'},
            },
            vAxes: [
              {title: 'Value', format: "0"},
              { title:'Quantity', format: "0"}
            ],
            vAxis: {
              0: {title: 'Value'},
              1: {title: 'Quantity' }
            },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            
            colors: ['#43b02a','#00c0ef','#00c0ef'],
            bar: {
                 groupWidth: 20
             }
          }

            me.AllDealer.forEach(dealerOpenOpportunityProduct3 => {
              dealerOpenOpportuanityProductData3.push([dealerOpenOpportunityProduct3.DealerName, 0, 0]);
            });

            const delOpenOppData3 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData3);
            const delOpenOppChart3 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct3.nativeElement);
        
            delOpenOppChart3.draw(delOpenOppData3, dealerOpenOpportunityOptions3);
          }
          


          let dealerOpenOpportuanityProductData4 = [];

          dealerOpenOpportuanityProductData4.push(['Values', 'Sum of Net Value', 'Sum Of Quantity']);
          
          if (data.Response && typeof data.Response.GroupProduct4Result != "undefined" && data.Response.GroupProduct4Result != null && data.Response.GroupProduct4Result.length != null
          && data.Response.GroupProduct4Result.length > 0) {
          data.Response.GroupProduct4Result.forEach(dealerOpenOpportunityProduct4 => {
            dealerOpenOpportuanityProductData4.push([dealerOpenOpportunityProduct4.Name, dealerOpenOpportunityProduct4.NetValue, dealerOpenOpportunityProduct4.TotalQuantity]);
          });

          // delete this.dealerOpenOpportunityOptions4.vAxes[0].ticks;
          // delete this.dealerOpenOpportunityOptions4.vAxes[1].ticks;
         const dealerOpenOpportunityOptions4 = {
            // vAxis: {title: 'Value', ticks: [0]},
          titleTextStyle: {fontSize:13},
          legend: { position: 'top', alignment: 'start', textStyle:{fontSize:13}},
          chartArea:{left:40,top:30,right:30, width:100, height:200},

            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            vAxes: [
              {title: '', format: 'short'},
              { title:'', format: '0'}
            ],
            vAxis: {
              0: {title: ''},
              1: {title: ''}
            },
            hAxis: {title: ''},
            rAxis: {title: ''},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            colors: ['#c8102e','#373D41','#373D41'],
            bar: {
                 groupWidth: 20
             }
          }

          const delOpenOppData4 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData4);
          const delOpenOppChart4 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct4.nativeElement);
      
          delOpenOppChart4.draw(delOpenOppData4, dealerOpenOpportunityOptions4);

        }else{

          const dealerOpenOpportunityOptions4 = {
            // vAxis: {title: 'Value', ticks: [0]},
            series: {
              0: {targetAxisIndex: 0},
              1: {targetAxisIndex: 1,type: 'line'}
            },
            vAxes: [
              {title: 'Value', format: '0'},
              { title:'Quantity', format: '0'}
            ],
            vAxis: {
              0: {title: 'Value'},
              1: {title: 'Quantity'}
            },
            hAxis: {title: 'Dealer'},
            rAxis: {title: 'Qty.'},
            seriesType: 'bars',
            // series: {1: {type: 'line'}},
            colors: ['#c8102e','#373D41','#373D41'],
            bar: {
                 groupWidth: 20
             }
          }
            me.AllDealer.forEach(dealerOpenOpportunityProduct4 => {
              dealerOpenOpportuanityProductData4.push([dealerOpenOpportunityProduct4.DealerName, 0, 0]);
            });
            const delOpenOppData4 = google.visualization.arrayToDataTable(dealerOpenOpportuanityProductData4);
            const delOpenOppChart4 = new google.visualization.ComboChart(me.dealerOpenOpportunityProduct4.nativeElement);
        
            delOpenOppChart4.draw(delOpenOppData4, dealerOpenOpportunityOptions4);
          }
          
      }
    })
  }

  ngOnInit() {

    const me = this;
    const datetype = -1;
    me.getZone();
    me.getProducts();
    me.ReportDatails.ToDate = new Date();
    me.ReportDatails.FromDate = new Date(
      new Date().getFullYear(),
      0, 
      1
  );
      me.getAllDealer();
  }
  changeDate(FromDate){
    this.ReportDatails.ToDate= undefined;
    }
  ngAfterViewInit() {
    google.charts.load('current', { 'packages': ['corechart'] });
    google.charts.setOnLoadCallback(this.drawChart(-1));
  }

  getZone() {
    const me = this;
    me.reportService.GetUserZoneByRole(me.sessionData.UserID, me.sessionData.RoleName).subscribe(data => {
      let item = {ZoneID:'',ZoneName:'All'}
      data.Response.splice(0, 0, item);
      me.ZoneList = data.Response;
      me.ReportDatails.ZoneID = me.ZoneList[0].ZoneID;

      let item1 = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item1);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
    });
  }

  getProducts() {
    const me = this;
    me.productRangeService.search().subscribe(data => {
      let item = {ProductRangeID:'',ProductRangeName:'All'}
      data.Response.splice(0, 0, item);
      me.ProductList = data.Response;
      me.ReportDatails.ProductRangeID = me.ProductList[0].ProductRangeID;
    });
  }
  
  getDealerName(ZoneID) {
    const me = this;
    me.ReportDatails.UserID = null
    me.ReportDatails.DealerID = null
    me.ReportDatails.DealerSEID = null
    me.reportService.getDealerName(ZoneID, me.ModifiedBy).subscribe(data => {
      let item = {DealerID:'',DealerName:'All'}
      data.Response.splice(0, 0, item);
      me.DealerList = data.Response;
      me.ReportDatails.DealerID = me.DealerList[0].DealerID;
     });
  }

  getAllDealer() {
    const me = this;
    me.dealerService.search().subscribe(data => {
      me.AllDealer = data.Response;
     });
  }

  initialize(comdashboardfilterform: NgForm) {
    const me = this;
    me.changeDetectorRef.detectChanges();
    comdashboardfilterform.reset();
    me.ngOnInit();
    me.ngAfterViewInit();
    // me.ReportDatails = new ReportDatails();
  }

}
