export class MassmailerDatails {    
    ZoneID?: number;
    UserID?: number;
    DealerID?: number;
    DealerSEID?: number;
    SegmentID?: number;
    AccountID?: number;
    RoleName:string;
    Subject:string;
    EmailContent:string;
}