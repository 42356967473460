import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DelarUser } from '../../model/master/delarUser/delarUser';
import { DelarUserService } from '../../service/master/delaruser/delar-user.service';
import { Dealer } from '../../model/master/dealer/dealer';
import { Role } from '../../model/master/role/role';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Utils } from 'src/app/common-function/filter-dropdown-data';

@Component({
  selector: 'app-dealeruser',
  templateUrl: './dealeruser.component.html',
  styleUrls: ['./dealeruser.component.css']
})
export class DealeruserComponent implements OnInit {

  p: number;
  dekarUser = new DelarUser();
  responseMessage: any;
  message: string;
  data: Array<DelarUser>;
  dealarList: Array<Dealer>;
  RoleList: Array<Role>;
  sessionData: any;
  constructor(
    private delarUserService: DelarUserService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.dekarUser.IsActive = true;
    me.search();
    me.searchDealer();
    // me.searchRole();
  }

  serviceSegment() {
    const me = this;
    me.dekarUser.ModifiedBy = me.sessionData.UserID;
    me.delarUserService.submit(me.dekarUser).subscribe(data => {
      me.responseMessage = data;
      me.message = me.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' created  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  update() {
    const me = this;
    this.delarUserService.update(me.dekarUser).subscribe(data => {
      me.responseMessage = data;
      me.message = this.responseMessage.Message;
      if (me.responseMessage.IsSuccess) {
        me.toastr.success(me.message + ' updated  successfully');
      } else {
        //me.toastr.error('Something went wrong');
        me.toastr.error(me.message);
      }
      me.search();
    });
  }

  submit() {
    const me = this;
    if (me.dekarUser && me.dekarUser.DealerSEID == null ||
      me.dekarUser && me.dekarUser.DealerSEID === undefined) {
      me.serviceSegment();
    } else {
      me.update();
    }
  }

  edit(dekarUserData) {
    const me = this;
    me.dekarUser.DealerSEID = dekarUserData.DealerSEID;
    me.dekarUser.DealerID = dekarUserData.DealerID;
    me.dekarUser.DealerName = dekarUserData.DealerName;
    me.dekarUser.FirstName = dekarUserData.FirstName;
    me.dekarUser.LastName = dekarUserData.LastName;
    me.dekarUser.EmailID = dekarUserData.EmailID;
    me.dekarUser.UserName = dekarUserData.UserName;
    me.dekarUser.UserPassword = dekarUserData.UserPassword;
    me.dekarUser.RoleID = dekarUserData.RoleID;
    me.dekarUser.RoleName = dekarUserData.RoleName;
    me.dekarUser.MobileNumber = dekarUserData.MobileNumber;
    me.dekarUser.IsActive = dekarUserData.IsActive;
    me.dekarUser.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.delarUserService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  searchDealer() {
    const me = this;
    me.delarUserService.searchDelar().subscribe(data => {
      me.dealarList = Utils.filterData(data.Response);
    });
  }

  // searchRole() {
  //   const me = this;
  //   me.delarUserService.searchRole().subscribe(data => {
  //     me.RoleList = data.Response;
  //   });
  // }

  initialize(form: NgForm) {
    const me = this;
    form.reset();
    me.changeDetectorRef.detectChanges();
    me.dekarUser = new DelarUser();
  }
}
