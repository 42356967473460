import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { LeadType } from 'src/app/model/master/lead-type/LeadType';
import { ReasonForLosing } from 'src/app/model/master/reason-for-losing/ReasonForLosing';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReasonForLosingService {

  constructor(private http: HttpClient) { }

  public submit(data: ReasonForLosing): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/LossingReasons/Insert", data);
    return this.http.post<Role[]>(`${environment.apiUrl}/LossingReasons/Insert`, data);
  }

  public search(): Observable<any> {
    // return this.http.get<any[]>("https://krios-growvac.azurewebsites.net/api/LossingReasons");
    return this.http.get<any[]>(`${environment.apiUrl}/LossingReasons`);
  }

  public update(data: ReasonForLosing): Observable<Role[]> {
    // return this.http.post<Role[]>("https://krios-growvac.azurewebsites.net/api/LossingReasons/Update", data);
    return this.http.post<Role[]>(`${environment.apiUrl}/LossingReasons/Update`, data);
  }
}
