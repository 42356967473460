import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { VisitType } from 'src/app/model/master/visit-type/VisitType';
import { VisitTypeService } from 'src/app/service/master/visit-type/visit-type.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-visit-type',
  templateUrl: './visit-type.component.html',
  styleUrls: ['./visit-type.component.css']
})
export class VisitTypeComponent implements OnInit {

  visitType = new VisitType();
  data: VisitType[];
  p: number;
  createData: any;
  updateData: any;
  sessionData: any;
  constructor(
    private visitTypeService: VisitTypeService,
    private toastr: ToastrService,
    protected changeDetectorRef: ChangeDetectorRef) { 
      const me = this;
      me.sessionData = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
    const me = this;
    me.search();
  }

  submit(visitType: VisitType) {
    const me = this;
    if (visitType.VisitTypeID == null && visitType.VisitTypeID === undefined) {
      me.create(visitType);
    } else {
      me.update(visitType);
    }
  }

  create(visitType: VisitType) {
    const me = this;
    visitType.ModifiedBy = me.sessionData.UserID;
    me.visitTypeService.submit(visitType).subscribe(data => {
      me.createData = data;
      if (me.createData.IsSuccess) {
        me.toastr.success(me.createData.Message + ' created  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  update(visitType: VisitType) {
    const me = this;
    me.visitTypeService.update(visitType).subscribe(data => {
      me.updateData = data;
      if (me.updateData.IsSuccess) {
        me.toastr.success(me.updateData.Message + ' updated  successfully');
      } else {
        me.toastr.error('Something went wrong');
      }
      me.search();
    });
  }

  edit(visitTypeData: VisitType) {
    const me = this;
    me.visitType.VisitTypeID = visitTypeData.VisitTypeID;
    me.visitType.VisitTypeName = visitTypeData.VisitTypeName;
    me.visitType.VisitTypeDescription = visitTypeData.VisitTypeDescription;
    me.visitType.IsActive = visitTypeData.IsActive;
    me.visitType.ModifiedBy = me.sessionData.UserID;
  }

  search() {
    const me = this;
    me.visitTypeService.search().subscribe(data => {
      me.data = data.Response;
    });
  }

  initialize(visitform: NgForm) {
    const me = this;
    visitform.reset();
    me.changeDetectorRef.detectChanges();
    me.visitType = new VisitType();
  }
}
