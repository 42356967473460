import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../model/master/role/role';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RoleService {
url : string = "https://krios-growvac.azurewebsites.net/"
  constructor(private http: HttpClient) { }


  public submit(role): Observable<Role[]> {
    // return this.http.post<Role[]>(this.url+"api/Role/Insert", role);
    return this.http.post<Role[]>(`${environment.apiUrl}/Role/Insert`, role);
  }


  public search(): Observable<any> {
    // return this.http.get<any[]>(this.url+"api/Role");
    return this.http.get<any[]>(`${environment.apiUrl}/Role`);
  }
  public update(role): Observable<Role[]> {
    // return this.http.post<Role[]>(this.url+"api/Role/Update", role);
    return this.http.post<Role[]>(`${environment.apiUrl}/Role/Update`, role);
  }

  // public changestats(teamId): Observable<any> {
  //   return this.http.get<any[]>("http://localhost:8080/changestatus?teamId=" + teamId);
  // }

}
