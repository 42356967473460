export class Account {
    AccountID: number;
    AccountName: string;
    CompanyName: string;
    Address: string;
    DistrictName: string;
    StateName: string;
    PinCode: number;
    GSTNNumber: string;
    SegmentID: number;
    SegmentName: string;
    DealerID: number;
    DealerName: string;
    DealerSEID: number;
    DealerSEName: string;
    AccountTypeID: number;
    AccountTypeName: string;  
    AccountDate: string; 
    ModifiedBy: number;
}